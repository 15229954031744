import Mission from './mission';

import { GCS_MODE, GCS_ModeInfo } from '@/services/cesium/cesium_screen_space_ev_service';
import store from "@/store";
import axios from 'axios';
import { cesiumService } from '../../cesium/cesium_service_inst';
import CESIUM_DRONE from '../cesium_drone';
import UserSettingManager from '../user_setting/user_setting_manager';
export interface MissionObject {
    _id?: string; // Generation from WAS
    account: string,
    items: MissionItem[];
    alias: string;
}

export interface MissionItem {
    _id?: string; // Generation from WAS
    id: string;
    type: MissionItemType;
    lng: number;
    lat: number;
    display: number;
    asl: number;
    agl: number;
    timeout: number;
}

export enum MissionItemType {
    NONE = 0,
    AT,
    ARM,
    RTL,
    LAND,
    TAKEOFF,
    WAYPOINT,
    ROION,
    ROIOFF,
}

export enum MissionModeType {
    NONE = 0,
    DELETE,
    MODIFY,
    SAVE,
}

export default class MissionManager {
  private static instance: MissionManager;

  public static getInstance() {
    return this.instance || (this.instance = new this());
  }

  private _is_init = false;
  private _mission_count: number;
  private _missions: Mission[];
  private _mission_alias: string;
  private _entity: any;
  
  private _gcs_mode_info: GCS_ModeInfo
  private _modify_mission: MissionItem[];
  private _waypoint_index_list: number[];
  private _waypoint_box_state: boolean;

  private constructor() {
    this._mission_count = 0;
    this._missions = [];
    this._entity = [];
    this._modify_mission = [];
    this._waypoint_index_list = [];
    this._gcs_mode_info = {
      state:false,
      mode_type:GCS_MODE.NORMAL
    }
    this._waypoint_box_state = false,
    this._mission_alias = '',
    this.init();
    this.worker();
  }

  private init() {
    const viewer = cesiumService.GetViewer();
    if (
      viewer &&
      viewer.scene &&
      viewer.scene.globe &&
      viewer.scene.globe &&
      viewer.scene.globe.tilesLoaded
    ) {
      this.SetMissions();
      this._is_init = true;
    }
  }
  private worker() {
    if (!this._is_init) {
      this.init();
    }
    store.commit("SetMissions", this.GetMissions());

    setTimeout(() => {
      this.worker();
    }, 500);
  }

  SetMissionLineColor() {
    const Cesium = cesiumService.GetCesium();
    const viewer = cesiumService.GetViewer();
    this._missions.forEach((mission: Mission) => {
      const mission_path_entity = viewer.entities.getById(mission.GetName() + "-mission-path");
      const mission_floor_entity = viewer.entities.getById(mission.GetName() + "-mission-floor");
      if(mission_path_entity) {
        mission_path_entity.polyline.material = new Cesium.GridMaterialProperty({
          color: Cesium.Color.fromCssColorString(`${UserSettingManager.getInstance().Get().color_mission}8a`),
        })
      }
      if(mission_floor_entity) {
        mission_floor_entity.polyline.material = new Cesium.PolylineGlowMaterialProperty({
          glowPower: 0.25,
          color: Cesium.Color.fromCssColorString(`${UserSettingManager.getInstance().Get().color_mission}8a`),
        })
      }
    });
  }

  private GetMissions() {
    const missions: MissionObject[] = [];
    this._missions.forEach((mission: Mission) => {
      const mission_object: MissionObject = {
        _id: mission.GetMission_id(),
        account: mission.GetAccount(),
        alias: mission.GetName(),
        items: mission.GetMissionItems(),
      };
      missions.push(mission_object);
    });
    return missions;
  }
  GetMissionFromUUID(uuid:string): MissionObject|undefined {
    let return_mission:MissionObject|undefined = undefined;
    this._missions.forEach((mission: Mission) => {
      if(mission.GetMission_id() == uuid) {
        const mission_object: MissionObject = {
          _id: mission.GetMission_id(),
          account: mission.GetAccount(),
          alias: mission.GetName(),
          items: mission.GetMissionItems(),
        };

        return_mission = mission_object;
      }
    });
    return return_mission;
  }
  GetMissionFromAlias(alias:string): MissionObject|undefined {
    let return_mission:MissionObject|undefined = undefined;
    this._missions.forEach((mission: Mission) => {
      if( `S_${mission.GetName()}` == alias) {
        const mission_object: MissionObject = {
          _id: mission.GetMission_id(),
          account: mission.GetAccount(),
          alias: mission.GetName(),
          items: mission.GetMissionItems(),
        };

        return_mission = mission_object;
      }
    });
    return return_mission;
  }
  GetMissionDistanceFromAlias(alias:string) {
    let distance = 0;
    this._missions.forEach((mission:Mission) => {
      if(mission.GetName() == alias) {
        distance = mission.GetMissionDistance();
      }
    });

    return distance;
  }
  SetMissions() {
    this._missions = [];
    axios.get(`/gcs/mission/${store.getters.GetLoginData.id}`).then((res) => {
      res.data.data.forEach((mission: MissionObject) => {
        const was_mission = new Mission(
          mission.alias,
          mission.account,
          mission._id
        );
        mission.items.forEach((mission_item: MissionItem) => {
          was_mission.AddItem(mission_item);
        });
        this._missions.push(was_mission);
      });
      store.commit("SetDeleteMission", []);
    });
  }
  SetSelectedMissionEntity(mission_alias: string) {
    const viewer = cesiumService.GetViewer();
    const missions: MissionObject[] = [];
    this._missions.forEach((mission: Mission) => {
      viewer.entities.removeById(mission.GetName() + "-mission-path");
      viewer.entities.removeById(mission.GetName() + "-mission-floor");
    });
    return missions;
  }
  SetMissionEntityState(mission: MissionObject) { // 미션탭 리스트에서 visible, unvisible 상태 체크해서 아이콘 색깔로 분류할 때
    const viewer = cesiumService.GetViewer();
    if (viewer.entities.getById(mission.alias + "-mission-path")) {
      return true
    } else {
      return false
    }
  }
  SetAllMissionViewState(missions:MissionObject[]) { // 미션 탭에서 전체미션 view 상태 체크
    const viewer = cesiumService.GetViewer();
    let AllMissionViewState = true
    missions.every((mission:MissionObject)=>{
      if (viewer.entities.getById(mission.alias + "-mission-path")) {
        AllMissionViewState = true
        return true
      } else {
        AllMissionViewState = false
        return false
      }
    })
    return AllMissionViewState
  }
  SetMissionEntityView(view_mission: MissionObject) {
    const viewer = cesiumService.GetViewer();
    if (viewer.entities.getById(view_mission.alias + "-mission-path")) {
      store.commit("SetMissionUnvisibleListPush", view_mission);
      viewer.entities.removeById(view_mission.alias + "-mission-path");
      viewer.entities.removeById(view_mission.alias + "-mission-floor");
    } else {
      const MissionUnvisibleList : MissionObject[] = store.getters.GetMissionUnvisibleList
      MissionUnvisibleList.forEach((mission:MissionObject, index:number) => {
        if(mission.alias == view_mission.alias){
          if(MissionUnvisibleList.length != 0){
            MissionUnvisibleList.splice(index, 1)
          }
          store.commit("SetMissionUnvisibleListChange", MissionUnvisibleList)
        }
      })
      axios.get(`/gcs/mission/${store.getters.GetLoginData.id}`).then((res) => {
        res.data.data.forEach((mission: MissionObject) => {
          if (mission.alias == view_mission.alias) {
            // const _entity = [];
            const Cesium = cesiumService.GetCesium();
            view_mission.items.forEach((item: MissionItem) => {
              if(item.type == MissionItemType.WAYPOINT || item.type == MissionItemType.LAND) {
                const cartographic = Cesium.Cartographic.fromDegrees(
                  item.lng,
                  item.lat,
                  item.display
                );
                this._entity.push(Cesium.Cartographic.toCartesian(cartographic));
              }
            });
            const mission_entity = viewer.entities.getOrCreateEntity(
              view_mission.alias + "-mission-path"
            );
            // _mission_entitys.set(view_mission + "-mission-path", mission_entity);
            mission_entity.name = view_mission.alias;
            mission_entity.polyline = {
              positions: this._entity,
              material: new Cesium.PolylineGlowMaterialProperty({
                glowPower: 0.25,
                color: Cesium.Color.fromCssColorString(UserSettingManager.getInstance().Get().color_mission),
              }),
              width: 5,
              arcType: Cesium.ArcType.NONE,
            };

            const mission_floor_entity = viewer.entities.getOrCreateEntity(
              view_mission.alias + "-mission-floor"
            );
            mission_floor_entity.polyline = {
              positions: this._entity,
              width: 5,
              clampToGround: true,
            };
            this._entity = [];
          }
        });
      });
    }
  }
  SetMissionEntityUnvisible(view_mission: MissionObject) { //parameter로 전달된 미션 Entity 제거
    const viewer = cesiumService.GetViewer();
    viewer.entities.removeById(view_mission.alias + "-mission-path");
    viewer.entities.removeById(view_mission.alias + "-mission-floor");
  }

  SetAllMissionView() {
    const viewer = cesiumService.GetViewer();
    axios.get(`/gcs/mission/${store.getters.GetLoginData.id}`).then((res) => {
      res.data.data.forEach((mission: MissionObject) => {
          const Cesium = cesiumService.GetCesium();
          mission.items.forEach((item: MissionItem) => {
            if(item.type == MissionItemType.WAYPOINT || item.type == MissionItemType.LAND) {
              const cartographic = Cesium.Cartographic.fromDegrees(
                item.lng,
                item.lat,
                item.display
              );
              this._entity.push(Cesium.Cartographic.toCartesian(cartographic));
            }
          });
          const mission_entity = viewer.entities.getOrCreateEntity(
            mission.alias + "-mission-path"
          );
          // _mission_entitys.set(view_mission + "-mission-path", mission_entity);
          mission_entity.name = mission.alias;
          mission_entity.polyline = {
            positions: this._entity,
            material: new Cesium.PolylineGlowMaterialProperty({
              glowPower: 0.25,
              color: Cesium.Color.fromCssColorString(UserSettingManager.getInstance().Get().color_mission),
            }),
            width: 5,
            arcType: Cesium.ArcType.NONE,
          };
  
          const mission_floor_entity = viewer.entities.getOrCreateEntity(
            mission.alias + "-mission-floor"
          );
          mission_floor_entity.polyline = {
            positions: this._entity,
            width: 5,
            clampToGround: true,
          };
          this._entity = [];  
          // const _entity = [];
      });
    });
  }



  SetMissionEntityVisible() { // unvisiblelist에 미션 제외한 미션 Entity 생성
    const viewer = cesiumService.GetViewer();
    axios.get(`/gcs/mission/${store.getters.GetLoginData.id}`).then((res) => {
      res.data.data.forEach((mission: MissionObject) => {
        let VisibleCheck = true
        store.getters.GetMissionUnvisibleList.forEach((unvisible_mission:MissionObject) => {
          if(unvisible_mission.alias == mission.alias) {
            VisibleCheck = false
          }
        })
        if(VisibleCheck) {
          const Cesium = cesiumService.GetCesium();
          mission.items.forEach((item: MissionItem) => {
            if(item.type == MissionItemType.WAYPOINT || item.type == MissionItemType.LAND) {
              const cartographic = Cesium.Cartographic.fromDegrees(
                item.lng,
                item.lat,
                item.display
              );
              this._entity.push(Cesium.Cartographic.toCartesian(cartographic));
            }
          });
          const mission_entity = viewer.entities.getOrCreateEntity(
            mission.alias + "-mission-path"
          );
          mission_entity.name = mission.alias;
          mission_entity.polyline = {
            positions: this._entity,
            // material: new Cesium.PolylineGlowMaterialProperty({
            material: new Cesium.GridMaterialProperty({
              // color: Cesium.Color.fromRandom({ maximumAlpha: 1.0, minimumAlpha: 1.0 }),
              color: Cesium.Color.fromCssColorString(`${UserSettingManager.getInstance().Get().color_mission}8a`),
              glowPower: 0.1,
            }),
            
            width: 1.5,
            arcType: Cesium.ArcType.NONE,
          };
  
          const mission_floor_entity = viewer.entities.getOrCreateEntity(
            mission.alias + "-mission-floor"
          );
          mission_floor_entity.polyline = {
            positions: this._entity,
            width: 3,
            clampToGround: true,
            material: new Cesium.PolylineGlowMaterialProperty({
              glowPower: 0.25,
              color: Cesium.Color.fromCssColorString(UserSettingManager.getInstance().Get().color_mission),
            }),
          };
          this._entity = [];  
          }
      });
    });
  }
  SetMissionModify(mission: MissionObject) {
    axios.put("/gcs/mission", mission).then((res) => {
      console.log('Mission', res.data.state, mission)
      if (res.data.state) {
        this.SetMissions();
        this.SetMissionEntityVisible()
      }
    });
  }
  GetMissionObject(mission_name: string) {
    let ret_mission_object: MissionObject | undefined;
    this._missions.forEach((mission: Mission) => {
      if (mission.GetName() == mission_name) {
        const mission_object: MissionObject = {
          _id: mission.GetMission_id(),
          account: mission.GetAccount(),
          alias: mission.GetName(),
          items: mission.GetMissionItems(),
        };
        ret_mission_object = mission_object;
        return;
      }
    });
    return ret_mission_object;
  }
  GetMission(mission_name: string) {
    let return_mission: Mission | undefined;
    this._missions.forEach((mission: Mission) => {
      if (mission.GetName() == mission_name) {
        return_mission = mission;
        return;
      }
    });
    return return_mission;
  }
  SetMissionSendToWAS(new_mission: MissionObject) {
    axios.post("/gcs/mission", new_mission).then((res: any) => {
      if (res.data.state) {
        axios
          .get(`/gcs/mission/${store.getters.GetLoginData.id}`)
          .then((res) => {
            res.data.data.forEach((mission: MissionObject) => {
              if (new_mission.alias == mission.alias) {
                store.commit("SetDeleteMission", []);
                const was_mission = new Mission(
                  mission.alias,
                  mission.account,
                  mission._id
                );
                mission.items.forEach((mission_item: MissionItem) => {
                  was_mission.AddItem(mission_item);
                });
                this._missions.push(was_mission);
              }
            });
          });
      this.SetMissionEntityVisible()
      }
    });
  }
  SetMissionDelete(delete_mission: MissionObject) {
    axios.delete("/gcs/mission", { data: delete_mission }).then((res) => {
      if (res.data.state) {
        this.SetMissions();
      }
    });
  }

  SetModifyMission(mission_item:MissionItem[]) {
    // console.log('mission_item', mission_item)
    if(this._modify_mission) {
      this._modify_mission = this._modify_mission.concat(mission_item)
    }
  }
  SetMissionAlias(mission_alias:string) {
    this._mission_alias = mission_alias
  }
  GetMissionAlias() {
    return this._mission_alias
  }
  ClearModifyMission() {
    this._modify_mission = []
  }
  GetModifyMission() {
    if(this._modify_mission) {
      return this._modify_mission
    } else {
      return []
    }
  }
  SetMissionPacket(mission_items:MissionItem[]) {
    let ret_mission_object:MissionObject|undefined;
    this._missions.forEach((mission: Mission) => {
      if (mission.GetName() == this._mission_alias) {
        const edit_mission_name = store.getters.GetEditMissionName;
        const mission_object: MissionObject = {
          _id: mission.GetMission_id(),
          account: mission.GetAccount(),
          alias: edit_mission_name == '' ? mission.GetName() : edit_mission_name,
          items: mission_items,
        };
        ret_mission_object = mission_object
      }
    });
    return ret_mission_object
  }
  SetSelectWaypointList(name:string) {
    const index_number = name.split("-")[1]
    this._waypoint_index_list.splice(0)
    this._waypoint_index_list.push(Number(index_number))
  }
  GetSelectWaypointList() {
    return this._waypoint_index_list
  }
  SetGCSMode(mode:GCS_MODE) {
    this._gcs_mode_info.mode_type = mode
  }
  GetGCSMode() {
    return this._gcs_mode_info
  }
  
  SetWaypointBoxState(state:boolean) {
    this._waypoint_box_state = state
  }
  GetWaypointBoxState() {
    return this._waypoint_box_state
  }
  WaypointLocation(lng:number, lat:number, alt:number) {
    const Cesium = cesiumService.GetCesium();
    const viewer = cesiumService.GetViewer();
    viewer.camera.setView({
        destination: Cesium.Cartesian3.fromDegrees(lng, lat, alt+40),
    });
  }
  GetMissionFlightTime(items:MissionItem[], drone:CESIUM_DRONE) {
    let whole_timewait = 0;
    let distance = 0;
    // let remain_time = 0;
     
    const Cesium = cesiumService.GetCesium();
    const mission_items = items;

    const current = drone.GetPosition().pos.clone();

    let from:MissionItem|undefined = undefined;
    let to:MissionItem|undefined = undefined;
     
    mission_items.forEach((mission_item:MissionItem) => {
        whole_timewait += mission_item.timeout;
        if(mission_item.type == MissionItemType.WAYPOINT) {
            if(from == undefined) {
                const distance_meters = Cesium.Cartesian3.distance(
                    current,
                    new Cesium.Cartesian3.fromDegrees(
                        mission_item.lng,
                        mission_item.lat,
                        mission_item.display
                    )
                );
    
                // console.log(1, distance_meters)
                distance += distance_meters;
                from = mission_item;
            }
            else {
                to = mission_item;
    
                const distance_meters = Cesium.Cartesian3.distance(
                    new Cesium.Cartesian3.fromDegrees(
                        from.lng,
                        from.lat,
                        from.display
                    ),
                    new Cesium.Cartesian3.fromDegrees(
                        to.lng,
                        to.lat,
                        to.display
                    )
                );
    
                // console.log(2, distance_meters)
                distance += distance_meters;
                from = mission_item;
            }
        }
    });
    // Math.ceil(distance / this._state._wp_nav._speed_cm * 1e2) + whole_timewait;
    const remain_time = Math.ceil(distance) + whole_timewait;

    let hour = 0;
    let minute = 0;
    let second = 0;
    hour = Math.floor(remain_time / 3600);
    minute = Math.floor((remain_time % 3600) / 60);
    second = Math.ceil(remain_time % 60);

    return `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}:${second.toString().padStart(2, '0')}`;
  }

  // SetMissionEntityTerrainHeight(terrainArr: any, entity_mission: MissionObject) { // 미션 Send 할 때 해당 드론의 terrain값을 고려해서 entity 다시 셋팅
  //   const viewer = cesiumService.GetViewer();
  //   let terrainArr_num = 0;
  //   viewer.entities.removeById(entity_mission.alias + "-mission-path");
  //   viewer.entities.removeById(entity_mission.alias + "-mission-floor");
  //   axios.get(`/gcs/mission/${store.getters.GetLoginData.id}`).then((res) => {
  //     res.data.data.forEach((mission: MissionObject) => {
  //       if (mission.alias == entity_mission.alias) {
  //         const Cesium = cesiumService.GetCesium();
  //         entity_mission.items.forEach((item: MissionItem) => {
  //           if(item.type == MissionItemType.WAYPOINT || item.type == MissionItemType.LAND) {
  //             const cartographic = Cesium.Cartographic.fromDegrees(
  //               item.lng,
  //               item.lat,
  //               terrainArr[terrainArr_num]
  //               );
  //             this._entity.push(Cesium.Cartographic.toCartesian(cartographic));
  //             terrainArr_num += 1
  //           }
  //         });
  //         const mission_entity = viewer.entities.getOrCreateEntity(
  //           entity_mission.alias + "-mission-path"
  //         );
  //         // _mission_entitys.set(view_mission + "-mission-path", mission_entity);
  //         mission_entity.name = entity_mission.alias;
  //         mission_entity.polyline = {
  //           positions: this._entity,
  //           material: new Cesium.PolylineGlowMaterialProperty({
  //             glowPower: 0.25,
  //             color: Cesium.Color.fromCssColorString(UserSettingManager.getInstance().Get().color_mission),
  //           }),
  //           width: 5,
  //           arcType: Cesium.ArcType.NONE,
  //         };
  //         const mission_floor_entity = viewer.entities.getOrCreateEntity(
  //           entity_mission.alias + "-mission-floor"
  //         );
  //         mission_floor_entity.polyline = {
  //           positions: this._entity,
  //           width: 5,
  //           clampToGround: true,
  //         };
  //         this._entity = [];
  //       }
  //     });
  //   });
  // }
}
