import {
  HG_DroneNoti,
  HG_DroneState,
} from "../ws/ws_hg_server_packet_interface";

import CESIUM_DRONE from "./cesium_drone";

import store from "@/store";
import { cesiumService } from "../cesium/cesium_service_inst";
import CustomAlert, { CustomAlertOptions } from "./custom_alert";
import SingleManager, { CONTROLLER_MODE } from "./single/single_manager";

export default class DroneManager {
  private static instance: DroneManager;

  public static getInstance() {
    return this.instance || (this.instance = new this());
  }

  private _ceisum_drone_list: Map<string, CESIUM_DRONE>;
  private _drone_noti_list: HG_DroneNoti[];
  private _detail_data_drone_name: string
  
  private constructor() {
    this._ceisum_drone_list = new Map<string, CESIUM_DRONE>();
    this._drone_noti_list = [];
    this._detail_data_drone_name = '';
  }

  GetDrone(name: string) {
    return this._ceisum_drone_list.get(name);
  }

  GetDroneList() {
    return this._ceisum_drone_list;
  }

  UpdateDroneNotiList(drone_noti_list: HG_DroneNoti[]) {
    if (cesiumService.isReady() == false) return;

    drone_noti_list.forEach((drone_noti:HG_DroneNoti) => {
      if(this.GetDrone(drone_noti._name)) {
        this._ceisum_drone_list.get(drone_noti._name)?.SetNoti(drone_noti);
      }
    });

    const add = drone_noti_list.filter(
      (x) => !this._drone_noti_list.some((y) => x._name === y._name)
    );
    // const keep = this._drone_noti_list.filter(x => drone_noti_list.some((y) => x._name === y._name));
    const remove = this._drone_noti_list.filter(
      (x) => !drone_noti_list.some((y) => x._name === y._name)
    );

    const connected_custom_alert_options: CustomAlertOptions = {
      position: "bottom left",
      color: "green",
    };
    const removed_custom_alert_options: CustomAlertOptions = {
      position: "bottom left",
      color: "warning",
    };

    add.forEach((a) => {
      CustomAlert.getInstance().ShowAlert(
        `${a._name} connected`,
        connected_custom_alert_options
      );
      this._drone_noti_list.push(a);
      this._ceisum_drone_list.set(a._name, new CESIUM_DRONE(a));
    });
    // keep.forEach(k => {console.log('keep:',k._name);});
    remove.forEach((r) => {
      // console.log('drone state remove', store.getters.GetDroneStateInfo)
      // if (store.getters.GetDroneStateInfo != 0) { // state창을 띄어놓은 드론이 연결 해제되었을때 state창 close
      //   const DroneState_name = store.getters.GetDroneStateInfo._noti._name;
      //   if (DroneState_name == r._name) {
      //     store.commit("SetDroneState", false);
      //   }
      // }
      // if (store.getters.GetGCSModeInfo.drone_name == r._name){ // 드론이 tracking 모드인 상태에서 해제되었을 때 tracking 모드 해제
      //   cesiumService.GetViewer().trackedEntity = undefined;
      //   MissionManager.getInstance().SetGCSMode(GCS_MODE.NORMAL)
      // }

      // 드론이 연결 해제된 경우 현재 싱글 컴포넌트에서 선택한 드론도 해제시킨다.
      const selected_single_drone : CESIUM_DRONE | undefined = store.getters.GetSingleDrone;
      if(selected_single_drone){
        if (selected_single_drone.GetNoti()._name == r._name) {
          store.commit("SetControllerMode", CONTROLLER_MODE.NONE);
          store.commit("SetSingleDrone", undefined);
          SingleManager.getInstance().SetTargetDelete();
        }
      }
      CustomAlert.getInstance().ShowAlert(
        `${r._name} disconnected`,
        removed_custom_alert_options
      );
      if (this._ceisum_drone_list.has(r._name)) {
        let drone = this._ceisum_drone_list.get(r._name);
        drone?.DestroySelf();
        drone = undefined;
        this._ceisum_drone_list.delete(r._name);
      }
      const findIndex = this._drone_noti_list.indexOf(r);
      this._drone_noti_list.splice(findIndex, 1);
    });

    if (add.length != 0 || remove.length != 0) {
      store.commit('SetDrones', Array.from(this._ceisum_drone_list.values()));
    }
  }

  UpdateDroneState(name: string, drone_state: HG_DroneState) {
    const viewer = cesiumService.GetViewer();
    if(viewer && viewer.scene && viewer.scene.globe && viewer.scene.globe && viewer.scene.globe.tilesLoaded)
    {
      if (this._ceisum_drone_list.has(name)) {
        this._ceisum_drone_list.get(name)?.SetState(drone_state);
      }
    }

  }

  SetDroneDetailData(drone_name:string) {
    this._detail_data_drone_name = drone_name
  }
  GetDroneDetailData() {
    return this._detail_data_drone_name
  }
}
