
import CesiumScreenSpaceEventService, { GCS_FEATURE } from "@/services/cesium/cesium_screen_space_ev_service";
import NoFlightZone from "@/services/hg/no_fllight_zone";
import SimulateManager from "@/services/hg/simulate/simulate_manager";
import store from "@/store";
import "@/styles/variables.scss";
import { computed, defineComponent } from "vue";
export default defineComponent({
  data() {
    return {
      noFlightZone: false,
      tab_open: false,
      function_item: [
        { title: "Controller", icon: "Controller", action: "showController" },
        { title: "No Flight", icon: "noflight", action: "NoFlight" },
        { title: "Firmware Update", icon: "update", action: "Firmware" },
        { title: "Setting", icon: "Setting", action: "Setting" },
        { title: "Log View", icon: "LogView", action: "LogView" },
        // { title: "H2 Log", icon: "h2-log", action: "H2LogData" },
        // { title: "License", icon: "license", action: "License" },
        // { title: "GCS Guide", icon: "test1", action: "GcsGuide" },
      ],
      extension_function: [
        { title: "Drone TV", icon: "drone tv", action: "DroneTV" },
        { title: "Facial Recognition", icon: "FacialRecognition", action: "FacialRecognition" },
        { title: "AI", icon: "ai_icon", action: "ai" },
      ],
    };
  },

  setup() {
    const account_id = computed(() => store.getters.GetAccountID);
    const app_type = computed(() => store.getters.GetAppType);
    const side_menu_tab = computed(() => store.getters.GetSideMenuTab);
    const noFlightZone = computed(() => store.getters.GetNoFlightZone);
    const license_state = computed(() => store.getters.GetLicenseState);
    const fc_log_data = computed(() => store.getters.GetFcLogData);
    const h2_log_data = computed(() => store.getters.GetH2LogData);
    const extension_function_state = computed(() => store.getters.GetExtensionFunctionState);
    return { side_menu_tab, account_id, app_type, license_state, fc_log_data, h2_log_data, extension_function_state };
  },
  watch: {
    side_menu_tab: {
      handler(n, o) {
        this.tab_open = n;
      },
    },
  },
  methods: {
    OnClickController() {
      const showSingle = store.getters.GetShowSingle;
      showSingle
        ? CesiumScreenSpaceEventService.getInstance().SetMode(
          GCS_FEATURE.NORMAL
        )
        : CesiumScreenSpaceEventService.getInstance().SetMode(
          GCS_FEATURE.SINGLE
        );
      store.commit("SetShowSingle", !showSingle);
    },
    OnClickLogView() {
      store.commit("SetSideMenuTab", false);
      store.commit("SetShowSingle", false);
      store.commit("SetFcLogData", !this.fc_log_data);
    },
    OnClickH2LogData() {
      store.commit("SetH2LogData", !this.h2_log_data);
    },
    OnClickFacialRecognition() {
      store.commit("SetSideMenuTab", false);
      store.commit("SetFacialRecognition", true);
    },
    OnClickExtensionFunction() {
      store.commit("SetSideMenuTab", false);
      store.commit("SetExtensionFunction", true);
    },
    OnClickBack() {
      store.commit("SetSideMenuTab", false);
    },
    OnClickCreateVirtualDroneTestBtn() {
      CesiumScreenSpaceEventService.getInstance().SetMode(GCS_FEATURE.NORMAL)
      store.commit("SetShowSingle", false);
      CesiumScreenSpaceEventService.getInstance().SetMode(GCS_FEATURE.CVD);
    },
    OnClickNoFlightZone() {
      NoFlightZone.getInstance().SetShow(store.getters.GetNoFlightZone);
      store.commit("SetNoFlightZone", !store.getters.GetNoFlightZone);
    },
    OnClickFCForceUpdate() {
      store.commit("SetShowFCForceUpdate", !store.getters.GetShowFCForceUpdate);
    },
    OnClickSetting() {
      store.commit("SetShowUserSetting", !store.getters.GetShowUserSetting);
    },
    OnClickRunSimulationTestBtn() {
      SimulateManager.getInstance().RunSimulate();
    },
    OnClickLicense() {
      store.commit("SetLicenseState", !this.license_state);
    },
    OnClickAiExtension() {
      store.commit("SetExtensionFunctionState", !this.extension_function_state);
    },
    OnClickGcsGuide() {
      store.commit('SetGcsGuideState', true)
    },
    OnClickDroneTV() {
      store.commit("SetShowDroneTV", !store.getters.GetShowDroneTV);
    },
  },
});
