export enum E_MESSAGE_TYPE {
   NONE = 0,
   LOGIN,
   RESERVE_1,
   RESERVE_2,
   RESERVE_3,
   LOST_AUTHENTICATION,
   STATE_NOTI,
   MISSION_START,
   MISSION_SUSPEND,
   MISSION_RESUME,
   MISSION_STOP,
   MISSION_REGISTER,
   MISSION_ADD,
   MISSION_REGISTER_AUTOSTART,
   MISSION_AVOIDANCE_REQUEST,
   MISSION_AVOIDANCE_SWITCH,
   RTT,
   LOG,
   MISSION_GIMBAL_CONTROL,
   MISSION_RC_CHANNEL,
   STATUS,
   HYDROGEN_STATUS,
   HYDROGEN_FAULT,
   MISSION_MANUAL_CONTROL,
   GIMBAL_PTZ_STATUS,
   SPECIAL_SETUP,
   FENCE_AI_COORDINATE = 80,
   FENCE_AI_IMAGE,
   DRONE_TV_CHANNEL = 100,
   FC_LOG = 200,
   FC_LOG_LIST_REPLY,
   FC_LOG_DATA_PROGRESS_INFO = 202,
   FC_LOG_DATA_REPLY = 202,      //same FC_LOG_DATA_PROGRESS_INFO
   HYDROGEN_SYSTEM_MSG_START = 1000,
   STACK_LIST,
   STACK_SUBSCRIPTION,
   STACK_LOG_FILE_LIST,
   STACK_LOG_FILE_DATA,
   STACK_LOG_FILE_LIST_DRONE,
   STACK_LOG_FILE_LIST_ESS,
   STACK_CMD,
   STACK_NOTE_DATA,
   STACK_NOTE_UPDATE,
   HYDROGEN_SYSTEM_MSG_END = 1500,

   UPDATER_MSG_START = 2000,
   UPDATE_REQ,
   UPDATE_RES,
   UPDATER_MSG_END = 2100,

   AI_SYSTEM_MSG_START = 3000,
   AI_REQ_STREAM_LIST,
   AI_RES_STREAM_LIST,
   AI_IMAGE,
   AI_META,
   AI_SYSTEM_MSG_END = 3100,

   LIQUID_GENERATOR_MSG_START = 4000,
   LIQUID_GENERATOR_STATE,
   LIQUID_GENERATOR_MSG_END = 4100,
   END
}
export enum State {
    STATE_NONE = 0,
    STATE_RUN, 
    STATE_SUSPEND, 
    STATE_DONE, 
    STATE_END,
}

export enum Command {
  COMMAND_NONE = 0,
  COMMAND_READY2FLIGHT,
  COMMAND_MODE,
  COMMAND_COORDINATE,
  COMMAND_VIRTUAL_KEY,
  COMMAND_TIMEWAIT,
  COMMAND_CHANNEL_OVERRIDE,
  COMMAND_MANUAL_CONTROL,
  COMMAND_CONDITION_YAW,
  COMMAND_GIMBAL_GCS = 80,
  COMMAND_AVOIDANCE_OBSTACLE = 100,
  COMMAND_AVOIDANCE_OFF,
  COMMAND_AVOIDANCE_ON,
  // COMMAND_FCACTIVATION,
  COMMAND_FC_LOG_LIST = 200,
  COMMAND_FC_LOG_DATA,
  COMMAND_FC_LOG_DELETE,
  COMMAND_ADDITIONAL_CMD_START = 1000,
  COMMAND_ROI_SET,
  COMMAND_ROI_RESET,
  COMMAND_ADDITIONAL_CMD_END = 2000,
  COMMAND_END
}

export enum SubCommand {
    SUB_COMMAND_NONE = 0, 
    SUB_COMMAND_ARM, 
    SUB_COMMAND_DISARM, 
    SUB_COMMAND_TAKEOFF, 
    SUB_COMMAND_GUIDE_MODE, 
    SUB_COMMAND_LOITER_MODE, 
    SUB_COMMAND_RETURN2LAND_MODE, 
    SUB_COMMAND_LAND_MODE, 
    SUB_COMMAND_ALT_OPTION_ABSOLUTE = 1000,
    SUB_COMMAND_ALT_OPTION_RELATIVE,
    SUB_COMMAND_ALT_OPTION_TERRAIN,      //Not support yet...
    // SUB_COMMAND_COORDINATE,
    // SUB_COMMAND_VIRTUAL_KEY,
    // SUB_COMMAND_TIMEWAIT,

    SUB_COMMAND_PTZ_START = 10000,
    SUB_COMMAND_PTZ_CONTINOUS,
    SUB_COMMAND_PTZ_STOP,
    SUB_COMMAND_PTZ_HOME,
    SUB_COMMAND_PTZ_END,

    SUB_COMMAND_END,
}

export enum E_APP_TYPE {
    NONE = 0,
    FC = 1 << 0,
    ESS = 1 << 1,
    GCS = 1 << 2,
    GCS_MONITOR = 1 << 3,
    DRONE_TV = 1 << 4,
    HYDROGEN = 1 << 5,
    ADMIN = 1 << 6,
    AI_CONTAINER = 1 << 7,
    AI_MODULE = 1 << 8,
    LIQUID_GENERATOR = 1 << 9,
    STREAMING_STORAGE = 1 << 20,
  }

export enum MissionState
{
   NONE = 0,
   RUN,
   SUSPEND,
   //CANCEL,
   DONE,
   END
}

export enum E_AI_TYPE {				// AI 종류
  NONE = 0,
  PEOPLE_COUNTING,
  VEHICLE_TYPE,
  FENCE_DETECT,
  END
}