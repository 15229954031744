import { Easing, Tween } from "@tweenjs/tween.js";

import ws from "@/services/ws/ws_hg_server";
import store from "@/store";
import axios from "axios";
import { computed } from "vue";
import { GCS_MODE } from "../cesium/cesium_screen_space_ev_service";
import { cesiumService } from '../cesium/cesium_service_inst';
import { HG_AltTracking, HG_Contents, HG_Coordinate, HG_DroneNoti, HG_DroneState, HG_Header, HG_Mission, HG_Packet } from "../ws/ws_hg_server_packet_interface";
import { Command, E_MESSAGE_TYPE, MissionState } from "../ws/ws_hg_server_protocol";
import { E_EQUIPMENT, E_GPS } from "./drone_equipment_type";
import MissionManager, { MissionItem, MissionItemType, MissionObject } from "./mission/mission_manager";
import SimulateManager from "./simulate/simulate_manager";
import STACK from "./stack/Stack";
import StackManager from "./stack/StackManager";
import UserSettingManager from "./user_setting/user_setting_manager";

export interface DronePos {
    pos: any;
    ori: any;
}

const _mission_destination_entitys:Map<string,any> = new Map();
const _drone_entitys:Map<string,any> = new Map();
const _drone_wall_entitys:Map<string,any> = new Map();
const _mission_path_entitys:Map<string,any> = new Map();

export default class CESIUM_DRONE {

    // CESIUM_DRONE 클래스 생성 리턴값으로 아래 변수값들을 줌

    private _noti:HG_DroneNoti;
    private _state:HG_DroneState|undefined;

    private _armed = false;
    private _arm_globeHeight = 0;

    private _drone_position:DronePos;

    private _mission_index:number|undefined = undefined;
    private _waypoint_mission_state:MissionState|undefined = undefined;
    private _mission_state:MissionState|undefined = undefined;

    private _mission_uuid:string|undefined = undefined;
    private _mission:MissionObject|undefined = undefined;
    private _mission_path: any;

    private _camera_height = 0

    private _remain_flight_time:number|undefined = undefined;

    // private _flight_history: any[] = [];

    private _tweener:Tween<DronePos>|undefined = undefined;

    private _pipe_glow_power = .4;

    // ROI Location
    private _roi_lng:number|undefined = undefined;
    private _roi_lat:number|undefined = undefined;

    // 수소 자료 - flight time 설정 값
    private _flight_time:string|undefined = undefined;
    private _flight_time_hy = 0;
    private _flight_time_tick:any = null;
    private _hyd_data_tick:any = null;
    private _output_power:number|undefined = undefined;
    private _stack_type:string|undefined = undefined;
    private _fcpm_type:string|undefined = undefined;
    private _gh_tank_level:number|undefined = undefined;
    private _gh2_tank1_level:number|undefined = undefined;
    private _gh2_tank2_level:number|undefined = undefined;
    private _lh2_tank_volume:number|undefined = undefined;
    private _lh2_remain_level:number|undefined = undefined;
    private _lh2_total_use:number|undefined = undefined;
    private _input_lh2_gram:number|undefined = undefined;

    private entity():any {
        if(_drone_entitys.has(this._noti._name))
        {
            return _drone_entitys.get(this._noti._name);
        }
        return undefined;
    }

    private entityDestination():any {
        if(_mission_destination_entitys.has(this._noti._name+"-mission-destination"))
        {
            return _mission_destination_entitys.get(this._noti._name+"-mission-destination");
        }
        return undefined;
    }

    private entityMissionPath():any {
        if(_mission_path_entitys.has(this._noti._name+"-mission-path"))
        {
            return _mission_path_entitys.get(this._noti._name+"-mission-path");
        }
        return undefined;
    }
    
    private entityID():string {
        if(_drone_entitys.has(this._noti._name))
        {
            return _drone_entitys.get(this._noti._name).id;
        }
        return '';
    }

    private entityReady():boolean {
        return _drone_entitys.has(this._noti._name) ? true : false;
    }

    private entityKill():void {
        _drone_entitys.delete(this._noti._name);
        _mission_path_entitys.delete(this._noti._name+'-mission-path');
        _drone_wall_entitys.delete(this._noti._name+'-wall');
        _mission_destination_entitys.delete(this._noti._name+'-mission-destination');
    }

    constructor(noti:HG_DroneNoti) {
        this._noti = noti;
        this._state = undefined;

        const viewer = cesiumService.GetViewer();
        const Cesium = cesiumService.GetCesium();

        this._drone_position = {
            pos: undefined,
            ori: undefined,
        }

        this._mission_path = [];

        const mission_path_entity = viewer.entities.getOrCreateEntity(this._noti._name+"-mission-path");
        _mission_path_entitys.set(this._noti._name+"-mission-path", mission_path_entity);
        mission_path_entity.show = false;
        mission_path_entity.polyline = {
          positions: new Cesium.CallbackProperty(() => {
            return this._mission_path;
          }, false),
          material: new Cesium.PolylineGlowMaterialProperty({
            glowPower: 0.25,
            color: Cesium.Color.fromRandom({
              maximumAlpha: 1.0,
              minimumAlpha: 1.0,
            }),
          }),
          width: 20,
          arcType: Cesium.ArcType.NONE,
        };

        const mission_destination = viewer.entities.getOrCreateEntity(this._noti._name+"-mission-destination");
        _mission_destination_entitys.set(this._noti._name+"-mission-destination", mission_destination);
        mission_destination.show = false;
        // 위치
        mission_destination.position = new Cesium.CallbackProperty( () => { 
            const current_mission_index = this.GetMissionIndex();
            const current_waypoint_mission_state = this.GetWaypointMissionState();
            if(this._mission && current_mission_index != undefined && current_waypoint_mission_state != undefined && current_waypoint_mission_state != MissionState.DONE) {
                const pos = Cesium.Cartesian3.fromDegrees(
                    this._mission.items[current_mission_index].lng
                    , this._mission.items[current_mission_index].lat
                    , this._mission.items[current_mission_index].display)
                return pos
            }
            return new Cesium.Cartesian3();
            
        }, false);

        // 본체
        mission_destination.model = 
        {
            uri : '/DroneModel/Pointing Arrow.glb',
            scale: .2,
        };
     
    }

    Get() {
        return this;
    }

    Roll(): number {
        if(this._state) {
            return this._state._attitude._roll * 180 / Math.PI;
        }
        return 0;
    }

    Pitch(): number {
        if(this._state) {
            return this._state._attitude._pitch * 180 / Math.PI;
        }
        return 0;
    }

    Yaw(): number {
        if(this._state) {
            return this._state._attitude._yaw * 180 / Math.PI;
        }
        return 0;
    }

    Mode(): number {
        if(this._state) {
            return this._state._heartbeat._custom_mode;
        }
        return 0;
    }

    SetRemainFlightTimeSec() {

        let whole_timewait = 0;
        let distance = 0;
        // console.log('드론 비행 속도 테스트', this._state?._wp_nav?._speed_cm)
        if(this._mission != undefined && this._mission_index != undefined && this.GetPosition() && this._state && this._state._wp_nav) {
            const Cesium = cesiumService.GetCesium();
            const mission_items = this._mission.items;

            const current = this.GetPosition().pos.clone();

            let from:MissionItem|undefined = undefined;
            let to:MissionItem|undefined = undefined;
            
            mission_items.forEach((mission_item:MissionItem) => {
                if(this._waypoint_mission_state == MissionState.DONE) return;
                if(this._mission_index != undefined) {
                    if(Number(mission_item.id) < this._mission_index) return;
                }

                whole_timewait += mission_item.timeout;
                /**
                 * TODO
                 * 방향 꺽을때 가속도를 통한 속도까지 도달 시간을 구하여 더한다.
                 * 가속도 / 속도 를 가산
                 */
                if(this._state && this._state._wp_nav) {
                    whole_timewait += (this._state._wp_nav._accel_cm / this._state._wp_nav._speed_cm)
                }
                else {
                    whole_timewait += 1;
                }

                if(mission_item.type == MissionItemType.WAYPOINT) {
                    if(from == undefined) {
                        const distance_meters = Cesium.Cartesian3.distance(
                            current,
                            new Cesium.Cartesian3.fromDegrees(
                                mission_item.lng,
                                mission_item.lat,
                                mission_item.display
                            )
                        );
    
                        // console.log(1, distance_meters)
                        distance += distance_meters;
                        from = mission_item;
                    }
                    else {
                        to = mission_item;
    
                        const distance_meters = Cesium.Cartesian3.distance(
                            new Cesium.Cartesian3.fromDegrees(
                                from.lng,
                                from.lat,
                                from.display
                            ),
                            new Cesium.Cartesian3.fromDegrees(
                                to.lng,
                                to.lat,
                                to.display
                            )
                        );
    
                        // console.log(2, distance_meters)
                        distance += distance_meters;
                        from = mission_item;
                    }
                }
            });

            
            if(this._state && this._state._wp_nav) {
                // console.log(distance, whole_timewait);
                if(distance == 0) {
                    this._remain_flight_time = 0;    
                }
                else {
                    this._remain_flight_time = Math.ceil(distance / this._state._wp_nav._speed_cm * 1e2) + whole_timewait;
                }
            }
            
        }
    }

    GetRemainFlightTimeSec(): number|undefined {
        if(this._mission) {
            return this._remain_flight_time;
        }
        else {
            return undefined;
        }
    }

    GetRemainFlightTimeText(): string {
        if(this._waypoint_mission_state == MissionState.DONE) {
            return '00:00:00';
        }
        else if(this._remain_flight_time) {
            let hour = 0;
            let minute = 0;
            let second = 0;

            hour = Math.floor(this._remain_flight_time / 3600);
            minute = Math.floor((this._remain_flight_time % 3600) / 60);
            second = Math.ceil(this._remain_flight_time % 60);

            return `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}:${second.toString().padStart(2, '0')}`;
        }
        else {
            return '00:00:00';
        }
    }

    GetProgressInfo(): number {
        let percent = 0;
        if(this._waypoint_mission_state == MissionState.DONE) {
            percent = 100;
        }
        else if(this._mission_index == 0) {
            percent = 0;
        }
        else if(this._mission_index != undefined && this._mission) {
            percent = (this._mission_index / this._mission.items.length * 1e2)
        }
        else {
            percent = 0;
        }

        return percent;
    }

    GetVersion() {
        return this.GetNoti()._version;
    }

    GetLatestVersion() {
        return this.GetNoti()._latest_version;
    }

    GetMissionIndex() {
        return this._mission_index;
    }

    GetWaypointMissionState() {
        return this._waypoint_mission_state;
    }

    GetMissionState() {
        return this._mission_state;
    }

    DestroySelf() {
        if(this._tweener) {
            this._tweener.stop();
            this._tweener.end();
        }


        const viewer = cesiumService.GetViewer();
        viewer.entities.removeById(this._noti._name);
        viewer.entities.removeById(this._noti._name+'-cylinder');
        viewer.entities.removeById(this._noti._name+'-path');
        viewer.entities.removeById(this._noti._name+'-direct-move-path');
        viewer.entities.removeById(this._noti._name+'-mission-path');
        viewer.entities.removeById(this._noti._name+'-wall');
        viewer.entities.removeById(this._noti._name+'-mission-destination');
        this.entityKill();
    }

    IsRunningSimulate():boolean {
        return SimulateManager.getInstance().IsRunSimulate(this._noti._name);
    }

    GetSimulatePathByMission():MissionObject|undefined {
        if(this._mission && this._state && this._drone_position.pos)
        {
            const Cesium = cesiumService.GetCesium();
            const current_position = this._drone_position.pos;
            const cartographic = Cesium.Cartographic.fromCartesian(current_position);
            const longitude = Cesium.Math.toDegrees(cartographic.longitude);
            const latitude = Cesium.Math.toDegrees(cartographic.latitude);
            const height = cartographic.height;

            const starting_position:MissionItem = {
                id: '-1',
                lng: longitude,
                lat: latitude,
                timeout: 0,
                type: MissionItemType.WAYPOINT,
                asl: 0,
                display: height,
                agl: 0,
            }
            const mission_object:MissionObject = {
                account: this._mission.account,
                alias: this._noti._name,
                items: [starting_position, ...this._mission.items],
            }

            return mission_object;
        }
        return undefined;
    }

    GetMission(): MissionObject|undefined {
        return this._mission;
    }

    SetMission(mission:MissionObject) {
        const Cesium = cesiumService.GetCesium();

        this._mission_path = [];
        this._mission = mission;
        const mission_items = mission.items;
        
        mission_items.forEach((mission_item:MissionItem) => {
            if(mission_item.type != MissionItemType.WAYPOINT) return;
            this._mission_path.push(Cesium.Cartesian3.fromDegrees(mission_item.lng, mission_item.lat, mission_item.display))
        });

        if(this._drone_position.pos && this._mission) {
            // TakeOff
            if(this._mission.items.length > 1) {
                if(this._mission.items[1].type == MissionItemType.TAKEOFF) {
                    const current_drone_position_cartographic = Cesium.Cartographic.fromCartesian(this.GetPosition().pos);
                    current_drone_position_cartographic.height += this._mission.items[1].agl;
                    this._mission_path.unshift(Cesium.Cartographic.toCartesian(current_drone_position_cartographic));
                }
            }
            if(this._mission.items.length > 0) {
                // Arm
                if(this._mission.items[0].type == MissionItemType.ARM) {
                    this._mission_path.unshift(this._drone_position.pos.clone());
                }
                // RTL
                if(this._mission.items[this._mission.items.length - 1].type == MissionItemType.RTL) {
                    // 수정 필요 (끝 지점 좌표의 데카르트 relative 0 지점 대입)
                    this._mission_path.push(this._drone_position.pos.clone());
                }
            }
            if(this._mission.items.length > 1) {
                // LAND
                if(this._mission.items[this._mission.items.length - 1].type == MissionItemType.LAND) {
                    this._mission_path.push(new Cesium.Cartesian3.fromDegrees(
                        this._mission.items[this._mission.items.length - 2].lng,
                        this._mission.items[this._mission.items.length - 2].lat,
                        0,
                    ));
                }
            }
        }
    }

    ClearMission() {
        this._mission = undefined;
        this._mission_path = [];
    }

    GetNoti() {
        return this._noti;
    }

    SetNoti(noti:HG_DroneNoti) {
        this._noti = noti;
    }

    GetState() {
        return this._state
    }

    SetState(state:HG_DroneState) {
        
        if(this._state)
        {
            const Cesium = cesiumService.GetCesium();
            const viewer = cesiumService.GetViewer();

            if(Cesium && viewer)
            {
                if(state._gps_int._lon == 0 && state._gps_int._lat == 0)
                {// 호그린에어 사무실 위치로 설정
                    state._gps_int._lon = 126.860225059 * 1e7;
                    state._gps_int._lat = 35.2363779143 * 1e7;

                    const cartographic = Cesium.Cartographic.fromDegrees(state._gps_int._lon / 1e7, state._gps_int._lat / 1e7);
                    state._gps_int._alt = (viewer.scene.globe.getHeight(cartographic) + 100) * 1000;
                }
            }

            this.setPosition();
        }
        else
        {
            this.droneInit(state);
        }

        // 현재 드론 정보를 해당 드론의 state 변수에 실시간으로 넣어줌
        this._state = state;
    }

    DisplayMe() {
        const Cesium = cesiumService.GetCesium();
        const viewer = cesiumService.GetViewer();
        viewer.trackedEntity = undefined;
        if (this._state) {
            const cartographic = Cesium.Cartographic.fromCartesian(this.GetPosition().pos);
            // console.log('DisplayMe', this.GetPosition().pos)
            cartographic.height = cartographic.height + 100;
            Cesium.Cartographic.toCartesian(cartographic);
            viewer.camera.setView({
                destination: Cesium.Cartographic.toCartesian(cartographic),
            });
        }
    }

    FollowMe() {
        if(this.entityReady())
        {
            const viewer = cesiumService.GetViewer();

            if(viewer.trackedEntity == undefined || viewer.trackedEntity.id != this.entityID())
            {
                viewer.trackedEntity = this.entity();
            }
            else
            {
                viewer.trackedEntity = undefined;
                MissionManager.getInstance().SetGCSMode(GCS_MODE.NORMAL)
            }
        }
    }

    IsFollowMe() {
        const viewer = cesiumService.GetViewer();
        if(viewer.trackedEntity != undefined && viewer.trackedEntity.id == this.entityID())
        {
            return true;
        }

        return false;
    }

    IsArmed() {
        return this._armed;
    }

    Deselect() {
        if(this.entityReady())
        {
            this.entity().model.silhouetteSize = 0;
        }

        if(this.entityDestination()) {
            this.entityDestination().show = false;
        }

        if(this.entityMissionPath()) {
            this.entityMissionPath().show = false;
        }
    }

    Select() {
        if(this.entityReady())
        {
            this.entity().model.silhouetteSize = 3;
        }

        if(this.entityDestination()) {
            this.entityDestination().show = true;
        }

        if(this.entityMissionPath()) {
            this.entityMissionPath().show = true;
        }
    }

    private droneInit(state:HG_DroneState) {
        const viewer = cesiumService.GetViewer();
        const Cesium = cesiumService.GetCesium();

        this._drone_position = {
            pos: Cesium.Cartesian3.fromDegrees(state._gps_int._lon / 1e7, state._gps_int._lat / 1e7, state._gps_int._alt / 1000),
            ori: Cesium.Transforms.headingPitchRollQuaternion(Cesium.Cartesian3.fromDegrees(state._gps_int._lon / 1e7, state._gps_int._lat / 1e7, state._gps_int._alt / 1000), new Cesium.HeadingPitchRoll(0,0,0)),
        }

        
        const drone_entity = viewer.entities.getOrCreateEntity(this._noti._name);
        _drone_entitys.set(this._noti._name, drone_entity);
        
        // 드론 위치
        drone_entity.position = new Cesium.CallbackProperty( () => { 
            if(this._drone_position.pos) return this._drone_position.pos; 
            return new Cesium.Cartesian3();
            
        }, false);

        // 드론 3축 Yaw Pitch Roll
        drone_entity.orientation = new Cesium.CallbackProperty( () => { 
            if(this._drone_position.ori) return this._drone_position.ori;
            return new Cesium.Quaternion(); 
        }, false);

        // 드론 본체
        drone_entity.model = 
        {
            uri : '/DroneModel/powerpack_drone_rotate0.glb',
            scale: 15.0,
            silhouetteColor: Cesium.Color.WHITE,
            silhouetteSize: 0,
            lightColor: new Cesium.Cartesian3(9,9,9),
        };

        drone_entity.viewFrom = new Cesium.Cartesian3(-20.0, -20.0, 10.0);

        // // 드론 헤딩 표시
        // drone_entity.ellipsoid = {
        //     radii: new Cesium.Cartesian3(20.0, 20.0, 20.0),
        //     innerRadii: new Cesium.Cartesian3(4.0, 4.0, 4.0),
        //     minimumClock: Cesium.Math.toRadians(-15.0),
        //     maximumClock: Cesium.Math.toRadians(15.0),
        //     minimumCone: Cesium.Math.toRadians(75.0),
        //     maximumCone: Cesium.Math.toRadians(105.0),
        //     material: new Cesium.ColorMaterialProperty(Cesium.Color.WHITE.withAlpha(0.3)),
        //     outline: true,
        //     outlineColor: Cesium.Color.WHITE,
        // }

        // // 드론 바닥 표시
        // drone_entity.ellipse = {
        //     semiMinorAxis: 10.0,
        //     semiMajorAxis: 10.0,
        //     material: "Res/drone-circle-3.png",
        //     stRotation : new Cesium.CallbackProperty( () => { 
        //         this._floor_circle_rotation++;
        //         if(this._floor_circle_rotation >= 360)
        //         {
        //             this._floor_circle_rotation = 0;
        //         }
        //         return Cesium.Math.toRadians(this._floor_circle_rotation); 
        //     }, false)
        // }

        // // 드론 고도 표시 기둥..
        // drone_entity.polyline = {
        //     positions: new Cesium.CallbackProperty(() => {
        //         if(this._drone_position.pos)
        //         {
        //             const cartographic = Cesium.Cartographic.fromCartesian(this._drone_position.pos);
        //             cartographic.height = 0;
        //             return [this._drone_position.pos, new Cesium.Cartographic.toCartesian(cartographic)];
        //         }
        //         return [];
        //     }, false),
        //     material: new Cesium.PolylineGlowMaterialProperty({
        //         glowPower: 0.25,
        //         color: Cesium.Color.fromRandom({
        //             maximumAlpha: 1.0,
        //             minimumAlpha: 1.0,
        //         }),
        //     }),
        //     width: 10,
        //     arcType: Cesium.ArcType.NONE,
        // }

        const drone_wall_entity = viewer.entities.getOrCreateEntity(this._noti._name+'-wall');
        _drone_wall_entitys.set(this._noti._name+'-wall', drone_wall_entity);
        drone_wall_entity.wall = {
            positions: new Cesium.CallbackProperty(() => {
                // if(this._drone_position.pos)
                // {
                //     this._flight_history.push(this._drone_position.pos.clone());
                //     if(this._flight_history.length > 100) {
                //         this._flight_history.shift();
                //     }
                //     if(this._flight_history.length > 1) {
                //         return this._flight_history;
                //     }
                // }
                // return this._flight_history;
                if(this._drone_position.pos)
                {
                    const cartographic = Cesium.Cartographic.fromCartesian(this._drone_position.pos);
                    const left = cartographic.clone();
                    const right = cartographic.clone();
                    const up = cartographic.clone();
                    const down = cartographic.clone();

                    up.longitude = up.longitude += 0.0000001;
                    down.longitude = down.longitude += -0.0000001;
                    left.latitude = left.latitude += 0.0000001;
                    right.latitude = right.latitude += -0.0000001;

                    return [
                        new Cesium.Cartographic.toCartesian(left), 
                        new Cesium.Cartographic.toCartesian(up),
                        new Cesium.Cartographic.toCartesian(right),
                        new Cesium.Cartographic.toCartesian(down),
                        new Cesium.Cartographic.toCartesian(left), 
                    ];
                }
                return [];
            }, false),
            material: Cesium.Color.fromCssColorString(UserSettingManager.getInstance().Get().color_drone_altitude),
        }
        setInterval(() => {
            this._pipe_glow_power += .005;
            if(this._pipe_glow_power > .9) {
                this._pipe_glow_power = .4;
            }

            drone_wall_entity.wall.material = new Cesium.PolylineGlowMaterialProperty({
                glowPower: this._pipe_glow_power,
                color: Cesium.Color.fromCssColorString(UserSettingManager.getInstance().Get().color_drone_altitude),
            })
        }, 33)

        // 드론 부연 설명
        
        // drone_entity.label = {
        //     // text: this._noti._name,
        //     text: new Cesium.CallbackProperty(() => {
        //         if(this.GetNoti()._simulate) {
        //             return `${this._noti._name}`
        //         }
        //         else if(this._state) {
        //             return `${this._noti._name}`
        //             + `${
        //                 (this._state._heartbeat._custom_mode == 4) ? ' / GCS' : 
        //                 (this._state._heartbeat._custom_mode == 5) ? ' / Controller' : 
        //                 (this._state._heartbeat._custom_mode == 6) ? ' / RTL' : 
        //                 (this._state._heartbeat._custom_mode == 9) ? ' / LAND' 
        //                 // : 'UNKNOWN'
        //                 : ''
        //             }`
        //             + `\n`
        //             + `\nARMED: ${(this._state.alt_tracking[0]._detect == 1) ? `Y` : 'N' }`
        //             + `\nAIRBORN: ${(this._state.alt_tracking[1]._detect == 1) ? `${(this._state._gps_int._relative_alt/1000).toFixed(0)}m` : 'N' }`
        //         }
        //         else {
        //             return `${this._noti._name}`
        //         }
        //     }),
        //     font: '16px NotoBold',
        //     style: Cesium.LabelStyle.FILL_AND_OUTLINE,
        //     pixelOffset: new Cesium.Cartesian2(0.0, -20.0),
        //     outlineColor : Cesium.Color.fromCssColorString('#1e1e1e'),
        //     fillColor: Cesium.Color.WHITE,
        //     outlineWidth : 7,
        //     // horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
        //     // verticalOrigin: Cesium.VerticalOrigin.CENTER,
        //     distanceDisplayCondition: new Cesium.DistanceDisplayCondition(0, 3500.0),
        //     disableDepthTestDistance: Number.POSITIVE_INFINITY,
        //     pixelOffsetScaleByDistance: new Cesium.NearFarScalar( 100, 1.0, 1e4, 0.1),
        //     scale: 1.5,
        //     scaleByDistance: new Cesium.NearFarScalar( 100, 1.0, 1e3, 0.5),
        // }
        // new Cesium.CallbackProperty(() => {
            // if(this._state) {
                // if(this._state._heartbeat._custom_mode == 4) {
        drone_entity.label = {
            text: new Cesium.CallbackProperty(() => {
                if(this.GetNoti()._simulate) {
                    return `${this._noti._name}`
                }
                else if(this._state) {
                    return `${this._noti._name}`
                   }
                else {
                    return `${this._noti._name}`
                }
            }),
            font: '25px NotoBold',
            style: Cesium.LabelStyle.FILL,
            pixelOffset: new Cesium.Cartesian2(70.0, -98.0),
            // eyeOffset: new Cesium.Cartesian3(0, 0, -150),
            fillColor: Cesium.Color.WHITE,
            distanceDisplayCondition: new Cesium.DistanceDisplayCondition(0, 500.0),
            disableDepthTestDistance: Number.POSITIVE_INFINITY,
            pixelOffsetScaleByDistance: new Cesium.NearFarScalar( 100, 1.0, 1e4, 0.1),
            scale: 1,
            scaleByDistance: new Cesium.NearFarScalar( 100, 1.0, 1e3, 0.5),
        }
        setInterval(() => {
            const a = new Cesium.Cartesian3(viewer.camera.positionWC.x, viewer.camera.positionWC.y, viewer.camera.positionWC.z)
            const b = Cesium.Cartographic.fromCartesian(a);
            this._camera_height = b.height;
        }, 1000)

        drone_entity.bollboard = { // 드론 헤딩방향 표시
            //
        }
        
        drone_entity.billboard = { // 드론 상태판
            image: new Cesium.CallbackProperty(() => {
                // console.log('H', this._camera_height)
                if(this._camera_height > 0 && this._camera_height < 500) {
                    if(this.GetNoti()._simulate) {
                        return "/DroneLabel/v-f.png"
                    } else if (this._state) {
                        const Mode = this._state._heartbeat._custom_mode
                        const Arm = this._state.alt_tracking[0]._detect
                        const AirBorn = this._state.alt_tracking[1]._detect
                        if(Mode == 4 &&Arm == 0) {
                            return "/DroneLabel/g-g.png"
                        } else if(Mode == 4 && Arm == 1 && AirBorn == 0) {
                            return "/DroneLabel/g-a.png"
                        } else if(Mode == 4 && Arm == 1 && AirBorn == 1) {
                            return "/DroneLabel/g-f.png"
                        } else if (Mode == 5 && Arm == 0) {
                            return "/DroneLabel/c-g.png"
                        } else if (Mode == 5 && Arm == 1 && AirBorn == 0) {
                            return "/DroneLabel/c-a.png"
                        } else if (Mode == 5 && Arm == 1 && AirBorn == 1) {
                            return "/DroneLabel/c-f.png"
                        } else {
                            return "/DroneLabel/c-g.png"
                        }
                     }
                } else if (this._camera_height > 500) {
                    return "/DroneLabel/drone-label.png"
                }
            }),
            
            scale: 0.4,
            // eyeOffset: new Cesium.Cartesian3(0, 0, -150),
            pixelOffset: new Cesium.Cartesian2(70.0, -70.0),
            distanceDisplayCondition: new Cesium.DistanceDisplayCondition(0, 15000),
            pixelOffsetScaleByDistance: new Cesium.NearFarScalar( 100, 1.0, 1e4, 0.1),
            scaleByDistance: new Cesium.NearFarScalar( 100, 1.0, 1e3, 0.5),
        }
        
        // drone_entity.billboard = {
        //     image: "/DroneLabel/drone-label.png",
        //     scale: 0.4,
        //     eyeOffset: new Cesium.Cartesian3(0, 0, -150),
        //     pixelOffset: new Cesium.Cartesian2(0.0, -70.0),
        //     distanceDisplayCondition: new Cesium.DistanceDisplayCondition(100, 3500.0),
        //     pixelOffsetScaleByDistance: new Cesium.NearFarScalar( 100, 1.0, 1e4, 0.1),
        //     scaleByDistance: new Cesium.NearFarScalar( 100, 1.0, 1e3, 0.5),
        // }   
                // } else if (this._state._heartbeat._custom_mode == 5) {
                    // drone_entity.billboard = {
                        // image: "/DroneLabel/drone-label.png",
                        // scale: 0.4,
                        // eyeOffset: new Cesium.Cartesian3(0, 0, -150),
                        // pixelOffset: new Cesium.Cartesian2(0.0, -70.0),
                        // distanceDisplayCondition: new Cesium.DistanceDisplayCondition(100, 3500.0),
                        // pixelOffsetScaleByDistance: new Cesium.NearFarScalar( 100, 1.0, 1e4, 0.1),
                        // scaleByDistance: new Cesium.NearFarScalar( 100, 1.0, 1e3, 0.5),
                    // }    
                // }
            // }  
        // })
    }
    // KillS_D() {
    //     const viewer = cesiumService.GetViewer();
    //     viewer.entities.removeById('S_M-1');
    // }

    GetPosition() {
        return this._drone_position;
    }

    private setPosition() {
        const state = this._state;
        let calc_alt = 0;
        if(state)
        {    
            this.SetRemainFlightTimeSec(); 
            // Single or Mission state는 FC Controller 버전 4.1.0 부터라서 없는 경우가 있음
            if(state._single_mission && state._waypoint_mission) {

                if(state._single_mission._state == MissionState.RUN || 
                    state._waypoint_mission._state == MissionState.RUN) {
                    this._mission_state = MissionState.RUN;
                }
                else if(state._waypoint_mission._state == MissionState.SUSPEND) {
                    this._mission_state = MissionState.SUSPEND;
                }
                else {
                    this._mission_state = MissionState.NONE;
                }
            }
            if(state._waypoint_mission) {
                if(state._waypoint_mission._uuid.length == 24) {
                    this._waypoint_mission_state = state._waypoint_mission._state;
                    this._mission_index = state._waypoint_mission._index;
                }
                if(state._waypoint_mission._uuid.length == 24 && state._waypoint_mission._uuid != this._mission_uuid) {
                    const mission_object = MissionManager.getInstance().GetMissionFromUUID(state._waypoint_mission._uuid);
                    if(mission_object) {
                        console.log('SetMission')
                        this.SetMission(mission_object);
                        this._mission_uuid = state._waypoint_mission._uuid;
                    }
                }
                else if(state._waypoint_mission._uuid == '' || state._waypoint_mission._uuid == undefined) {
                    this._mission_uuid = undefined;
                    this.ClearMission(); 
                }
            }
            const Cesium = cesiumService.GetCesium();
            const viewer = cesiumService.GetViewer();

            const cartographic = Cesium.Cartographic.fromDegrees(state._gps_int._lon / 1e7, state._gps_int._lat / 1e7);
            const globeHeight = viewer.scene.globe.getHeight(cartographic);

            const alt_tracking:HG_AltTracking[]|undefined = state.alt_tracking;
            const alt_tracking_arm:HG_AltTracking = alt_tracking[0];

            if(alt_tracking_arm._detect)
            {
                // Cesium Map 이 Arm 지점의 정보를 획득하지 못할 경우를 대비하여 매번 check!!!
                this._armed = true;

                const arm_cartographic = Cesium.Cartographic.fromDegrees(alt_tracking_arm._coordinate_alt._lon / 1e7, alt_tracking_arm._coordinate_alt._lat / 1e7);
                const terrain_arming_position = viewer.scene.globe.getHeight(arm_cartographic);

                this._arm_globeHeight = terrain_arming_position;
            }
            else
            {
                this._armed = false;
            }

            const raw_alt_arming = (alt_tracking_arm._coordinate_alt._abs_alt ? alt_tracking_arm._coordinate_alt._abs_alt / 1000 : 0);
            const arming_gap = raw_alt_arming - this._arm_globeHeight;

            calc_alt = this._arm_globeHeight + (state._gps_int._relative_alt/1000); // for Normal GPS
            const equip_gps = this.GetNoti()._gps
            if(equip_gps){
                if(this.GetNoti()._equipment & E_EQUIPMENT.GPS && equip_gps._type & E_GPS.MBC_RTK){
                    calc_alt = (state._gps_raw_int._alt / 1000) - arming_gap// for MBC RTK
                } 
            }

            if(this.GetNoti()._simulate) {
                calc_alt = state._gps_int._relative_alt / 1e3;
            }

            // 지하로 들어가지 않도록 terrain 값보다 작을 경우 보정
            if(calc_alt < globeHeight || !this._armed)
            {
                calc_alt = globeHeight + .5;
            }

            state._gps_int._alt = calc_alt * 1000;

            const next_position = this.getPositionFromState(state);
            const next_orientation = this.getOrientationFromState(state, this._drone_position.pos.clone());

            if(this._tweener) {
                this._tweener.stop();
                this._tweener = undefined;
            }
            this._tweener = new Tween(this._drone_position)
            .to({pos: next_position, ori: next_orientation}, 500)
            .easing(Easing.Linear.None)
            .start();

        }
    }

    private getPositionFromState(state:HG_DroneState) {
        const position = cesiumService.GetCesium().Cartesian3.fromDegrees(state._gps_int._lon / 1e7, state._gps_int._lat / 1e7, state._gps_int._alt / 1000);
        return position;
    }

    // 3D 드론 PTZ 조정 로직
    private getOrientationFromState(state:HG_DroneState, currentPos:any) {
        const Cesium = cesiumService.GetCesium();
        // const heading = Cesium.Math.toRadians((state._attitude._yaw * 180 / Math.PI)-180);
        const heading = Cesium.Math.toRadians((state._attitude._yaw * 180 / Math.PI) - 90);
        // const heading = Cesium.Math.toRadians((state._attitude._yaw * 180 / Math.PI));
        const pitch = Cesium.Math.toRadians(state._attitude._pitch * 180 / Math.PI);
        const roll = Cesium.Math.toRadians(state._attitude._roll * 180 / Math.PI);
        const hpr = new Cesium.HeadingPitchRoll(heading, pitch, roll);
        const orientation = Cesium.Transforms.headingPitchRollQuaternion(currentPos, hpr);
        return orientation;
    }

    HydrogenFlightTime() {
        const stack_data = computed(() => store.getters.GetSelectedStackData)
        // console.log('_noti', StackManager.getInstance().GetStackFromDeviceSerial(this.GetNoti()._name))
        // console.log('_noti', StackManager.getInstance().GetStackFromDeviceSerial('ED-00000001'))
        let sum_power = 0
        let tank_level = 0
        let flight_time = 0
        let hour = 0
        let minute = 0 
        let second = 0
        StackManager.getInstance().GetStackFromDeviceSerial('D-6').forEach((stack:STACK) => {
            sum_power = sum_power + stack.Get().soar_output_power
            tank_level = stack.Get().soar_tank_level
        })
        // axios.get(`/gcs/eft/lh?wat=${sum_power}&remain=${tank_level}`).then((res) => {
        axios.get(`/gcs/eft/gh?wat=${sum_power}&tem=25&bar=${tank_level}&vol=9.3`).then((res) => {
            flight_time = res.data.flight_time
            this._flight_time_hy = res.data.flight_time
        })
        .catch((reason) => {
            console.error(reason);
        })
        
        // console.log(flight_time)   
        if(this._flight_time_hy) {
            hour = Math.floor(this._flight_time_hy / 3600);
            minute = Math.floor((this._flight_time_hy % 3600) / 60);
            second = Math.ceil(this._flight_time_hy % 60);
            return `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}:${second.toString().padStart(2, '0')}`
        }
        return 'Stack Run Required'
        // if(stack_data.value.size > 0) {
        //     if(stack_data.value.has(this._noti._index)) {
        //         this._output_power = stack_data.value.get(this._noti._index).get('OutputPower') // 스택에서만 쓰는 와트량
        //         this._gh_tank_level = stack_data.value.get(this._noti._index).get('TankLevel') // 더미? 데이터
        //         this._lh2_remain_level = stack_data.value.get(this._noti._index).get('LH2RemainLevel')
        //         this._lh2_total_use = stack_data.value.get(this._noti._index).get('LH2HydrogenTotalUse')
        //         this._stack_type = stack_data.value.get(this._noti._index).get('FuelType')
        //         this._fcpm_type = stack_data.value.get(this._noti._index).get('FcpmType')
        //     }
        //     else {
        //         console.log(this._noti._index + ' Drone Cannot Hyd Drone')
        //     }
            
            
        //     let calc = null
        //     if(this._stack_type == 'G') {
        //         const volume = 6.8
        //         //기체수소 비행시간 구하기
        //         axios.get(`/gcs/eft/gh?wat=2400&tem=10&bar=300&vol=9.2`).then((res) => {
        //             console.log(res.data)
        //         })
        //         .catch((reason) => {
        //           console.error(reason);
        //         })
        //         console.log(Number(this._gh_tank_level), volume, Number(this._output_power))
        //         calc = CalcGH2RemainTank(Number(this._gh_tank_level), volume, Number(this._output_power))
        //     }
        //     else {
        //         //액체 수소 구하기
        //          axios.get(`/gcs/eft/lh?wat=1989&remain=87.8`).then((res) => {
        //              console.log(res.data)
        //          })
        //          .catch((reason) => {
        //            console.error(reason);
        //          })
        //         console.log(Number(this._input_lh2_gram), Number(this._lh2_total_use), Number(this._output_power))
        //         calc = CalcLH2RemainTank(Number(this._input_lh2_gram), Number(this._lh2_total_use), Number(this._output_power))
        //     }
            
        //     if(calc) {
        //         if(Math.abs(calc) != Infinity) {
        //             const pad = (num:any) => String(num).padStart(2, '0');
        //             const hour = pad(Math.floor(calc / 3600));
        //             const minute = pad(Math.floor((calc % 3600) / 60));
        //             const second = pad(calc % 60);
        //             this._flight_time = `${hour}:${minute}:${second}`
        //         }    
        //     }
        //     return this._flight_time
        // }
        
    }
    SetDroneRoiLocation(lng:number|undefined, lat:number|undefined) {
        this._roi_lng = lng
        this._roi_lat = lat
    }
    GetDroneRoiLocation() {
        const RoiLocation = {
            lng: this._roi_lng,
            lat: this._roi_lat
        }
        return RoiLocation
    }
    SendRoiStatus() {
        
        if(this._roi_lat && this._roi_lng) {
            const coordinate: HG_Coordinate = {
                _lat: Math.floor(this._roi_lat * 1e7),
                _lon: Math.floor(this._roi_lng * 1e7),
                _alt: 0
            };
        
            const mission: HG_Mission = {
                _index: 0,
                _command: Command.COMMAND_ROI_SET,
                _sub_command: 0,
                _wait_time: 0,
                _coordinate: coordinate
            }
        
            const header: HG_Header = {
                _index: 1,
                _msg_type: E_MESSAGE_TYPE.SPECIAL_SETUP,
                _target_indicator: 1 << this._noti._index,
            }
        
            const contents: HG_Contents = {
                _drone_mission: mission
            }
        
            const packet: HG_Packet = {
                _header: header,
                _contents: contents
            }
            console.log('Roi send packet', packet)
            ws.getInstance().send(packet)
        }
    }

    SendRoiCancel() {
            const coordinate: HG_Coordinate = {
                _lat: 0,
                _lon: 0,
                _alt: 0
            };
        
            const mission: HG_Mission = {
                _index: 0,
                _command: Command.COMMAND_ROI_RESET,
                _sub_command: 0,
                _wait_time: 0,
                _coordinate: coordinate
            }
        
            const header: HG_Header = {
                _index: 1,
                _msg_type: E_MESSAGE_TYPE.SPECIAL_SETUP,
                _target_indicator: 1 << this._noti._index,
            }
        
            const contents: HG_Contents = {
                _drone_mission: mission
            }
        
            const packet: HG_Packet = {
                _header: header,
                _contents: contents
            }
            console.log('Roi cencel send packet', packet)
            ws.getInstance().send(packet)
    }

}
