import { HG_Contents, HG_Coordinate, HG_Header, HG_Mission, HG_Packet, HG_RcChannel } from "@/services/ws/ws_hg_server_packet_interface";
import { Command, E_MESSAGE_TYPE, SubCommand } from "@/services/ws/ws_hg_server_protocol";

import ws from "@/services/ws/ws_hg_server";
import store from "@/store";
import CESIUM_DRONE from "../cesium_drone";
import CustomAlert, { CustomAlertOptions } from "../custom_alert";
import UserSettingManager from "../user_setting/user_setting_manager";

export enum CONTROLLER_MODE {
    NONE,
    GCS,
    CONTROLLER
}

export default class SingleManager {
    private static instance:SingleManager;

    private _target:CESIUM_DRONE|undefined = undefined;


    private options:CustomAlertOptions = {
        color: 'success', // 원하는 색
    }

    public static getInstance () {
        return this.instance || (this.instance = new this())
    }

    SetSingleTarget(drone:CESIUM_DRONE) {
        if(this._target) {
            this._target.Deselect();
        }
        if(this._target?.GetNoti()._index == drone.GetNoti()._index) {
            this._target = undefined;    
            store.commit("SetControllerMode", CONTROLLER_MODE.NONE);
        }
        else {
            this._target = drone;
            this._target.Select();
        }
        
        store.commit('SetSingleDrone', this._target);
    }
    SetTargetDelete(){
        this._target = undefined
    }

    GetSingleTarget(): CESIUM_DRONE|undefined {
        return this._target;
    }

    Command(type:string, drone_index:number, alt = 0){

        if(drone_index != null)
        {
            let main_command:Command = 0
            let sub_command:SubCommand = 0
            let wait_time = 0;

            if(type == 'arm') 
            {
                main_command = Command.COMMAND_READY2FLIGHT
                sub_command = SubCommand.SUB_COMMAND_ARM
                wait_time = 2
            }
            else if(type == 'disarm') 
            {
                main_command = Command.COMMAND_READY2FLIGHT
                sub_command = SubCommand.SUB_COMMAND_DISARM
                wait_time = 0
            }
            else if(type == 'takeoff') 
            {
                main_command = Command.COMMAND_READY2FLIGHT
                sub_command = SubCommand.SUB_COMMAND_TAKEOFF
                wait_time = 3
            }
            else if(type == 'land') 
            {
                main_command = Command.COMMAND_MODE
                sub_command = SubCommand.SUB_COMMAND_LAND_MODE
                wait_time = 1
            }
            else if(type == 'hovering') 
            {
                main_command = Command.COMMAND_VIRTUAL_KEY
                wait_time = 2
            }
            else if(type == 'rtl') 
            {
                main_command = Command.COMMAND_MODE
                sub_command = SubCommand.SUB_COMMAND_RETURN2LAND_MODE
                wait_time = 1
            }
            else console.log('Invalid type : ', type)

            const coordinate : HG_Coordinate = {
                _lat: 0,
                _lon: 0,
                _alt: type == 'takeoff' ? Math.ceil(alt) : 0
            };

            const mission: HG_Mission = {
                //_index: 0,
                _command: main_command,
                _sub_command: sub_command,
                _wait_time: wait_time,
                _coordinate: coordinate
            }

            const header: HG_Header = {
                _index: 1,
                _msg_type: E_MESSAGE_TYPE.MISSION_REGISTER_AUTOSTART,
                _target_indicator: 1 << drone_index
            }

            const contents: HG_Contents = {
                _drone_mission: mission
            }

            const packet: HG_Packet = {
                _header: header,
                _contents: contents
            }
            
            ws.getInstance().send(packet)
            console.log(packet)
            CustomAlert.getInstance().ShowAlert(`Command(${type}) is sent`, this.options);
        }
        else console.log('Send Fail : drone index null')
    }


    ModeChange(mode:number /* GCS: 4, Controller: 5 */ , drone_index:number){
        if(mode == 4) {
            store.commit('SetControllerMode', CONTROLLER_MODE.GCS)
        } else if(mode == 5) {
            store.commit('SetControllerMode', CONTROLLER_MODE.CONTROLLER)
        } else {
            store.commit('SetControllerMode', CONTROLLER_MODE.NONE)
        }

        if(mode == SubCommand.SUB_COMMAND_GUIDE_MODE || mode == SubCommand.SUB_COMMAND_LOITER_MODE)
        {
            const coordinate:HG_Coordinate = {
                _alt: 0, _lat: 0, _lon: 0,
            }
        
            const mission:HG_Mission = {
                _index: 0,
                _command: Command.COMMAND_MODE,
                _sub_command: mode,
                _wait_time: 2,
                _coordinate: coordinate,
            }

            const header:HG_Header = {
                _index: 1,
                _msg_type: E_MESSAGE_TYPE.MISSION_REGISTER_AUTOSTART,
                _target_indicator: 1 << drone_index,
            }

            const contents:HG_Contents = {
                _drone_mission: mission,
            }

            const packet:HG_Packet = {
                _header: header,
                _contents: contents,
            }

            ws.getInstance().send(packet);
            console.log(packet)
            CustomAlert.getInstance().ShowAlert("Command(mode) is sent", this.options);
        }
        else
        {
            console.error(`${mode} is not supported mode`);
        }
    }

    MoveDrone(direction:string, drone_index:number){

        const front_or_back:number = direction == 'Front' ? 1 : direction == 'Back' ? -1 : 0
        const right_or_left:number = direction == 'Right' ? 1 : direction == 'Left' ? -1 : 0
        const up_or_down:number = direction == 'Down' ? 1 : direction == 'Up' ? -1 : 0

        const coordinate: HG_Coordinate = {
            _lat: front_or_back,
            _lon: right_or_left,
            _alt: up_or_down
        };

        const mission: HG_Mission = {
            _index: 0,
            _command: Command.COMMAND_VIRTUAL_KEY,
            _sub_command: 0,
            _wait_time: 2,
            _coordinate: coordinate
        }

        const header: HG_Header = {
            _index: 1,
            _msg_type: E_MESSAGE_TYPE.MISSION_REGISTER_AUTOSTART,
            _target_indicator: 1 << drone_index,
        }

        const contents: HG_Contents = {
            _drone_mission: mission
        }

        const packet: HG_Packet = {
            _header: header,
            _contents: contents
        }

        if(front_or_back == 0 && right_or_left == 0 && up_or_down == 0) console.log('Send Fail. Virtual direction error')
        else
        {
            ws.getInstance().send(packet)
            console.log(packet)
            CustomAlert.getInstance().ShowAlert(`Command(${direction.toLowerCase()}) is sent`, this.options);
        }
    }

    YawControl(direction: string, drone_index:number) {

        const yaw_speed = UserSettingManager.getInstance().Get().yaw_speed;
        const rc_channel_data : HG_RcChannel = {
            _ch1: 0,
            _ch2: 0,
            _ch3: 0,
            _ch4: direction == 'left' ? -yaw_speed : yaw_speed, // 100당 36도?
            _ch5: 0,
            _ch6: 0,
            _ch7: 0,
            _ch8: 0,
            _ch9: 0,
        };

        const mission: HG_Mission = {
            _command: Command.COMMAND_MANUAL_CONTROL,
            _rc_channel: rc_channel_data
        }

        const header: HG_Header = {
            _index: 1,
            _msg_type: E_MESSAGE_TYPE.MISSION_MANUAL_CONTROL,
            _target_indicator: 1 << drone_index,
        }

        const contents: HG_Contents = {
            _drone_mission: mission
        }

        const packet: HG_Packet = {
            _header: header,
            _contents: contents
        }

        ws.getInstance().send(packet)
        console.log('Yaw Control', packet)
        CustomAlert.getInstance().ShowAlert("Command(yaw rotation) is sent", this.options);
    }

    YawRelease(drone_index:number) {
        const rc_channel_data : HG_RcChannel = {
            _ch1: 0,
            _ch2: 0,
            _ch3: 0,
            _ch4: 0,
            _ch5: 0,
            _ch6: 0,
            _ch7: 0,
            _ch8: 0,
            _ch9: 0,
        };

        const mission: HG_Mission = {
            _rc_channel: rc_channel_data
        }

        const header: HG_Header = {
            _index: 1,
            _msg_type: E_MESSAGE_TYPE.MISSION_MANUAL_CONTROL,
            _target_indicator: 1 << drone_index,
        }

        const contents: HG_Contents = {
            _drone_mission: mission
        }

        const packet: HG_Packet = {
            _header: header,
            _contents: contents
        }

        ws.getInstance().send(packet)
        console.log('Yaw Release', packet)
        CustomAlert.getInstance().ShowAlert("Command(yaw release) is sent", this.options);
    }

    FlyToTarget(drone_index:number, receive_lat:number, receive_lon:number, receive_alt:number) {
        const coordinate : HG_Coordinate = { // GCSV3은 모두 정수로 보냄
            _lat: Math.floor(receive_lat),
            _lon: Math.floor(receive_lon),
            _alt: Math.ceil(receive_alt),
        };

        const mission: HG_Mission = {
            _index: 0, // 0 : Yaw 회전하면서 이동, 1 : Yaw 회전 후 이동
            _command: Command.COMMAND_COORDINATE,
            _sub_command: SubCommand.SUB_COMMAND_ALT_OPTION_RELATIVE,
            _wait_time: 2,
            _coordinate: coordinate
        }

        const header: HG_Header = {
            _index: 1,
            _msg_type: E_MESSAGE_TYPE.MISSION_REGISTER_AUTOSTART,
            _target_indicator: 1 << drone_index,
        }

        const contents: HG_Contents = {
            _drone_mission: mission
        }

        const packet: HG_Packet = {
            _header: header,
            _contents: contents
        }

        console.log("Single packet", packet)
        ws.getInstance().send(packet)

        CustomAlert.getInstance().ShowAlert("Command(flight to target) is sent", this.options);
    }
}