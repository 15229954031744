
import MissionWaypointGeneratingManager from "@/services/hg/mission/mission_generating_tool_manager";
import MissionManager from "@/services/hg/mission/mission_manager";
import store from "@/store";
import { computed, defineComponent } from "vue";
export default defineComponent({
  data() {
    return {
      slide_step : 1,
      altitude : {
          max: 100,
          min: -50,
          slider: MissionWaypointGeneratingManager.getInstance().GetGeneratingSet().append_altitude,
          state: MissionWaypointGeneratingManager.getInstance().GetGeneratingSet().increase_type,
        },
      rectangle_set: {
        multiple_size: {
          max: 100,
          min: 0,
          slider: MissionWaypointGeneratingManager.getInstance().GetGeneratingSet().rectangle_multi_count,
        },
        multiple_interval: {
          max: 100,
          min: 0,
          slider: MissionWaypointGeneratingManager.getInstance().GetGeneratingSet().rectangle_multi_interval,
        },
        fillinside: {
          max: 100,
          min: 0,
          slider: MissionWaypointGeneratingManager.getInstance().GetGeneratingSet().fill_inside_term,
          state: MissionWaypointGeneratingManager.getInstance().GetGeneratingSet().fill_inside,
        },
      },
      polygon_set: {
        multiple_size: {
          max: 100,
          min: 1,
          slider: MissionWaypointGeneratingManager.getInstance().GetGeneratingSet().polygon_multi_count,
        },
        multiple_interval: {
          max: 100,
          min: 0,
          slider: MissionWaypointGeneratingManager.getInstance().GetGeneratingSet().polygon_multi_interval,
        },
        segments: {
          max: 100,
          min: 3,
          slider: MissionWaypointGeneratingManager.getInstance().GetGeneratingSet().segments,
        },
      },
      sheet: false,
      show: true,
      function_type: MissionWaypointGeneratingManager.getInstance().GetGeneratingSet().type,
      waypointList: [
        {
          Name: "line",
          icon: "line",
          action:1,
        },
        {
          Name: "rectangle",
          icon: "rectangle",
          action:2,
        },
        {
          Name: "polygon",
          icon: "polygon",
          action:3,
        },
      ],
    };
  },
  setup() {
    const show_waypoint_box = computed(() => MissionManager.getInstance().GetWaypointBoxState());
    const waypoint_box_type_none = computed(() => store.getters.GetWaypointBoxTypeNone);
    return { waypoint_box_type_none, show_waypoint_box };
  },
  watch: {
    show_waypoint_box: {
      // deep:true,
      handler(n, o) {
        console.log('showwaypointBox', n)
      },
    },
    // group() {
    //   this.show_waypoint_box = false;
    // },

  },
  methods: {
    onClickWayPoint() {
      store.commit("SetWayPointBox", !this.show_waypoint_box);
    },
    onClickConvertType(function_type: number) {
      store.commit("SetWaypointBoxTypeNone", false);
      MissionWaypointGeneratingManager.getInstance().SetType(function_type)
      this.function_type = function_type;
    },
    onClickSegments(segments: number) {
      MissionWaypointGeneratingManager.getInstance().SetSegs(segments)
    },
    onClickWaypointAltitude(altitude: number) {
      MissionWaypointGeneratingManager.getInstance().SetAppendAlt(altitude)
    },
    onClickStep(step: number, type: string) {
      const manager= MissionWaypointGeneratingManager.getInstance().SetProcessStep(step, type);
      const getType = MissionWaypointGeneratingManager.getInstance().GetGeneratingSet();
      if(type == 'append_altitude'){manager; this.altitude.slider = getType.append_altitude }
      else if(type === 'rectangle_multi_count') {manager; this.rectangle_set.multiple_size.slider = getType.rectangle_multi_count}
      else if(type === 'rectangle_multi_interval') {manager; this.rectangle_set.multiple_interval.slider = getType.rectangle_multi_interval}
      else if(type === 'polygon_multi_count') {manager; this.polygon_set.multiple_size.slider = getType.polygon_multi_count}
      else if(type === 'polygon_multi_interval') {manager; this.polygon_set.multiple_interval.slider = getType.polygon_multi_interval}
      else if(type === 'fill_inside_term') {manager; this.rectangle_set.fillinside.slider = getType.fill_inside_term}
      else if(type === 'segments') {manager; this.polygon_set.segments.slider = getType.segments}
    },
    onClickRectangleMultipleCount(multiple_count: number) {
      MissionWaypointGeneratingManager.getInstance().SetRectangleMultiCount(multiple_count)
    },
    onClickSquareMultipleInterval(multiple_interval: number) {
      MissionWaypointGeneratingManager.getInstance().SetRectangleMultiInterval(multiple_interval)
    },
    onClickPolygonMultipleCount(multiple_count: number) {
      MissionWaypointGeneratingManager.getInstance().SetPolygonMultiCount(multiple_count)
    },
    onClickPolygonMultipleInterval(multiple_interval: number) {
      MissionWaypointGeneratingManager.getInstance().SetPolygonMultiInterval(multiple_interval)
    },
    onClickFillInsideTerm(inside_term: number) {
      MissionWaypointGeneratingManager.getInstance().SetFillTerm(inside_term)
    },
    onClickFillInside(state: boolean) {
      MissionWaypointGeneratingManager.getInstance().SetFillInside(state)
    },
    onClickSetIncreaseType(state: boolean) {
      MissionWaypointGeneratingManager.getInstance().SetIncraseType(state)
    },
  },
});
