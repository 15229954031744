/* eslint-disable @typescript-eslint/no-explicit-any */
import { E_AI_TYPE } from '@/services/ws/ws_hg_server_protocol';
import $ from 'jquery';

export interface ChannelInfo {
    index: number,
    channel_id: number,
    rtspurl: string,
    psn: string,
    des: string,
    ai_account?: string,
    ai_device?: string,
    ai_type?: E_AI_TYPE,
    ai_url?: string,
}

const config = {
    iceServers: [{
        urls: ["stun:stun.l.google.com:19302"]
    }]
};

export class WEBRTC {
    private livestream: any | null = null;

    private stream:MediaStream|null = null;
    private pc:RTCPeerConnection|null = null;
    private track:any = null;

    private _url = '';
    private _is_request = false;

    constructor() {
        this.stream = new MediaStream();
    }
    SetVideoElement(element: HTMLElement) {
        this.livestream = element;
    }
    PlayFromUrl(url: string) {
        this._url = url;
        if (!this.livestream) {
            console.error("Please, input the video tag element.\r\nUse class.SetVideoElement(element:HTMLElement)")
            return;
        }

        this.stop();
        this.setPeerConnectionFromUrl();

        const videoElem = this.livestream;
        const stream = this.stream;
        if(this.pc)
        {
            this.pc.ontrack = (event) => {
                this.track = event.track;
                if(stream) stream.addTrack(event.track);
                videoElem.srcObject = stream;
            }

            if(this.pc)
            {
                this.pc.addTransceiver("video", {
                    'direction': 'recvonly'
                })
            }
        }
    }
    UserStop() {
        this.stop();
    }
    private stop() {
        if(this.pc) this.pc.close();
        if(this.track) if(this.stream) this.stream.removeTrack(this.track);
        if(this.livestream) this.livestream.srcObject = null;

        this.pc = null;
        this.track = null;
        this.stream = null;
        this.stream = new MediaStream();
    }
    private setPeerConnectionFromUrl() {
        if(!this.pc)
        {
            this.pc = new RTCPeerConnection(config);
            this.pc.onnegotiationneeded = async () => {
                if(this.pc)
                {
                    const offer = await this.pc.createOffer();
                    await this.pc.setLocalDescription(offer);
                    if (this.pc?.localDescription && this._url)
                    {
                        if(!this._is_request) {
                            this._is_request = true;
                            // $.post("http://125.136.97.200:9556/stream", {
                            $.post("https://gcs.hogreenair.com/stream", {
                                url: this._url,
                                sdp64: btoa(this.pc?.localDescription?.sdp),
                            }).done(
                                (data: any) => {
                                    this._is_request = false;
                                    try {
                                        if (this.pc) {
                                            this.pc.setRemoteDescription(new RTCSessionDescription({
                                                type: 'answer',
                                                sdp: atob(data.sdp64)
                                            }))
                                        }
                                    } catch (e) {
                                        console.warn(e);
                                    }
                                }
                            ).fail(
                                () => {
                                    this._is_request = false;
                                    console.log('re-play request', this._is_request);
                                    if(!this._is_request) {
                                        this.PlayFromUrl(this._url);
                                    }
                                }
                            );
                        }
                        else {
                            console.log('Already request...')
                        }
                    }
                }
            }

            this.pc.oniceconnectionstatechange = () => {if(this.pc)console.log(this.pc.iceConnectionState)}
        }
    }
}