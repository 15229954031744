import { cesiumService } from "@/services/cesium/cesium_service_inst";
import * as turf from "@turf/turf";
import MissionManager, { MissionItem, MissionItemType } from "./mission_manager";
export enum WaypointGenerateType {
  NONE,
  LINE,
  RECTANGLE,
  POLYGON,
}

export interface MissionGeneratingSet {
  type: WaypointGenerateType;
  append_altitude: number;
  rectangle_multi_count: number;
  polygon_multi_count: number;
  rectangle_multi_interval: number;
  polygon_multi_interval: number;
  fill_inside: boolean;
  fill_inside_term: number;
  segments: number;
  increase_type: boolean;
}

export default class MissionWaypointGeneratingManager {
  private static instance: MissionWaypointGeneratingManager;

  public static getInstance() {
    return this.instance || (this.instance = new this());
  }
  private _terrain_provider: any;
  private _mission_tool_type = MissionItemType.NONE;

  private _ignore_mouse_move = true;

  private mission_item_temp: any;
  private modify_altitude = false;
  private modify_lnglat = false;
  private click_position_cartesian: any;
  private click_position_y = 0;
  private click_position_height = 0;
  
  private _paths: any;
  
  private _opts: MissionGeneratingSet = {
    type: WaypointGenerateType.LINE,
    append_altitude: 5,
    rectangle_multi_count: 1,
    polygon_multi_count: 1,
    rectangle_multi_interval: 0,
    polygon_multi_interval: 0,
    fill_inside: false,
    fill_inside_term: 10,
    segments: 36,
    increase_type: false,
  };

  private constructor() {
    const viewer = cesiumService.GetViewer();
    const Cesium = cesiumService.GetCesium();
    this._terrain_provider = Cesium.createWorldTerrain();
  }

  async GetPath(mouse_position: any, mission_type:MissionItemType ): Promise<MissionItem[]> {
    const Cesium = cesiumService.GetCesium();
    const viewer = cesiumService.GetViewer();
    const paths: MissionItem[] = [];
    const current_missions:MissionItem[] = MissionManager.getInstance().GetModifyMission();
    const pathArrLength_minus = current_missions.length -1;
    // console.log('current_missions 길이', current_missions.length)
    if (current_missions.length > 0 && current_missions[current_missions.length-1].type == MissionItemType.WAYPOINT) {
      const last: MissionItem = current_missions[current_missions.length - 1];
      const next = mouse_position.clone();
      const cartographic = Cesium.Cartographic.fromCartesian(next);
      const previous_MissionItem = current_missions[pathArrLength_minus]
      const next_globe_height = viewer.scene.globe.getHeight(cartographic);
      const next_asl = next_globe_height + 26.6871;

      if (this._opts.type == WaypointGenerateType.NONE) {
        console.log("WaypointGenerateType.NONE");
      } else if (this._opts.type == WaypointGenerateType.LINE) {
        paths.push(last);

        const longitude = Cesium.Math.toDegrees(cartographic.longitude);
        const latitude = Cesium.Math.toDegrees(cartographic.latitude);
        const append_altitude = this._opts.append_altitude
        const increase_type = this._opts.increase_type
        const post_height = current_missions[current_missions.length - 1];
        const mission_item: MissionItem = {
          id: current_missions.length + "",
          type: mission_type,
          lng: longitude,
          lat: latitude,
          //updatedPositions[0].height => 실제지형 terrain 높이 적용
          asl: next_asl + (append_altitude + (increase_type ? post_height.agl : 0)),
          //scene_alt => 세슘 맵 상에서 표시할 고도높이
          display: next_globe_height + (append_altitude + (increase_type ? post_height.agl : 0)),
          //Groun위 높이
          agl: append_altitude + (increase_type ? post_height.agl : 0),
          timeout: 0,
        };
        
        paths.push(mission_item);
      } else if (this._opts.type == WaypointGenerateType.RECTANGLE) {

        const lat = Cesium.Math.toDegrees(cartographic.latitude);
        const lng = Cesium.Math.toDegrees(cartographic.longitude);

        const mouse_point = [lng, lat];
        const pathArrLength = current_missions.length;
        const current_heading = (viewer.camera.heading * 180) / Math.PI;
        const post_point = [current_missions[pathArrLength - 1].lng, current_missions[pathArrLength - 1].lat,];
        const current_two_point_heading = turf.bearing(post_point, mouse_point);
        const heading =turf.bearingToAngle(current_two_point_heading) - current_heading;
        const distance = turf.distance(post_point, mouse_point, {units: "meters",});
        const point_1 = turf.destination(post_point, 0, 0, { units: "meters" }).geometry.coordinates;
        const point_2 = turf.destination(post_point,distance * Math.cos((Math.PI * (heading % 90)) / 180),heading - (heading % 90) + current_heading,{ units: "meters" }).geometry.coordinates;
        const point_3 = turf.destination(post_point,distance,heading + current_heading,{ units: "meters" }).geometry.coordinates;
        const point_4 = turf.destination(post_point,distance * Math.sin((Math.PI * (heading % 90)) / 180),heading - (heading % 90) + current_heading + 90,{ units: "meters" }).geometry.coordinates;
        const line_1 = turf.lineString([point_2, point_3]);
        const line_2 = turf.lineString([point_1, point_4]);
        const line_chunk_1 = turf.lineChunk(line_1,this._opts.fill_inside_term,{ units: "meters" });
        const line_chunk_2 = turf.lineChunk(line_2,this._opts.fill_inside_term,{ units: "meters" });
        const bigger_length = line_chunk_1.features.length > line_chunk_2.features.length ? line_chunk_1.features.length : line_chunk_2.features.length;
        const fill_inside = this._opts.fill_inside;
        const rectangle_multi_count = this._opts.rectangle_multi_count;
        const rectangle_multi_interval = this._opts.rectangle_multi_interval;
        const append_altitude = this._opts.append_altitude;
        const increase_type = this._opts.increase_type

        const path_chunk_coords:MissionItem[] = [];
        const Set_path:MissionItem[] = [];
        if (fill_inside == true) {
          Set_path.push({
            id: pathArrLength_minus + "",
            type: mission_type,
            lng: point_1[0],
            lat: point_1[1],
            asl: (increase_type ? previous_MissionItem.agl : 0) + append_altitude * (increase_type ? Set_path.length : 1) +next_asl +rectangle_multi_interval,
            display: (increase_type ? previous_MissionItem.agl : 0) + append_altitude * (increase_type ? Set_path.length : 1) +next_globe_height +rectangle_multi_interval,
            timeout: 0,
            agl: (increase_type ? previous_MissionItem.agl : 0) + append_altitude * (increase_type ? Set_path.length : 1)+rectangle_multi_interval
          });
          Set_path.push({
            id: pathArrLength_minus + "",
            type: mission_type,
            lng: point_2[0],
            lat: point_2[1],
            asl: (increase_type ? previous_MissionItem.agl : 0) + append_altitude * (increase_type ? Set_path.length : 1) +next_asl +rectangle_multi_interval,
            display: (increase_type ? previous_MissionItem.agl : 0) + append_altitude * (increase_type ? Set_path.length : 1) +next_globe_height +rectangle_multi_interval,
            timeout: 0,
            agl: (increase_type ? previous_MissionItem.agl : 0) + append_altitude * (increase_type ? Set_path.length : 1)+rectangle_multi_interval
          });
            for (let i = 0; i < bigger_length; ++i) {
              if (i % 2 === 0) {
                path_chunk_coords.push({
                  id: pathArrLength_minus + "",
                  type: mission_type,
                  lng: line_chunk_1.features[i].geometry.coordinates[1][0],
                  lat: line_chunk_1.features[i].geometry.coordinates[1][1],
                  asl: (increase_type ? previous_MissionItem.agl : 0) + append_altitude*(increase_type ? Set_path.length : 1) + next_asl+ rectangle_multi_interval,
                  display: (increase_type ? previous_MissionItem.agl : 0) + append_altitude*(increase_type ? (path_chunk_coords.length + Set_path.length) : 1) + next_globe_height+ rectangle_multi_interval,
                  timeout: 0,
                  agl:(increase_type ? previous_MissionItem.agl : 0) + append_altitude * (increase_type ? (path_chunk_coords.length + Set_path.length) : 1)+rectangle_multi_interval
                });
                path_chunk_coords.push({
                  id: pathArrLength_minus + "",
                  type: mission_type,
                  lng: line_chunk_2.features[i].geometry.coordinates[1][0],
                  lat: line_chunk_2.features[i].geometry.coordinates[1][1],
                  asl: (increase_type ? previous_MissionItem.agl : 0) + append_altitude*(increase_type ? Set_path.length : 1) + next_asl + rectangle_multi_interval,
                  display: (increase_type ? previous_MissionItem.agl : 0) + append_altitude*(increase_type ? (path_chunk_coords.length + Set_path.length) : 1) + next_globe_height + rectangle_multi_interval,
                  timeout: 0,
                  agl: (increase_type ? previous_MissionItem.agl : 0) + append_altitude * (increase_type ? (path_chunk_coords.length + Set_path.length) : 1)+rectangle_multi_interval
                });
              } else {
                path_chunk_coords.push({
                  id: pathArrLength_minus + "",
                  type: mission_type,
                  lng: line_chunk_2.features[i].geometry.coordinates[1][0],
                  lat: line_chunk_2.features[i].geometry.coordinates[1][1],
                  asl: (increase_type ? previous_MissionItem.agl : 0) + append_altitude*(increase_type ? Set_path.length : 1) + next_asl + rectangle_multi_interval,
                  display: (increase_type ? previous_MissionItem.agl : 0) + append_altitude*(increase_type ? (path_chunk_coords.length + Set_path.length) : 1) + next_globe_height + rectangle_multi_interval,
                  timeout: 0,
                  agl: (increase_type ? previous_MissionItem.agl : 0) + append_altitude * (increase_type ? (path_chunk_coords.length + Set_path.length) : 1)+rectangle_multi_interval
                });
                path_chunk_coords.push({
                  id: pathArrLength_minus + "",
                  type: mission_type,
                  lng: line_chunk_1.features[i].geometry.coordinates[1][0],
                  lat: line_chunk_1.features[i].geometry.coordinates[1][1],
                  asl: (increase_type ? previous_MissionItem.agl : 0) + append_altitude*(increase_type ? Set_path.length : 1) + next_asl + rectangle_multi_interval,
                  display: (increase_type ? previous_MissionItem.agl : 0) + append_altitude*(increase_type ? (path_chunk_coords.length + Set_path.length) : 1) + next_globe_height + rectangle_multi_interval,
                  timeout: 0,
                  agl: (increase_type ? previous_MissionItem.agl : 0) + append_altitude * (increase_type ? (path_chunk_coords.length + Set_path.length) : 1)+rectangle_multi_interval
                });
              }
            }
            Set_path.push(...path_chunk_coords);
            Set_path.push({
              id: pathArrLength_minus + "",
              type: mission_type,
              lng: point_1[0],
              lat: point_1[1],
              asl: (increase_type ? previous_MissionItem.agl : 0) + append_altitude*(increase_type ? Set_path.length : 1) +next_asl + rectangle_multi_interval,
              display: (increase_type ? previous_MissionItem.agl : 0) + append_altitude*(increase_type ? Set_path.length : 1) + next_globe_height + rectangle_multi_interval,
              timeout: 0,
              agl: (increase_type ? previous_MissionItem.agl : 0) + append_altitude *(increase_type ? Set_path.length : 1)+rectangle_multi_interval
            });
            const last_asl = Set_path[Set_path.length - 1].asl
            const last_display = Set_path[Set_path.length - 1].display
            const last_agl = Set_path[Set_path.length - 1].agl

            for (let n = 0; n < rectangle_multi_count; ++n) {
              Set_path.forEach((mission:MissionItem, index:number)=>{
                paths.push({
                    id: pathArrLength_minus + index + Set_path.length*n + "",
                    type: mission.type,
                    lng: mission.lng,
                    lat: mission.lat,
                    asl: mission.asl + (n-1) * rectangle_multi_interval,
                    display: mission.display + (n-1) * rectangle_multi_interval,
                    timeout: mission.timeout,
                    agl: mission.agl + (n-1) * rectangle_multi_interval
                  })
              })
            }
        }
        // Fill Inside == false
        else {
          for (let i = 0; i < rectangle_multi_count; ++i) {
            const rectangle_multi_interval_count = i * rectangle_multi_interval;
            paths.push({
              id: pathArrLength_minus + 0 + i * 5 + "",
              type: mission_type,
              lng: point_1[0],
              lat: point_1[1],
              asl: (increase_type ? previous_MissionItem.asl : 0) + append_altitude*(increase_type ? paths.length : 1)+next_asl+rectangle_multi_interval_count,
              display: (increase_type ? previous_MissionItem.agl : 0) + append_altitude*(increase_type ? paths.length : 1) + next_globe_height + rectangle_multi_interval_count,
              timeout: 0,
              agl: (increase_type ? previous_MissionItem.agl : 0) + append_altitude*(increase_type ? paths.length : 1)+rectangle_multi_interval_count,
            });
            paths.push({
              id: pathArrLength_minus + 1 + i * 5 + "",
              type: mission_type,
              lng: point_2[0],
              lat: point_2[1],
              asl: (increase_type ? previous_MissionItem.asl : 0) + append_altitude*(increase_type ? paths.length : 1) + next_asl+rectangle_multi_interval_count,
              display: (increase_type ? previous_MissionItem.agl : 0) + append_altitude*(increase_type ? paths.length : 1) + next_globe_height + rectangle_multi_interval_count,
              timeout: 0,
              agl: (increase_type ? previous_MissionItem.agl : 0) + append_altitude*(increase_type ? paths.length : 1)+rectangle_multi_interval_count,
            });
            paths.push({
              id: pathArrLength_minus + 2 + i * 5 + "",
              type: mission_type,
              lng: point_3[0],
              lat: point_3[1],
              asl: (increase_type ? previous_MissionItem.asl : 0) + append_altitude*(increase_type ? paths.length : 1) + next_asl+rectangle_multi_interval_count,
              display: (increase_type ? previous_MissionItem.agl : 0) + append_altitude*(increase_type ? paths.length : 1) + next_globe_height + rectangle_multi_interval_count,
              timeout: 0,
              agl: (increase_type ? previous_MissionItem.agl : 0) + append_altitude*(increase_type ? paths.length : 1)+rectangle_multi_interval_count,
            });
            paths.push({
              id: pathArrLength_minus + 3 + i * 5 + "",
              type: mission_type,
              lng: point_4[0],
              lat: point_4[1],
              asl: (increase_type ? previous_MissionItem.asl : 0) + append_altitude*(increase_type ? paths.length : 1) + next_asl+rectangle_multi_interval_count,
              display: (increase_type ? previous_MissionItem.agl : 0) + append_altitude*(increase_type ? paths.length : 1) + next_globe_height + rectangle_multi_interval_count,
              timeout: 0,
              agl: (increase_type ? previous_MissionItem.agl : 0) + append_altitude*(increase_type ? paths.length : 1)+rectangle_multi_interval_count,
            });
            paths.push({
              id: pathArrLength_minus + 4 + i * 5 + "",
              type: mission_type,
              lng: post_point[0],
              lat: post_point[1],
              asl: (increase_type ? previous_MissionItem.asl : 0) + append_altitude*(increase_type ? paths.length : 1) + next_asl+rectangle_multi_interval_count,
              display: (increase_type ? previous_MissionItem.agl : 0) + append_altitude*(increase_type ? paths.length : 1) + next_globe_height + rectangle_multi_interval_count,
              timeout: 0,
              agl: (increase_type ? previous_MissionItem.agl : 0) + append_altitude*(increase_type ? paths.length : 1)+rectangle_multi_interval_count,
            });
          }
        }

        // console.log('path info', paths)
      } else if (this._opts.type == WaypointGenerateType.POLYGON) {
        const lat = Cesium.Math.toDegrees(cartographic.latitude);
        const lng = Cesium.Math.toDegrees(cartographic.longitude);
        const mouse_point = [lng, lat];
        const pathArrLength = current_missions.length;
        const heading = (viewer.camera.heading * 180) / Math.PI;
        const current_heading = turf.bearingToAngle(heading);
        const post_point = [current_missions[pathArrLength - 1].lng, current_missions[pathArrLength - 1].lat,];
        const mid_point = turf.midpoint(post_point, mouse_point);
        const two_point_bearing = turf.bearing(post_point, mouse_point);
        const two_point_bearingToAngle = turf.bearingToAngle(two_point_bearing);
        const current_two_point_bearing = two_point_bearingToAngle - current_heading
        const distance = turf.distance(post_point, mouse_point, {units: "meters",});
        const polygon_multi_interval = this._opts.polygon_multi_interval
        const append_altitude = this._opts.append_altitude
        const polygon_multi_count = this._opts.polygon_multi_count
        const segments = this._opts.segments
        
        const increase_type = this._opts.increase_type
        console.log('pre', previous_MissionItem.agl, previous_MissionItem.asl, previous_MissionItem.display)

        for (let n = 0; n < polygon_multi_count; ++n) {
          const polygon_multi_interval_count = n * polygon_multi_interval;
          const start_destination = turf.destination( post_point, 0, 0,{ units: "meters" }).geometry.coordinates;
          const last_destination = turf.destination( post_point, 0, 0,{ units: "meters" }).geometry.coordinates;
            paths.push({
              id: pathArrLength_minus + segments + n + (n-1) * segments + "",
              type: mission_type,
              lng: last_destination[0],
              lat: last_destination[1],
              asl: (increase_type ? previous_MissionItem.agl : 0) + next_asl + append_altitude*(increase_type ? paths.length : 1) + polygon_multi_interval_count,
              display: (increase_type ? previous_MissionItem.agl : 0) + next_globe_height + append_altitude*(increase_type ? paths.length : 1) + polygon_multi_interval_count,
              timeout: 0,
              agl: (increase_type ? previous_MissionItem.agl : 0) + append_altitude*(increase_type ? paths.length : 1)+polygon_multi_interval_count,
            });
            for (let i = 1; i < segments; ++i) {
              const destination = turf.destination( mid_point, distance / 2, current_two_point_bearing + current_heading - 180 + (i * 360) / segments,{ units: "meters" }).geometry.coordinates;
              paths.push({
                id: pathArrLength_minus + i + n + n * segments + "",
                type: mission_type,
                lng: destination[0],
                lat: destination[1],
                asl: (increase_type ? previous_MissionItem.agl : 0) + next_asl + append_altitude*(increase_type ? paths.length : 1) + polygon_multi_interval_count,
                display: (increase_type ? previous_MissionItem.agl : 0) + next_globe_height + append_altitude*(increase_type ? paths.length : 1) + polygon_multi_interval_count,
                timeout: 0,
                agl: (increase_type ? previous_MissionItem.agl : 0) + append_altitude*(increase_type ? paths.length : 1)+polygon_multi_interval_count,
              });
            }
            paths.push({
              id: pathArrLength_minus + (n+1) * segments + n + "",
              type: mission_type,
              lng: start_destination[0],
              lat: start_destination[1],
              asl: (increase_type ? previous_MissionItem.agl : 0) + next_asl + append_altitude*(increase_type ? paths.length : 1) + polygon_multi_interval_count,
              display: (increase_type ? previous_MissionItem.agl : 0) + next_globe_height + append_altitude*(increase_type ? paths.length : 1) + polygon_multi_interval_count,
              timeout: 0,
              agl:(increase_type ? previous_MissionItem.agl : 0) + append_altitude*(increase_type ? paths.length : 1)+polygon_multi_interval_count,
            });
          }
      }
    } else {
      if (this._mission_tool_type != MissionItemType.NONE) {
          const next = mouse_position.clone();
          const cartographic = Cesium.Cartographic.fromCartesian(next);
          const scene_alt = viewer.scene.globe.getHeight(cartographic);
          const longitude = Cesium.Math.toDegrees(cartographic.longitude);
          const latitude = Cesium.Math.toDegrees(cartographic.latitude);
          const append_altitude = this._opts.append_altitude;
          const mission_item: MissionItem = {
            id: current_missions.length + "",
            type: mission_type,
            lng: longitude,
            lat: latitude,
            asl: append_altitude + scene_alt + 26.6871,
            display: append_altitude + scene_alt,
            timeout: 0,
            agl:append_altitude,
          };
          
          paths.push(mission_item);
      }
    }
    return paths;
  }

  SetType(_: WaypointGenerateType) {
    this._opts.type = _;
  }
  SetRectangleMultiCount(_: number) {
    this._opts.rectangle_multi_count = _;
  }
  SetPolygonMultiCount(_: number) {
    this._opts.polygon_multi_count = _;
  }
  SetRectangleMultiInterval(_: number) {
    this._opts.rectangle_multi_interval = _;
  }
  SetPolygonMultiInterval(_: number) {
    this._opts.polygon_multi_interval = _;
  }
  SetAppendAlt(_: number) {
    this._opts.append_altitude = _;
  }
  SetProcessStep(_: number, type: string) {
    if(type === 'append_altitude'){if(this._opts.append_altitude == -50 && _ ==-1){console.log('No Action')}else{this._opts.append_altitude = this._opts.append_altitude + _}} 
    else if(type === 'rectangle_multi_count'){if(this._opts.rectangle_multi_count == 0 && _ ==-1){console.log('No Action')}else{this._opts.rectangle_multi_count = this._opts.rectangle_multi_count + _}} 
    else if(type === 'rectangle_multi_interval'){if(this._opts.rectangle_multi_interval == 0 && _ ==-1){console.log('No Action')}else{this._opts.rectangle_multi_interval = this._opts.rectangle_multi_interval + _}} 
    else if(type === 'polygon_multi_count'){if(this._opts.polygon_multi_count == 0 && _ ==-1){console.log('No Action')}else{this._opts.polygon_multi_count = this._opts.polygon_multi_count + _}} 
    else if(type === 'polygon_multi_interval'){if(this._opts.polygon_multi_interval == 0 && _ ==-1){console.log('No Action')}else{this._opts.polygon_multi_interval = this._opts.polygon_multi_interval + _}} 
    else if(type === 'fill_inside_term'){if(this._opts.fill_inside_term == 0 && _ ==-1){console.log('No Action')}else{this._opts.fill_inside_term = this._opts.fill_inside_term + _}} 
    else if(type === 'segments'){if(this._opts.segments == 3 && _ ==-1){console.log('No Action')}else{this._opts.segments = this._opts.segments + _}}
  }
  SetFillInside(_: boolean) {
    this._opts.fill_inside = _;
  }
  SetFillTerm(_: number) {
    this._opts.fill_inside_term = _;
  }
  SetSegs(_: number) {
    this._opts.segments = _;
  }
  GetGeneratingSet() {
    return this._opts;
  }
  GetMissionToolType() {
    return this._mission_tool_type;
  }
  SetMissionToolType(_: number)  {
    this._mission_tool_type = _;
  }
  SetOptsDefault()  {
    this._opts.type = WaypointGenerateType.LINE;
    this._opts.append_altitude = 5;
    this._opts.rectangle_multi_count = 1;
    this._opts.polygon_multi_count = 1;
    this._opts.rectangle_multi_interval = 0;
    this._opts.polygon_multi_interval = 0;
    this._opts.fill_inside = false;
    this._opts.fill_inside_term = 100;
    this._opts.segments = 36;
    this._opts.increase_type = false;

    this._mission_tool_type = MissionItemType.NONE
  }
  SetIncraseType(_:boolean)  {
    this._opts.increase_type = _;
  }
}