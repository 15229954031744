import ws from "@/services/ws/ws_hg_server";

import {
  HG_Contents,
  HG_Coordinate,
  HG_DroneState,
  HG_Header,
  HG_Hydrogen_Log_Info2,
  HG_Hydrogen_Serial,
  HG_Mission,
  HG_Packet
} from "@/services/ws/ws_hg_server_packet_interface";
import {
  Command,
  E_APP_TYPE,
  E_MESSAGE_TYPE,
  SubCommand,
} from "@/services/ws/ws_hg_server_protocol";
import store from "@/store";
import { cesiumService } from "../cesium/cesium_service_inst";
import CESIUM_DRONE from "../hg/cesium_drone";
import CustomAlert from "../hg/custom_alert";
import { MissionItem, MissionItemType, MissionObject } from "../hg/mission/mission_manager";

const verbose = true;

export function login(id:string, pw:string) {
  // export function login(id = "hogreenair", pw = "1234567") {
    
    const header: HG_Header = {
      _msg_type: E_MESSAGE_TYPE.LOGIN,
      _index: E_APP_TYPE.GCS,
    };
    
    const contents: HG_Contents = {
      _id: id,
      _pw: pw,
    };
    
    const packet: HG_Packet = {
      _header: header,
      _contents: contents,
    };
    
    store.commit("SetLoginData", { id: id, pw: pw });
    store.commit("SetAccountID", id);
    
  ws.getInstance().send(packet);
}

export function loginHyd() {
  const login_data: HG_Packet = {
    _header: {
      _msg_type: E_MESSAGE_TYPE.LOGIN,
      _index: E_APP_TYPE.HYDROGEN,
    },
    _contents: {
      _id: store.getters.GetLoginData.id,
      _pw: store.getters.GetLoginData.pw,
    },
  };

  ws.getInstance().hyd_send(login_data);
}

export function ChangeMode(
  mode: number /* GCS: 4, Controller: 5 */,
  drone_index: number
) {
  if (
    mode == SubCommand.SUB_COMMAND_GUIDE_MODE ||
    mode == SubCommand.SUB_COMMAND_LOITER_MODE
  ) {
    const coordinate: HG_Coordinate = {
      _alt: 0,
      _lat: 0,
      _lon: 0,
    };

    const mission: HG_Mission = {
      _index: 0,
      _command: Command.COMMAND_MODE,
      _sub_command: mode,
      _wait_time: 2,
      _coordinate: coordinate,
    };

    const header: HG_Header = {
      _index: 1,
      _msg_type: E_MESSAGE_TYPE.MISSION_REGISTER_AUTOSTART,
      _target_indicator: 1 << drone_index,
    };

    const contents: HG_Contents = {
      _drone_mission: mission,
    };

    const packet: HG_Packet = {
      _header: header,
      _contents: contents,
    };

    if (verbose) console.log(packet);
    ws.getInstance().send(packet);
  } else {
    console.error(`${mode} is not supported mode`);
  }
}
export function LogDataList(drone_index: number) {
  const header: HG_Header = {
    _msg_type: E_MESSAGE_TYPE.FC_LOG,
    _target_indicator: 1 << drone_index,
  };
  const contents: HG_Contents = {
    _drone_mission: {
      _index: 0,
      _command: Command.COMMAND_FC_LOG_LIST,
      _sub_command: 0,
      _wait_time: 0,
    },
  };
  const packet: HG_Packet = {
    _header: header,
    _contents: contents,
  };
  ws.getInstance().send(packet);
}
export function LogDataReq(
  drone_target: number,
  log_id: number,
  log_size: number
) {
  const header: HG_Header = {
    _msg_type: E_MESSAGE_TYPE.FC_LOG,
    _target_indicator: 1 << drone_target,
  };
  const contents: HG_Contents = {
    _drone_mission: {
      _index: 0,
      _command: Command.COMMAND_FC_LOG_DATA,
      _sub_command: 0,
      _wait_time: 0,
      _rc_channel: {
        _ch1: log_id,
        _ch2: log_size,
        _ch3: 0,
        _ch4: 0,
        _ch5: 0,
        _ch6: 0,
        _ch7: 0,
        _ch8: 0,
        _ch9: 0,
      },
    },
  };
  const packet: HG_Packet = {
    _header: header,
    _contents: contents,
  };
  ws.getInstance().send(packet);
}
export function RequestStackList() {
  const header: HG_Header = {
    _msg_type: E_MESSAGE_TYPE.STACK_LIST,
  };
  const contents: HG_Contents = {};
  const packet: HG_Packet = {
    _header: header,
    _contents: contents,
  };

  ws.getInstance().hyd_send(packet);
}
export function RequestStackData(stack_serial_list: string[]) {
  const header: HG_Header = {
    _msg_type: E_MESSAGE_TYPE.STACK_SUBSCRIPTION,
  };
  let contents:any = []
  const serials:HG_Hydrogen_Serial[] = [];
  stack_serial_list.forEach((stack_serial:string) => {
            serials.push({
                _serial_num: stack_serial,
            })
        });

  // 데이터가 있을때만 추가해서 보냄
  if (0 != stack_serial_list.length) {
    contents = {
      _subscription_list: serials,
    };
  }

  const packet: HG_Packet = {
    _header: header,
    _contents: contents,
  };
  
  ws.getInstance().hyd_send(packet);
}
export function RequestStackLogFileList() {
  if(store.getters.GetAuthState) {
      const packet:HG_Packet = {
          _header: {
              _msg_type: E_MESSAGE_TYPE.STACK_LOG_FILE_LIST,
          },
          _contents: {}
      };
  
      ws.getInstance().hyd_send(packet);
  }
}
export function RequestStackLogFileData(file_name_list:HG_Hydrogen_Log_Info2[]) {
  if(store.getters.GetAuthState) {
      const packet:HG_Packet = {
          _header: {
              _msg_type: E_MESSAGE_TYPE.STACK_LOG_FILE_DATA,
          },
          _contents: {
              _filename_list: file_name_list
          }
      };
  
      ws.getInstance().hyd_send(packet);
  }
}
export function Arm(drone_Info:CESIUM_DRONE) {
  let main_command: Command = 0;
  let sub_command: SubCommand = 0;
  main_command = Command.COMMAND_READY2FLIGHT;
  sub_command = SubCommand.SUB_COMMAND_ARM;

  const coordinate: HG_Coordinate = {
    _lat: 0,
    _lon: 0,
    _alt: 0,
  };
  const mission: HG_Mission = {
    //_index: 0,
    _command: main_command,
    _sub_command: sub_command,
    _wait_time: 2,
    _coordinate: coordinate,
  };

  const header: HG_Header = {
    _index: 1,
    _msg_type: E_MESSAGE_TYPE.MISSION_ADD,
    _target_indicator: 1 << drone_Info.GetNoti()._index,
  };

  const contents: HG_Contents = {
    _drone_mission: mission,
  };

  const packet: HG_Packet = {
    _header: header,
    _contents: contents,
  };

  ws.getInstance().send(packet);
}
export function DroneMissionSend(drone:CESIUM_DRONE|undefined, mission_object:MissionObject|undefined) {
  if(!mission_object || !drone) return;
  const Cesium = cesiumService.GetCesium();
  const viewer = cesiumService.GetViewer();
  const is_armed = drone.IsArmed()
  const arm_position = drone.GetState()?.alt_tracking[0]._coordinate_alt;
  const drone_state:HG_DroneState|undefined = drone.GetState();
  DroneMissionStop(drone, false);

  let st_position:any|undefined = undefined;
  // Arming 인 경우
  if(is_armed && arm_position) {
    st_position = Cesium.Cartesian3.fromDegrees(arm_position._lon/1e7, arm_position._lat/1e7);
  }
  // 그 외 경우 현재 드론 위치로..
  else {
    if(drone_state) {
      st_position = Cesium.Cartesian3.fromDegrees(drone_state._gps_int._lon / 1e7, drone_state._gps_int._lat / 1e7);
    }
  }

  // 시작 지점이 없는 경우는 없다..
  if(!st_position) {
    console.error('why..?');
    return;
  }

  const cartographic = Cesium.Cartographic.fromCartesian(st_position);
  const st_position_globe_height = viewer.scene.globe.getHeight(cartographic);

  mission_object.items.forEach((mission_item: MissionItem) => {
    let main_command: Command = 0;
    let sub_command: SubCommand = 0;

    let lat = 0;
    let lon = 0;
    let alt = 0;

    if (mission_item.type == MissionItemType.ARM) {
      main_command = Command.COMMAND_READY2FLIGHT;
      sub_command = SubCommand.SUB_COMMAND_ARM;
    } else if (mission_item.type == MissionItemType.RTL) {
      main_command = Command.COMMAND_MODE;
      sub_command = SubCommand.SUB_COMMAND_RETURN2LAND_MODE;
    } else if (mission_item.type == MissionItemType.LAND) {
      main_command = Command.COMMAND_MODE;
      sub_command = SubCommand.SUB_COMMAND_LAND_MODE;
    } else if (mission_item.type == MissionItemType.TAKEOFF) {
      main_command = Command.COMMAND_READY2FLIGHT;
      sub_command = SubCommand.SUB_COMMAND_TAKEOFF;
      alt = mission_item.agl;
    } else if (mission_item.type == MissionItemType.WAYPOINT) {
      main_command = Command.COMMAND_COORDINATE;
      sub_command = SubCommand.SUB_COMMAND_ALT_OPTION_RELATIVE;
    } else if (mission_item.type == MissionItemType.ROION) {
      main_command = Command.COMMAND_ROI_SET;
      sub_command = SubCommand.SUB_COMMAND_NONE;
    } else if (mission_item.type == MissionItemType.ROIOFF) {
      main_command = Command.COMMAND_ROI_RESET;
      sub_command = SubCommand.SUB_COMMAND_NONE;
    }
    lat = mission_item.lat;
    lon = mission_item.lng;
    alt = (mission_item.type === MissionItemType.WAYPOINT) ? (mission_item.asl - (st_position_globe_height + 26.6871)) : (mission_item.type === MissionItemType.TAKEOFF) ? mission_item.agl : 0;
    const coordinate: HG_Coordinate = {
      _lat: Math.floor(lat * 1e7),
      _lon: Math.floor(lon * 1e7),
      _alt: Math.ceil(alt), // fc로 높이 보낼 때 소수점 없이 ceil(올림)로 적용해서 정수값으로 보내줘야함
    };
    

    const mission: HG_Mission = {
      _uuid: mission_object._id,
      _index: Number(mission_item.id),
      _command: main_command,
      _sub_command: sub_command,
      _wait_time: mission_item.timeout,
      _coordinate: coordinate,
    };

    // console.log('mission send', mission._index, mission)

    const header: HG_Header = {
      _index: 1,
      _msg_type: E_MESSAGE_TYPE.MISSION_ADD,
      _target_indicator: 1 << drone.GetNoti()._index,
    };

    const contents: HG_Contents = {
      _drone_mission: mission,
    };

    const packet: HG_Packet = {
      _header: header,
      _contents: contents,
    };

    ws.getInstance().send(packet);
    CustomAlert.getInstance().ShowAlert(`Send mission(${mission_object.alias}) to drone(${drone.GetNoti()._name})`, {color:'success', timeout: 3000});
  })
}

export function DroneMissionStart(drone: CESIUM_DRONE) {
  if (drone.GetState()?._heartbeat._custom_mode == 4) {
    const header: HG_Header = {
      _index: 0,
      _msg_type: E_MESSAGE_TYPE.MISSION_START,
      _target_indicator: 1 << drone.GetNoti()._index,
    };

    const contents: HG_Contents = {
      //empty..
    };

    const packet: HG_Packet = {
      _header: header,
      _contents: contents,
    };

    console.log(packet);
    ws.getInstance().send(packet);
    CustomAlert.getInstance().ShowAlert(`Mission start: ${drone.GetNoti()._name}`, {color:'success', timeout: 3000});
  } else {
    CustomAlert.getInstance().ShowAlert("Please, Change to GCS mode");
  }
}
export function DroneMissionStop(drone: CESIUM_DRONE, alert:boolean) {
  const header: HG_Header = {
    _index: 0,
    _msg_type: E_MESSAGE_TYPE.MISSION_STOP,
    _target_indicator: 1 << drone.GetNoti()._index,
  };

  const contents: HG_Contents = {
    //empty..
  };

  const packet: HG_Packet = {
    _header: header,
    _contents: contents,
  };

  console.log("stop packet : ", packet);
  ws.getInstance().send(packet);
  if(alert) {
    CustomAlert.getInstance().ShowAlert(`Mission stop: ${drone.GetNoti()._name}`, {color:'success', timeout: 3000});
  }
}
export function DroneMissionSuspend(drone: CESIUM_DRONE) {
  if (drone.GetState()?._heartbeat._custom_mode == 4) {
    const header: HG_Header = {
      _index: 0,
      _msg_type: E_MESSAGE_TYPE.MISSION_SUSPEND,
      _target_indicator: 1 << drone.GetNoti()._index,
    };

    const contents: HG_Contents = {
      //empty..
    };

    const packet: HG_Packet = {
      _header: header,
      _contents: contents,
    };

    console.log("stop packet : ", packet);
    ws.getInstance().send(packet);
    CustomAlert.getInstance().ShowAlert(`Mission suspend: ${drone.GetNoti()._name}`, {color:'success', timeout: 3000});
  } else {
    CustomAlert.getInstance().ShowAlert("Please, Change to GCS mode");
  }
}