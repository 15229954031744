
import { WEBRTC, ChannelInfo } from '@/services/hg/drone_tv/webrtc';
import { ptz_home, ptz_move, ptz_stop } from "@/services/hg/drone_tv/gimbal_control";
import { computed, defineComponent } from "vue";
import store from '@/store';
import { E_AI_TYPE } from '@/services/ws/ws_hg_server_protocol';
import ExtensionAiManager, { HG_Ai_Data } from '@/services/hg/extension_ai/ExtensionAiManager';

export default defineComponent({
  name: 'AIComponent',
  props: {
    video_tag_key: {
      type: String,
      required: true
    },
    size_w: {
      type: Number,
      required: true
    },
    size_h: {
      type: Number,
      required: true
    },
    count: {
      type: Number,
      required: true
    },
  },
  watch: {
    size_h: {
      handler(n, o) {
        this.h_asd = n-120
      }
    },
  },

  data() {
    return {
      dt: new WEBRTC(),
      ai_img_arr_test: [] as any[],
      h_asd:0,
      screen_size: 1,
      screen_size_w: 300,
      selected_channel: {} as ChannelInfo|undefined,
      channel_box_state: false,
      channel_list: [] as ChannelInfo[],
      description: undefined as undefined | string,
      is_show_ptz: false,
      target_index: 0,
      is_press: false,
      is_continuous: false,
      ptz_command: '',
      continuous_active_time_ms: 180,
      to: undefined as any,
      selected_snapshot: {} as HG_Ai_Data,
      dummy_data: [{
_image :'asd',
_latitude: 32.159,
_longitude: 13.448945,
_sequence: 152, 
_type: 1,
      }]
    };
  },

  setup() {
    const channels = computed(() => store.getters.GetAiChannel)
    const ai_people_counting_arr = computed(() => store.getters.GetAiImagePeopleCounting)
    const ai_vehicle_arr = computed(() => store.getters.GetAiImageVehicle)
    const ai_fence_detect_arr = computed(() => store.getters.GetAiImageFenceDetect)
    return {channels, ai_people_counting_arr, ai_vehicle_arr, ai_fence_detect_arr}
  },

  mounted() {
    const video_element = document.getElementById(this.video_tag_key);
    if (video_element) {
      this.dt.SetVideoElement(video_element);
    }
  },

  beforeDestroy() {
    this.dt.UserStop();
  },

  methods: {
    onClickRemove(info:any) {
      if(info.target._prevClass == 'v-btn__content' || info.target._prevClass == 'screen-icon') {
        return
      } else {
        this.channel_box_state = false
      }
      console.log(this.channel_box_state)
    },
    home() {
      ptz_home(this.target_index);
    },
    mouse_down(dir: string) {
      this.ptz_command = dir;
      this.is_press = true;
      this.to = setTimeout(() => {
        if (this.is_press) {
          this.is_continuous = true;
          console.log('continuous move', this.ptz_command, this.target_index)
          ptz_move(this.ptz_command, this.target_index, true);
        }
      }, this.continuous_active_time_ms)
    },
    mouse_up() {
      clearTimeout(this.to);
      this.is_press = false;

      if (this.is_continuous) {
        this.is_continuous = false;
        console.log('continuous stop', this.target_index)
        ptz_stop(this.target_index);
      }
      else {
        console.log('move', this.ptz_command, this.target_index)
        ptz_move(this.ptz_command, this.target_index);
      }
    },
    playUrl() {
      const url = prompt('Input rtsp url', 'rtsp://125.136.97.200:40000/ds-test')
      if (url) {
        this.description = url;
        this.dt.PlayFromUrl(url);
        this.is_show_ptz = false;
      }
    },
    full_screen(e: any) {
      const fullscreenTarget = document.getElementById(this.video_tag_key);

      if (fullscreenTarget) {
        if (fullscreenTarget.requestFullscreen) {
          fullscreenTarget.requestFullscreen();
        }
      }
    },
    onClickchannellist() {
      this.channel_list = []
      this.channels.forEach((channel:ChannelInfo) => {
        if(channel.ai_type != E_AI_TYPE.NONE) {
          this.channel_list.push(channel)
        }
      });
      this.channel_box_state = !this.channel_box_state
    },

    change_channel(channel: ChannelInfo) {
      if (channel) {
        this.description = `[${channel.index}]${channel.psn}: ${channel.des}`;
        if(channel.ai_url) {
          this.dt.PlayFromUrl(channel.ai_url);
        }
        this.is_show_ptz = true;
        this.target_index = channel.index;
        this.selected_channel = channel
      } else {
        this.description = undefined;
        this.dt.UserStop();
        this.is_show_ptz = false;
        this.selected_channel = undefined;
      }
      this.channel_box_state = false
    },
    ScreenSize(size:number) {
      this.screen_size = size
    },
    onClickSnapshotImg(data:HG_Ai_Data) {
      this.selected_snapshot = data
    },
    onClickSnapshotImgClose() {
      this.selected_snapshot = {} as HG_Ai_Data
    },
    MouseWheelEvent(e:any) {
      const scroll_x:any = this.$refs.scroll_container;
      scroll_x.scrollLeft += e.deltaY;
    },
    ScrollBtn(type:any) {
      const scroll_x:any = this.$refs.scroll_container;
      if(type == 'left') {
        scroll_x.scrollLeft += -this.size_w*0.8;
      } else {
        scroll_x.scrollLeft += this.size_w*0.8;
      }
    }
  },
});
