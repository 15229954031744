import { render, staticRenderFns } from "./LogView.vue?vue&type=template&id=2a18db70&scoped=true&"
import script from "./LogView.vue?vue&type=script&lang=ts&"
export * from "./LogView.vue?vue&type=script&lang=ts&"
import style0 from "./LogView.vue?vue&type=style&index=0&id=2a18db70&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a18db70",
  null
  
)

export default component.exports