
import CESIUM_DRONE from "@/services/hg/cesium_drone";
import CustomAlert from "@/services/hg/custom_alert";
import DEVICE from "@/services/hg/device/Device";
import { E_CAMERA, E_EQUIPMENT, E_GPS, E_LIDAR } from "@/services/hg/drone_equipment_type";
import DroneManager from "@/services/hg/drone_manager";
import MissionManager, { MissionObject } from "@/services/hg/mission/mission_manager";
import SingleManager from "@/services/hg/single/single_manager";
import STACK from "@/services/hg/stack/Stack";
import StackManager from "@/services/hg/stack/StackManager";
import { DroneMissionSend, DroneMissionStart, DroneMissionStop, DroneMissionSuspend } from "@/services/ws/ws_hg_server_message_sender";
import { HG_DroneState } from "@/services/ws/ws_hg_server_packet_interface";
import { SubCommand } from "@/services/ws/ws_hg_server_protocol";
import store from "@/store";
import { computed, defineComponent } from "vue";
export default defineComponent({
  data() {
    return {
      drone_connection : true,
      drone_name:DroneManager.getInstance().GetDroneDetailData(),
      drone: {} as CESIUM_DRONE,
      drone_state: {} as HG_DroneState|any,

      tab_open: false,
      selected_mission_name: 'None',
      panel: [],
      mission_manager: MissionManager.getInstance() as MissionManager,
      selected_mission: {} as MissionObject|undefined,
      equipment_lidar: E_EQUIPMENT.LIDAR,
      equipment_gps: E_EQUIPMENT.GPS,
      equipment_camera: E_EQUIPMENT.CAMERA,

      equipment_lidar_sf40c: E_LIDAR.SF40C,
      equipment_gps_nomal: E_GPS.NORMAL,
      equipment_gps_mbc_rtk: E_GPS.MBC_RTK,
      equipment_gps_kr_rtk: E_GPS.KR_RTK,
      equipment_camera_usb: E_CAMERA.USB,
      equipment_camera_viewpro: E_CAMERA.VIEWPRO,
      equipment_camera_onvif: E_CAMERA.ONVIF,

      stack_manager: StackManager.getInstance(),
      selected_stack: undefined as STACK|undefined,
      stacks: [] as STACK[],
      devices: [] as DEVICE[],
      selected_stack_serial: '',
      switching_state: true, // true = stack list, false = stack info 
    };
  },
  setup() {
    const show_drone_detail_tab = computed(() => store.getters.GetDroneDetailTab);
    const missions = computed<MissionObject[]>(() => store.getters.GetMissions);
    const store_stacks = computed(() => store.getters.GetStacks);
    const store_devices = computed(() => store.getters.GetDevices);
    const controller_mode = computed(() => store.getters.GetControllerMode);
    const store_drones = computed<CESIUM_DRONE[]>(() => store.getters.GetDrones);
    return { show_drone_detail_tab, missions, store_stacks, store_devices, controller_mode, store_drones};
  },

  watch: {
    show_drone_detail_tab: {
      handler(n, o) {
        if(n) {
          this.drone_name = DroneManager.getInstance().GetDroneDetailData();
          const DroneManagerGetDrone = computed(() => DroneManager.getInstance().GetDrone(this.drone_name)).value
          if(DroneManagerGetDrone) {
            this.drone = DroneManagerGetDrone
            this.drone_state = computed(() =>DroneManagerGetDrone.GetState())
            console.log("TEST", this.drone_state._gps_int)
            this.selected_mission = this.drone.GetMission()
          }
        } else {
          this.panel = []
        }

        this.store_drones.forEach((drone:CESIUM_DRONE) => {
          if(drone.GetNoti()._name == this.drone_name) {
            this.drone_connection = true
          } else {
            this.drone_connection = false
          }
        })

        this.tab_open = n;
      },
    },
    store_stacks: {
      handler(n, o) {
        const drone_name = DroneManager.getInstance().GetDroneDetailData();
        // this.stacks = StackManager.getInstance().GetStackFromDeviceSerial('D-6');
        this.stacks = StackManager.getInstance().GetStackFromDeviceSerial(drone_name);
        this.selected_stack = StackManager.getInstance().GetStackFromStackSerial(this.selected_stack_serial);
      },
    },
    store_drones: {
      handler(n, o) {
        // const DroneName = DroneManager.getInstance().GetDroneDetailData()
        if(n.length > 0) {
          n.forEach((drone:CESIUM_DRONE) => {
            console.log('name', this.drone_name)
            if(drone.GetNoti()._name == this.drone_name) {
              this.drone_connection = true
            }
            else {
              this.drone_connection = false
            }
          })
        } else {
          this.drone_connection = false
        }
      },
    },
  },

  methods: {    
    Back() {
      store.commit('SetDroneDetailTab', false)
      store.commit('SetSideDroneTab', true)
      this.selected_mission = undefined
      this.selected_mission_name = 'None'
      this.selected_stack = undefined
      this.selected_stack_serial = ''
      this.switching_state = true
    },
    onClickGCS(drone:CESIUM_DRONE) {
      SingleManager.getInstance().ModeChange(SubCommand.SUB_COMMAND_GUIDE_MODE, drone.GetNoti()._index)
    },
    onClickController(drone:CESIUM_DRONE) {
      SingleManager.getInstance().ModeChange(SubCommand.SUB_COMMAND_LOITER_MODE, drone.GetNoti()._index)
    },
    onClickMissionSelect(drone:CESIUM_DRONE, mission:MissionObject) {
        this.selected_mission = mission
        this.selected_mission_name = mission.alias
        DroneMissionSend(drone, mission)
    },
    OnClickMissionStart(drone:CESIUM_DRONE) {
      if(this.selected_mission) {
        DroneMissionStart(drone)
      } else {
        CustomAlert.getInstance().ShowAlert('Please Select a Mission')
      }
    },
    OnClickMissionStop(drone:CESIUM_DRONE) {
      if(this.selected_mission) {
        DroneMissionStop(drone, true)
      } else {
        CustomAlert.getInstance().ShowAlert('Please Select a Mission')
      }
    },
    OnClickMissionSuspend(drone:CESIUM_DRONE) {
      if(this.selected_mission) {
        DroneMissionSuspend(drone)
      } else {
        CustomAlert.getInstance().ShowAlert('Please Select a Mission')
      }
    },
    onClickSelectStack(stack:STACK) {
        this.switching_state = !this.switching_state;
        this.selected_stack_serial = stack.Get().stack_serial
    },
    onClickStackBack() {
      this.switching_state = !this.switching_state; 
    },
  },
});
