
import CustomAlert from "@/components/CustomAlert.vue";
import { login } from "@/services/ws/ws_hg_server_message_sender";
import { defineComponent } from "vue";

export default defineComponent({
  
  data() {
    return {
      id: 'hg',
      pw : '1234567',
    };
  },

  setup() {
    //
  },

  mounted() {
    window.addEventListener("keyup", (key) => {
      this.SetKeyboardEvent(key)
    })
  },

  methods: {
    onClickLoginBtn() {
      login(this.id, this.pw)
    },
    SetKeyboardEvent(key:any) {
      if (key.code == 'Enter') {
        this.onClickLoginBtn()
      }
    },
  },

  components: {
    CustomAlert,
  }

});
