// singletone websocket class
// author jhlee

import {
  hydrogen_parse,
  parse,
} from "@/services/ws/ws_hg_server_message_handler";

import store from "@/store";
import { login, loginHyd } from "./ws_hg_server_message_sender";
import { HG_Packet } from "./ws_hg_server_packet_interface";

class WS_HG_SERVER {
  private static instance: WS_HG_SERVER;

  public static getInstance() {
    return this.instance || (this.instance = new this());
  }

  private ws: WebSocket | null = null;
  private hydrogen_ws: WebSocket | null = null;

  private gcs_server_url = 'wss://gcs.hogreenair.com/ws'
  private hsm_server_url = 'wss://hsm.hogreenair.com/ws'

  private constructor() {
    this.connect();
  }

  private connect() {

    this.ws = new WebSocket(this.gcs_server_url);
     
    this.ws.onopen = (e: any) => {
      console.log('GCS Server connected', e);
    };

    this.ws.onmessage = (e: any) => {
      // console.log('websocket e.data._header',e.data[26])
      parse(e.data);
    };

    this.ws.onerror = (e: any) => {
      console.error('GCS Server error', e);
    };

    this.ws.onclose = (e: any) => {
      const is_auth = store.getters.GetAuthState;
      store.commit("SetAuthState", false)
      this.ws = null;

      if(is_auth) {
        this.connectWithAuth();
      }
      else {
        this.connect();
      }
    };
  }

  private connectWithAuth() {
    this.ws = new WebSocket(this.gcs_server_url);

    this.ws.onopen = (e: any) => {
      console.log('GCS Server connected', e);
      const login_data = store.getters.GetLoginData;
      
      login(login_data.id, login_data.pw);
    };

    this.ws.onmessage = (e: any) => {
      // console.log('ws.onmessage', e.data)
      parse(e.data);
    };

    this.ws.onerror = (e: any) => {
      console.error('GCS Server error', e);
    };

    this.ws.onclose = (e: any) => {
      store.commit("SetAuthState", false)
      this.ws = null;

      this.connectWithAuth();
    };
  }

  HydrogenConnectWithAuth() {
    this.hydrogen_ws = new WebSocket(this.hsm_server_url);

    this.hydrogen_ws.onopen = (e: any) => { 
      console.log("Hydrogen Server connected");
      loginHyd();
    };

    this.hydrogen_ws.onmessage = (e: any) => {
      hydrogen_parse(e.data);
    };

    this.hydrogen_ws.onerror = (e: any) => {
      console.error('HYD Server error', e);
    };

    this.hydrogen_ws.onclose = (e: any) => {
      store.commit("SetStackAuth", false)

      this.hydrogen_ws = null;
      this.HydrogenConnectWithAuth();
    };
  }

  send(msg: HG_Packet) {
    if(this.ws) {
      if(this.ws.readyState == WebSocket.OPEN) {
        this.ws.send(JSON.stringify(msg));
      }
      else {
        console.error(`GCS socket not opened, current state is ${this.ws.readyState}`);
      }
    }
    else {
      console.error('GCS socket not ready');
    }
  }

  hyd_send(msg: HG_Packet) {
    if(this.hydrogen_ws) {
      if(this.hydrogen_ws.readyState == WebSocket.OPEN) {
        this.hydrogen_ws.send(JSON.stringify(msg));
      }
      else {
        console.error(`Hyd socket not opened, current state is ${this.hydrogen_ws.readyState}`);
      }
    }
    else {
      console.error('Hyd socket not ready');
    }
  }
}

export default WS_HG_SERVER;
