
import CesiumScreenSpaceEventService, { GCS_MODE } from "@/services/cesium/cesium_screen_space_ev_service";
import CESIUM_DRONE from "@/services/hg/cesium_drone";
import CustomAlert from "@/services/hg/custom_alert";
import MissionManager from "@/services/hg/mission/mission_manager";
import SingleManager from "@/services/hg/single/single_manager";
import UserSettingManager from "@/services/hg/user_setting/user_setting_manager";
import { USER_SETTINGS } from "@/services/hg/user_setting/user_setting_protocol";
import { E_APP_TYPE, MissionState } from "@/services/ws/ws_hg_server_protocol";
import store from "@/store";
import axios from "axios";
import { computed, defineComponent } from "vue";
export default defineComponent({
  data() {
    return {
      mode: 0,
      btn_state: false,
      mission_state_run: MissionState.RUN,
      mission_state_suspend: MissionState.SUSPEND,

      mouse_over_btn_name:'',
      drones: [] as CESIUM_DRONE[],
      center_switch: true,

      yaw_tick: 0,

      battery_voltage: 0,
      battery_power: 0,
      spm_input_power: 0,
      output_power: 0,
      gh_tank_level: 0,

      fcpm_state_run: 0,
      fcpm_state_sleep: 0,
      fcpm_state_dst: 0,
      fcpm_state_dc: 0,

      gh2_tank1_volume: 0,
      gh2_tank1_level: 0,
      gh2_tank2_volume: 0,
      gh2_tank2_level: 0,

      lh2_tank_volume: 0,
      lh2_remain_level: 0,
      lh2_total_use: 0,
      lh2_short_time_use: 0,

      stack_type: '',

      stack_serial: '',

      flight_time: '00:00:00',
      flight_time_second: 0,
      flight_time_weight: 0.6,

      is_stack_drone: false,

      out_test: 3000,
      gh_test:400,
      total_use_test: 150,
      time_test: 7200,
      deg_test: 0,

      is_flip: false,
      is_flip_possible: false,

      interval: 0,
      time: 0,
    };
  },

  setup() {
    const show_single = computed(() => store.getters.GetShowSingle);
    const single_drone = computed<CESIUM_DRONE|undefined>(() => store.getters.GetSingleDrone);
    const store_drones = computed<CESIUM_DRONE[]>(() => store.getters.GetDrones);
    const app_type = computed(() => store.getters.GetAppType);
    const stack_data = computed(() => store.getters.GetSelectedStackData)
    const stack_list = computed(() => store.getters.GetStackList)
    const gcs_auth = computed(() => store.getters.GetAuthState)
    const log_message_state = computed(() => store.getters.GetLogMessageState);
    const controller_mode = computed(() => store.getters.GetControllerMode);
    const roi_state = computed(() => store.getters.GetRoiState);

    return { show_single, single_drone, store_drones, stack_data, stack_list, gcs_auth, app_type, log_message_state, controller_mode, roi_state};
  },
  watch: {
    group() {
      this.show_single = false;
    },
    store_drones: {
      handler(new_drone_list:CESIUM_DRONE[], o:CESIUM_DRONE[]) {

        this.drones = new_drone_list.filter((d:CESIUM_DRONE)=> !d.GetNoti()._simulate);

        let is_exist = false

        new_drone_list.forEach((drone:CESIUM_DRONE) => {
          if (this.single_drone != undefined)
          {
            // 드론 업데이트 이후 선택된 드론이 존재하면 스킵
            if(drone.GetNoti()._name == this.single_drone?.Get().GetNoti()._name)
            {  
              is_exist = true
            }
          }
        });

        // 드론 업데이트 이후 선택된 드론이 없을 경우 데이터 초기화
        if(is_exist == false)
        {
          this.is_flip = false
        }
      },
      deep: true,
    },
    gcs_auth: {
      handler(new_auth, o) {

        // GCS 서버 연결 끊길 시 싱글창 표시 데이터 초기화
        if(new_auth == false)
        {
          this.is_flip = false

          store.commit("SetSingleDrone", undefined);

          // 네트워크 끊길 시 경고창 표시
          CustomAlert.getInstance().ShowAlert("Network Error");

          // 10초엔 한 번씩 경고창 계속 표시
          this.interval = setInterval(() => {
            CustomAlert.getInstance().ShowAlert("Network Error");
          }, 10000)
        }
        else
        {
          // 연결되면 경고창 Interval 클리어 시키고 연결 성공 창 표시
          CustomAlert.getInstance().ShowAlert("Network Connected", {color:'green'});
          clearInterval(this.interval)
        }
      },
    },
    // stack_list: {
    //   handler(new_stack_list, o) {

    //     // 초기화 돼서 0이 되면 다시 변수 초기화
    //     if(new_stack_list.length == 0)
    //     {
    //       // console.log('flip possible clear', new_stack_list)
    //       this.is_flip_possible = false
    //     }   

    //     if (this.single_drone)
    //     {
    //       // Flip을 한 번도 못한, 즉 불가능한 상태일때 데이터가 나중에 들어와서 뒤집어야 하는 경우
    //       if(this.is_flip_possible == false)
    //       {
    //         // 클릭한 드론이 스택 리스트에 연결된 드론인지 판별 후 연결됐다면 수소창 바로 표시
    //         new_stack_list.forEach((stack:HG_HydrogenInfos[]) => {
    //           if(stack.length != 0)
    //           {
    //             if(stack[0]._drone_index == this.single_drone?.GetNoti()._index) this.is_flip = true
    //           }
    //         });
    //       }
    //       else console.log('Already Flip Possible !')
    //     }

    //   },
    //   deep: true,
    // }
  },
  mounted() {
    // 모니터링 계정일 경우 싱글 버튼 작동 안하도록
    if((this.app_type & E_APP_TYPE.GCS_MONITOR) != 0) {
      this.btn_state = true
    }
    // Dummy Data Generate
    setInterval(() => {

      if (this.out_test <= 0) this.out_test = 3000
      else this.out_test -= 100

      if (this.gh_test <= 0) this.gh_test = 400
      else this.gh_test -= 10

      if (this.total_use_test >= 400) this.total_use_test = 150
      else this.total_use_test += 1

      if (this.time_test <= 0) this.time_test = 7200
      else this.time_test -= 100

      if (this.deg_test >= 360) this.deg_test = 0
      else this.deg_test += 10

    }, 500)

    // 10초에 한 번씩 드론 상태값 체크해서 선택한 드론이 arm 상태일 경우 비행 시간에 따른 alert 출력
    setInterval(() => {
        this.store_drones.forEach((drone:CESIUM_DRONE) => {
        if(drone.GetNoti()._name == this.single_drone?.GetNoti()._name)
        {
          // Stack Drone만 비행 시간 계산이 가능하므로 조건문 추가
          if(this.is_stack_drone)
          {
            // Drone이 Arm 상태일때만 비행 시간 계산 결과 alert 출력
            if(drone.IsArmed())
            {
              if(this.time / 60 < 10) CustomAlert.getInstance().ShowAlert('Low Fuel Gauge', {color:'red'})
              else if(this.time / 60 < 30) CustomAlert.getInstance().ShowAlert('Warning Fuel Gauge', {color:'orange'})
              else console.log('Flight Time Good')
            }
            else console.log('no arm !')
          }
          else console.log('not stack drone !')
        }
      });
      
    }, 10000)

    this.CalcFlightTime()

    setInterval(() => {
      this.CalcFlightTime()
    }, 1000);

    setInterval(() => {

      if (this.stack_data.has(this.single_drone?.GetNoti()._index)) {
        this.is_stack_drone = true;

        const stack_map_list = this.stack_data.get(this.single_drone?.GetNoti()._index) // Array 이고 안에 스택 개수만큼 Map 존재

        // 가져온 데이터가 Array 이면서 길이가 1개 이상일 경우
        if (Array.isArray(stack_map_list) && stack_map_list.length > 0) {
          // 첫번째 스택값 할당
          const stack_map_data: Map<string, any> = stack_map_list[0]

          const stack_map_key = stack_map_data.keys().next().value

          if (stack_map_key) {
            // console.log('LOL : ', stack_map_data.get(stack_map_key))

            this.stack_serial = stack_map_key

            this.battery_voltage = stack_map_data.get(stack_map_key).get('BatteryVoltage')
            this.battery_power = stack_map_data.get(stack_map_key).get('BatteryPower')
            this.spm_input_power = stack_map_data.get(stack_map_key).get('SPMInputPower') // 스택과 배터리 둘 다 쓰는 합산 와트량
            this.output_power = stack_map_data.get(stack_map_key).get('OutputPower') // 스택에서만 쓰는 와트량
            this.gh_tank_level = stack_map_data.get(stack_map_key).get('TankLevel') // 기체 수소 Tank 용량? 데이터

            this.fcpm_state_run = stack_map_data.get(stack_map_key).get('FCPMState_Run')
            this.fcpm_state_sleep = stack_map_data.get(stack_map_key).get('FCPMState_Sleep')
            this.fcpm_state_dst = stack_map_data.get(stack_map_key).get('FCPMState_DST')
            this.fcpm_state_dc = stack_map_data.get(stack_map_key).get('FCPMState_DC')

            this.gh2_tank1_volume = stack_map_data.get(stack_map_key).get('GH2Tank1Volume') // 3
            this.gh2_tank1_level = stack_map_data.get(stack_map_key).get('GH2Tank1Level') // 2
            this.gh2_tank2_volume = stack_map_data.get(stack_map_key).get('GH2Tank2Volume') // 3
            this.gh2_tank2_level = stack_map_data.get(stack_map_key).get('GH2Tank2Level') // 2

            this.lh2_tank_volume = stack_map_data.get(stack_map_key).get('LH2TankVolume')
            this.lh2_remain_level = stack_map_data.get(stack_map_key).get('LH2RemainLevel')
            this.lh2_total_use = stack_map_data.get(stack_map_key).get('LH2HydrogenTotalUse')
            this.lh2_short_time_use = stack_map_data.get(stack_map_key).get('LH2HydrogenShortTimeUse')

            this.stack_type = stack_map_data.get(stack_map_key).get('FuelType')
          }
        }
        else console.log('Single Cmd Get Stack Data Error')
      }
      else this.is_stack_drone = false;

    }, 1000);
  },
  methods: {
    MouseOver(btn_name:string) {
      this.mouse_over_btn_name = btn_name
    },
    ShowDronePosition(drone:CESIUM_DRONE|undefined) {
      if(drone) {
        drone.DisplayMe();
      }
    },
    // Flip() {
    //     // 클릭한 드론이 스택 리스트에 연결된 드론인지 판별 후 연결됐다면 수소창 바로 표시
    //   this.stack_list.forEach((stack: HG_HydrogenInfos[]) => {
    //     if (stack.length != 0) {
    //       if (stack[0]._drone_index == this.single_drone?.GetNoti()._index) {
    //         this.is_flip = !this.is_flip

    //         // Flip이 되는 순간 무조건 데이터가 계속 있으므로 이때부턴 자동 뒤집기 불가능 처리
    //         this.is_flip_possible = true
    //       }
    //     }
    //   });
    // },
    CalcFlightTime(){
        if(this.single_drone?.GetNoti()._index)
        {
            let calc = null

            if(this.stack_type == 'L')
            {
              // 액체 수소로 비행 시간 구하기 (시연은 깡 입력 데이터 필요)
              calc = axios.get(`/gcs/eft/lh?wat=${this.output_power}&usage=${Number(this.lh2_total_use)}&remain=${Number(this.lh2_remain_level)}`);
            }
            else if(this.stack_type == 'G')
            {
              // 기체 수소로 비행 시간 구하기 (7번 드론 값)
              calc = axios.get(`/gcs/eft/gh?wat=${this.output_power}&bar=${Number(this.gh_tank_level)}&vol=${6.8}`);
            }
            else {
              // console.log('Not Receive Stack Type')
            } 
            
            if(calc)
            {
              calc.then(res => {
                try {
                  if(res.status == axios.HttpStatusCode.Ok)
                  {
                    const data = res.data;
                    console.log('time data : ', data.flight_time, 'stack type : ', this.stack_type, 'output :', this.output_power, 'ghTank : ', this.gh_tank_level, 'lhUse : ', Number(this.lh2_total_use))
                    this.flight_time_second = (this.flight_time_second * this.flight_time_weight) + (data.flight_time * (1 - this.flight_time_weight));
                    this.ReturnFlightTime(data)
                  }
                  
                } catch (error) {
                  console.error('error hydrogen request tick : ', error);
                }
              })
            }
        }
        // else console.log('Not Selected Single Drone ! (Flight Time Calc)')
    },
    ReturnFlightTime(time: any) {
      const pad = (num: any) => String(num).padStart(2, '0');
      const hour = pad(Math.floor(time.flight_time / 3600));
      const minute = pad(Math.floor((time.flight_time % 3600) / 60));
      const second = pad(time.flight_time % 60);

      this.time = time.flight_time
      this.flight_time = `${hour}:${minute}:${second}`

      // console.log('Flight Time ! : ', this.flight_time)

      // 비행 시간이 10분 미만일 경우 위험 표시
      // if(Number(hour) == 0 && Number(minute) < 10) CustomAlert.getInstance().ShowAlert('Flight Time Dangerous', {color:'red'})

      // 비행 시간이 30분 미만일 경우 경고 표시
      // else if(Number(hour) == 0 && Number(minute) < 30) CustomAlert.getInstance().ShowAlert('Flight Time Warning', {color:'orange'})
    },
    onClickSwitch(state: boolean) {
      state ?
        this.center_switch = false : this.center_switch = true;
    },
    onClickLogMessage() {
      store.commit('SetLogMessageState', !this.log_message_state)
      //
    },
    onClickRoi() {
      if(this.roi_state) {
        if(this.single_drone) {
          this.single_drone.SendRoiCancel()
          this.single_drone.SetDroneRoiLocation(undefined, undefined)
        }
        CesiumScreenSpaceEventService.getInstance().SetRoiCancel()
        store.commit('SetRoiState', false)
      } else {
        if (this.single_drone) {
          MissionManager.getInstance().SetGCSMode(GCS_MODE.ROI)
          CesiumScreenSpaceEventService.getInstance().SetSingleRoiMode();
        } else CustomAlert.getInstance().ShowAlert("Please, Select the drone");
      }
    },
    onClickSingleDrone(drone:CESIUM_DRONE) {

      this.center_switch = true;

      SingleManager.getInstance().SetSingleTarget(drone);

      if (this.stack_list.length != 0) {
        let can_flip = false

        // 클릭한 드론이 스택 리스트에 연결된 드론인지 판별 후 연결됐다면 수소창 바로 표시
        // this.stack_list.forEach((stack: HG_HydrogenInfos[]) => {
        //   if (stack.length != 0) {
        //     // 선택한 드론 인덱스가 스택 리스트에 존재한다면 플립 해야 함
        //     if (stack[0]._drone_index == drone.GetNoti()._index) can_flip = true
        //   }
        //   else console.log('Stack Data Is Zero.')
        // });

        if (can_flip) this.is_flip = true
        else this.is_flip = false
      }
      else console.log('Stack List Is Zero.')
    },
    SingleFunction(single_type: string, detail_data: any = '') {
      if (this.single_drone) {
        // 선택 드론이 있을 경우
        // Arming 명령
        if (single_type == "Power") {
          if (this.single_drone.IsArmed() == false) {
            SingleManager.getInstance().Command(
              "arm",
              this.single_drone.GetNoti()._index
            );
          }
          else {
            SingleManager.getInstance().Command(
              "disarm",
              this.single_drone.GetNoti()._index
            );
          }
        }

        // 모드 변경 명령
        else if (single_type == "ModeChange") {
          // detail data -> mode
          // if(detail_data == 4) {
          //   this.mode = 2
          // } else if(detail_data == 5) {
          //   this.mode = 1
          // } else {
          //   this.mode = 0
          // }
          SingleManager.getInstance().ModeChange(
            detail_data,
            this.single_drone.GetNoti()._index
          );
        }

        // 단일 Cmd 명령
        else if (single_type == "Command") {
          // detail data -> type
          if (detail_data == "takeoff") {
            const user_setting:USER_SETTINGS = UserSettingManager.getInstance().Get();
            if(user_setting.takeoff_confirm) {
              store.commit("SetModalType", 'takeoff')
              store.commit("SetModalWindow", true)
            } else {
              SingleManager.getInstance().Command( detail_data, this.single_drone.GetNoti()._index, user_setting.takeoff_initial)
            }
            // let input_alt: string | null = prompt("Take Off Alt : ", "5");

            // 확인 버튼을 눌렀을때만
            // if (input_alt != null) {
            //   // 정수일 경우에만
            //   if (!isNaN(Number(input_alt))) {
            //     SingleManager.getInstance().Command(
            //       detail_data,
            //       this.single_drone.GetNoti()._index,
            //       Number(input_alt)
            //     );
            //   } else
            //     CustomAlert.getInstance().ShowAlert(
            //       "Input value is Not a Number"
            //     );
            // } else console.log("Cancel TakeOff");
          }
          // TakeOff 이외의 Command 명령
          else
            SingleManager.getInstance().Command(
              detail_data,
              this.single_drone.GetNoti()._index
            );
        }

        // 방향키 이동 명령
        else if (single_type == "VirtualKey") {
          // detail data -> direction
          SingleManager.getInstance().MoveDrone(
            detail_data,
            this.single_drone.GetNoti()._index
          );
        }

        // 헤딩 방향 회전 명령
        else if (single_type == "YawControl") {
          // detail data -> direction
          const target_index = this.single_drone.GetNoti()._index;
          SingleManager.getInstance().YawControl(
            detail_data,
            target_index
          );

          this.yaw_tick = setInterval(() => {
            // 1초에 1번씩 yaw 변경 명령을 보냄
            SingleManager.getInstance().YawControl(
              detail_data,
              target_index
            );
          }, 1000);
        }

        // 헤딩 방향 회전 취소 명령
        else if (single_type == "YawRelease") {
          SingleManager.getInstance().YawRelease(this.single_drone.GetNoti()._index);
          clearInterval(this.yaw_tick);
        }

        else console.log("Invalid Single Command");
      } else CustomAlert.getInstance().ShowAlert("Please, Select the drone");
    },
    // zindex(id:any) {
    //   console.log('정보', id)
    //   ScreenManager.getInstance().SetZ_Index(id)
    // }
  },
});
