import { ToInt16 } from "../util/Converter";
import { TimestampToString } from "../util/Datetime";
import { STACK_FAULT, StackFaults } from "./StackFaults";

export enum STACK_CONN_TYPE {
    UNKNOWN = -1,
    UART,
    NETWORK,
}

export default class STACK {
    /**
     *      serial: string,
            model: number,
            type: number,
            alias: string,
            group_id: string,
            drone_index: number,
            connect_state: number,
            error_state: number,
            edge_serial: string,
            data?: string,
     */
    private stack_serial = '';
    private model_type = 0;
    private use_type = 0;
    private alias = '';
    private group_id = '';
    private group_index = 0;
    private connect_state = 0;
    private error_state = 0;
    private edge_serial = '';
    private connection_type = STACK_CONN_TYPE.UNKNOWN;
    private summary_run_state = 0;
    private last_uptime = 0;
    private note = '';

    // 241206 이재혁 임시
    private soar_fcpm_state = '';
    // 241206 이재혁 임시 끝
    private soar_cyclic_counter = 0;
    private soar_fcpm_state_run = 0;
    private soar_fcpm_state_sleep = 0;
    private soar_fcpm_state_dst = 0;
    private soar_fcpm_state_dc = 0;
    private soar_tank_level = 0;
    private soar_battery_voltage = 0;
    private soar_output_power = 0;
    private soar_spm_input_power = 0;
    private soar_battery_power = 0;

    private data = '';

    private sw_version = '';
    private run_sec = 0;
    private total_run_energy = 0;
    private output_power = 0;
    private output_voltage = 0;
    private output_current = 0;
    private anode_pressure = 0;
    private outlet_temperature = 0;
    private inlet_temperature = 0;
    private dcdc_voltage_set_point = 0;
    private dcdc_current_limit = 0;
    private louver_position = 0;
    private fan_sp_duty = 0;
    private state_inactive = 0;
    private state_run = 0;
    private state_standby = 0;
    private state_fault = 0;
    private dcdc_disabled = 0;
    private on_load = 0;
    private fan_pulse = 0;
    private serated = 0;
    private sv01 = 0;
    private sv02 = 0;
    private sv04 = 0;
    private louver_open = 0;
    private dcdc_enable = 0;
    private power_from_stack = 0;
    private power_from_external = 0;

    private enable = 0;
    private run_enable = 0;
    private status = 0;
    private regulator_pressure = 0;

    private temp_fault_list:STACK_FAULT[] = [];
    private fault_list:STACK_FAULT[] = [];
    private fault_lazy_updater:number|undefined = undefined;

    constructor(
        stack_serial:string, 
        model_type:number, 
        use_type:number, 
        alias:string,
        group_id:string,
        group_index:number,
        connect_state:number,
        error_state:number,
        edge_serial:string,
        connection_type: number,
        summary_run_state: number,
        last_uptime: number,) {
        this.stack_serial = stack_serial;
        this.model_type = model_type;
        this.use_type = use_type;
        this.alias = alias;
        this.group_id = group_id;
        this.group_index = group_index;
        this.connect_state = connect_state;
        this.error_state = error_state;
        this.edge_serial = edge_serial;
        this.connection_type = connection_type;
        this.summary_run_state = summary_run_state;
        this.last_uptime = last_uptime;

        this.initialize();
    }

    private initialize() {
        this.fault_lazy_updater = setInterval(() => {
            const temp:STACK_FAULT[] = this.fault_list.concat(this.temp_fault_list);
            this.fault_list = temp;

            if(this.fault_list.length > 50) {
                this.fault_list.splice(0, this.fault_list.length - 50);
            }
        }, 3000)
    }

    Destructor() {
        if(this.fault_lazy_updater != undefined){
            clearInterval(this.fault_lazy_updater);
        }
    }

    SetNote(note:string) {
        this.note = note;
    }

    Set(
        model_type:number, 
        use_type:number, 
        alias:string,
        group_id:string,
        group_index:number,
        connect_state:number,
        error_state:number,
        edge_serial:string,
        connection_type: number,
        summary_run_state: number,
        last_uptime: number,) {
        this.model_type = model_type;
        this.use_type = use_type;
        this.alias = alias;
        this.group_id = group_id;
        this.group_index = group_index;
        this.connect_state = connect_state;
        this.error_state = error_state;
        this.edge_serial = edge_serial;
        this.connection_type = connection_type;
        this.summary_run_state = summary_run_state;
        this.last_uptime = last_uptime;
    }

    SetDataFormNetwork(can_raw_data:string) {
        const str = can_raw_data;
        const base64decode = atob(str);
        let addr = '';
        let command = '';
        let data = '';
  
        for(let i = 0; i < base64decode.length; ++i) {
            if(i < 2) {
                addr += base64decode.charCodeAt(i).toString(16).padStart(2, '0');
            }
            else if(i < 4) {
                command += base64decode.charCodeAt(i).toString(16).padStart(2, '0');
            }
            else {
                data += base64decode.charCodeAt(i).toString(16).padStart(2, '0');
            }
        }
        const hex_command = parseInt(command, 16);
        
        if(data == '0000000000000000') {
            console.log(this.stack_serial, addr, command, data, can_raw_data);
            return;
        }

        if(parseInt("310", 16) <= hex_command && hex_command < (parseInt("310", 16) + 8)) {
            // console.log('SetDataNetForm', this.stack_serial, 'to', can_raw_data, addr, command, data, parseInt(command, 16))
        }
        else if(parseInt("318", 16) <= hex_command && hex_command < (parseInt("318", 16) + 8)) {
            this.sw_version = `${parseInt(data.slice(0, 2), 16)}.${parseInt(data.slice(2, 4), 16)}.${parseInt(data.slice(4, 6), 16)}`
        }
        else if(parseInt("320", 16) <= hex_command && hex_command < (parseInt("320", 16) + 8)) {
            this.run_sec = parseInt(data.slice(0, 8), 16);
            this.total_run_energy = parseInt(data.slice(8), 16);
            // if(this.stack_serial == 'S-00000001') {
                // console.log(this.stack_serial, this.run_sec, hex_command)
                if(this.run_sec == 0) {
                    // console.log(this.stack_serial, addr, command, data, can_raw_data)
                }
            // }
        }
        else if(parseInt("328", 16) <= hex_command && hex_command < (parseInt("328", 16) + 8)) {
            // fault
            const fault_a = parseInt(`0x${data.slice(0, 8)}`, 16);
            const fault_b = parseInt(`0x${data.slice(0, 8)}`, 16);

            const time = Date.now();

            StackFaults.forEach((fault:STACK_FAULT) => {
                fault.time = TimestampToString(time);
                if(fault.command == 0x328 && fault.flag == 'A') {
                    if(fault.code & fault_a) {
                        this.temp_fault_list.push(fault);
                    }
                }

                if(fault.command == 0x328 && fault.flag == 'B') {
                    if(fault.code & fault_b) {
                        this.temp_fault_list.push(fault);
                    }
                }
            });
        }
        else if(parseInt("338", 16) <= hex_command && hex_command < (parseInt("338", 16) + 8)) {
            this.output_power = ToInt16(parseInt(data.slice(0, 4), 16));
            this.output_voltage = Math.floor(ToInt16(parseInt(data.slice(4, 8), 16)) *.01);
            this.output_current = Math.floor(ToInt16(parseInt(data.slice(8, 12), 16)) *.01);
            this.anode_pressure = Math.floor(ToInt16(parseInt(data.slice(12), 16)) *.1);
        }
        else if(parseInt("348", 16) <= hex_command && hex_command < (parseInt("348", 16) + 8)) {
            this.outlet_temperature = Math.floor(ToInt16(parseInt(data.slice(0, 4), 16)) *.01);
            this.inlet_temperature = Math.floor(ToInt16(parseInt(data.slice(4, 8), 16)) *.01);
            this.dcdc_voltage_set_point = Math.floor(ToInt16(parseInt(data.slice(8, 12), 16)) *.01);
            this.dcdc_current_limit = Math.floor(ToInt16(parseInt(data.slice(12), 16)) *.01);
        }
        else if(parseInt("358", 16) <= hex_command && hex_command < (parseInt("358", 16) + 8)) {
            this.louver_position = Math.floor(ToInt16(parseInt(data.slice(0, 4), 16)) *.01);
            this.fan_sp_duty = Math.floor(ToInt16(parseInt(data.slice(4, 8), 16)) *.01);
        }
        else if(parseInt("368", 16) <= hex_command && hex_command < (parseInt("368", 16) + 8)) {
            const state_information = parseInt(data.slice(0, 2), 16).toString(2).padStart(8, '0');
            const load_logic = parseInt(data.slice(2, 4), 16).toString(2).padStart(8, '0');
            const output_bits = parseInt(data.slice(4, 6), 16).toString(2).padStart(8, '0');

            // console.log(state_information, load_logic, output_bits)

            this.state_inactive = Number(state_information.substring(0, 1));
            this.state_run = Number(state_information.substring(1, 2));
            this.state_standby = Number(state_information.substring(2, 3));
            this.state_fault = Number(state_information.substring(3, 4));

            this.dcdc_disabled = Number(load_logic.substring(0, 1));
            this.on_load = Number(load_logic.substring(1, 2));
            this.fan_pulse = Number(load_logic.substring(2, 3));
            this.serated = Number(load_logic.substring(3, 4));

            this.sv01 = Number(output_bits.substring(0, 1));
            this.sv02 = Number(output_bits.substring(1, 2));
            this.sv04 = Number(output_bits.substring(2, 3));
            this.louver_open = Number(output_bits.substring(3, 4));
            this.dcdc_enable = Number(output_bits.substring(4, 5));
            
            this.power_from_stack = Number(output_bits.substring(5, 6));
            this.power_from_external = Number(output_bits.substring(6, 7));
        }
        else if(parseInt("378", 16) <= hex_command && hex_command < (parseInt("378", 16) + 8)) {
            // fault
            const fault_c = parseInt(`0x${data.slice(0, 8)}`, 16);
            const fault_d = parseInt(`0x${data.slice(0, 8)}`, 16);

            const time = Date.now();

            StackFaults.forEach((fault:STACK_FAULT) => {
                fault.time = TimestampToString(time);
                if(fault.command == 0x378 && fault.flag == 'C') {
                    if(fault.code & fault_c) {
                        this.temp_fault_list.push(fault);
                    }
                }

                if(fault.command == 0x378 && fault.flag == 'D') {
                    if(fault.code & fault_d) {
                        this.temp_fault_list.push(fault);
                    }
                }
            });
        }
        else {
            console.log(`Not defined command ${hex_command}`)
        }
    }

    SetDataFromUart(raw_data:string) {
        this.data = raw_data;
        const command = raw_data.split(":")[0].slice(-3);
        const command2 = raw_data.split(":")[0].slice(-5);
        const hex_command = parseInt(`0x${command}`, 16);
        const data = raw_data.split(':')[1];
        if(command2 == '1A800') {
            console.log('aras', command2)
        } else {
            console.log('안옴', command2)

            const cyclic_counter = 0;
            const fcpm_state = raw_data.split(",")[0];
            const hex_to_decimal_state = parseInt(fcpm_state, 16);
            const tank_level = raw_data.split(",")[1];
            const battery_voltage = raw_data.split(",")[2];
            const output_power = raw_data.split(",")[3];
            const spm_input_power = raw_data.split(",")[4];
            const battery_power = raw_data.split(",")[5];

            this.soar_cyclic_counter = Number(cyclic_counter);

            if(fcpm_state == '0') {
                this.soar_fcpm_state = 'OFF'
            }
            else if(fcpm_state == '1') {
                this.soar_fcpm_state = 'STARTING'
            }
            else if(fcpm_state == '2') {
                this.soar_fcpm_state = 'RUNNING'
            }
            else if(fcpm_state == '3') {
                this.soar_fcpm_state = 'STOPPING'
            }
            else if(fcpm_state == '4') {
                this.soar_fcpm_state = 'GO TO SLEEP'
            }
            else if(fcpm_state == '9') {
                this.soar_fcpm_state = 'UNKNOWN'
            }
            else {
                this.soar_fcpm_state = `UNKNOWN(${fcpm_state})`
            }

            // this.soar_fcpm_state_run = hex_to_decimal_state & 8 ? 1 : 0;
            // this.soar_fcpm_state_sleep = hex_to_decimal_state & 4 ? 1 : 0;
            // this.soar_fcpm_state_dst = hex_to_decimal_state & 2 ? 1 : 0;
            // this.soar_fcpm_state_dc = hex_to_decimal_state & 1 ? 1 : 0;
            this.soar_tank_level = Number(tank_level);
            this.soar_battery_voltage = Number(battery_voltage);
            this.soar_output_power = Number(output_power);
            this.soar_spm_input_power = Number(spm_input_power);
            this.soar_battery_power = Number(battery_power);
        }
        if(0x310 == hex_command) {
            // Stack serial already known
        }
        else if(0x138 == hex_command) {
            this.sw_version = data;
        }
        else if(0x320 == hex_command) {
            if (data.split(",").length == 2) {
                const run_sec = data.split(",")[0];
                const total_run_sec = data.split(",")[1];
          
                this.run_sec = Number(run_sec);
                this.total_run_energy = Number(total_run_sec);
            }
        }
        else if(0x328 == hex_command) {
            // fault
            if (data.split(",").length == 2) {
                const fault_a = parseInt(`0x${data.split(",")[0]}`, 16);
                const fault_b = parseInt(`0x${data.split(",")[1]}`, 16);

                const time = Date.now();

                StackFaults.forEach((fault:STACK_FAULT) => {
                    fault.time = TimestampToString(time);
                    if(fault.command == 0x328 && fault.flag == 'A') {
                        if(fault.code & fault_a) {
                            this.temp_fault_list.push(fault);
                        }
                    }

                    if(fault.command == 0x328 && fault.flag == 'B') {
                        if(fault.code & fault_b) {
                            this.temp_fault_list.push(fault);
                        }
                    }
                });
            }
        }
        else if(0x338 == hex_command) {
            if (data.split(",").length == 4) {
                const output_power = data.split(",")[0];
                const output_voltage = data.split(",")[1];
                const output_current = data.split(",")[2];
                const anode_pressure = data.split(",")[3];
          
                this.output_power = Number(output_power);
                this.output_voltage = Number(output_voltage);
                this.output_current = Number(output_current);
                this.anode_pressure = Number(anode_pressure);
            }
        }
        else if(0x348 == hex_command) {
            if (data.split(",").length == 4) {
                const outlet_temperature = data.split(",")[0];
                const inlet_temperature = data.split(",")[1];
                const dcdc_voltage_set_point = data.split(",")[2];
                const dcdc_current_limit = data.split(",")[3];
          
                this.outlet_temperature = Number(outlet_temperature);
                this.inlet_temperature = Number(inlet_temperature);
                this.dcdc_voltage_set_point = Number(dcdc_voltage_set_point);
                this.dcdc_current_limit = Number(dcdc_current_limit);
            }
        }
        else if(0x358 == hex_command) {
            if (data.split(",").length == 2) {
                const louver_position = data.split(",")[0];
                const fan_sp_duty = data.split(",")[1];
          
                this.louver_position = Number(louver_position);
                this.fan_sp_duty = Number(fan_sp_duty);
            }
        }
        else if(0x368 == hex_command) {
            if (data.length == 24) {
                const state_information = data.substring(0, 8);
                const load_logic = data.substring(8, 16);
                const output_bits = data.substring(16);

                this.state_inactive = Number(state_information.substring(0, 1));
                this.state_run = Number(state_information.substring(1, 2));
                this.state_standby = Number(state_information.substring(2, 3));
                this.state_fault = Number(state_information.substring(3, 4));

                this.dcdc_disabled = Number(load_logic.substring(0, 1));
                this.on_load = Number(load_logic.substring(1, 2));
                this.fan_pulse = Number(load_logic.substring(2, 3));
                this.serated = Number(load_logic.substring(3, 4));

                this.sv01 = Number(output_bits.substring(0, 1));
                this.sv02 = Number(output_bits.substring(1, 2));
                this.sv04 = Number(output_bits.substring(2, 3));
                this.louver_open = Number(output_bits.substring(3, 4));
                this.dcdc_enable = Number(output_bits.substring(4, 5));
                
                this.power_from_stack = Number(output_bits.substring(5, 6));
                this.power_from_external = Number(output_bits.substring(6, 7));
            }
        }
        else if(0x378 == hex_command) {
            // fault
            if (data.split(",").length == 2) {
                const fault_c = parseInt(`0x${data.split(",")[0]}`, 16);
                const fault_d = parseInt(`0x${data.split(",")[1]}`, 16);

                const time = Date.now();

                StackFaults.forEach((fault:STACK_FAULT) => {
                    fault.time = TimestampToString(time);
                    if(fault.command == 0x378 && fault.flag == 'C') {
                        if(fault.code & fault_c) {
                            this.temp_fault_list.push(fault);
                        }
                    }

                    if(fault.command == 0x378 && fault.flag == 'D') {
                        if(fault.code & fault_d) {
                            this.temp_fault_list.push(fault);
                        }
                    }
                });
            }
        }
        else if(0x800 == hex_command) {
            if ((data.includes("+") || data, data.split(".").length == 3)) {
                const enable = data.substring(0, 1);
                const run_enable = data.substring(1, 2);
                const status = data.substring(2, 3);
                const regulator_pressure = data.substring(3, data.length - 1);

                this.enable = Number(enable);
                this.run_enable = Number(run_enable);
                this.status = Number(status);
                this.regulator_pressure = Number(regulator_pressure);
            }
        }
        else if(0x400 == hex_command) {
            // 
            const splited:string[] = data.split(',');
            if(splited.length == 7) {
                const cyclic_counter = splited[0];
                const fcpm_state = splited[1];
                const hex_to_decimal_state = parseInt(fcpm_state, 16);
                const tank_level = splited[2];
                const battery_voltage = splited[3];
                const output_power = splited[4];
                const spm_input_power = splited[5];
                const battery_power = splited[6];

                this.soar_cyclic_counter = Number(cyclic_counter);
                this.soar_fcpm_state_run = hex_to_decimal_state & 8 ? 1 : 0;
                this.soar_fcpm_state_sleep = hex_to_decimal_state & 4 ? 1 : 0;
                this.soar_fcpm_state_dst = hex_to_decimal_state & 2 ? 1 : 0;
                this.soar_fcpm_state_dc = hex_to_decimal_state & 1 ? 1 : 0;
                this.soar_tank_level = Number(tank_level);
                this.soar_battery_voltage = Number(battery_voltage);
                this.soar_output_power = Number(output_power);
                this.soar_spm_input_power = Number(spm_input_power);
                this.soar_battery_power = Number(battery_power);
            }
        }
        else if(0x408 == hex_command || 0x418 == hex_command || 0x428 == hex_command) {
            // fault
            if (data.split(",").length == 2) {
                const fault_a = parseInt(`0x${data.split(",")[0]}`, 16);
                const fault_b = parseInt(`0x${data.split(",")[1]}`, 16);

                const time = Date.now();

                StackFaults.forEach((fault:STACK_FAULT) => {
                    fault.time = TimestampToString(time);
                    if(fault.command == hex_command && fault.flag == 'A') {
                        if(fault.code & fault_a) {
                            this.temp_fault_list.push(fault);
                        }
                    }

                    if(fault.command == hex_command && fault.flag == 'B') {
                        if(fault.code & fault_b) {
                            this.temp_fault_list.push(fault);
                        }
                    }
                });
            }
        }
        else if(0x410 == hex_command || 0x420 == hex_command || 0x430 == hex_command) {
            // fault
            if (data.split(",").length == 2) {
                const fault_c = parseInt(`0x${data.split(",")[0]}`, 16);
                const fault_d = parseInt(`0x${data.split(",")[1]}`, 16);

                const time = Date.now();

                StackFaults.forEach((fault:STACK_FAULT) => {
                    fault.time = TimestampToString(time);
                    if(fault.command == hex_command && fault.flag == 'C') {
                        if(fault.code & fault_c) {
                            this.temp_fault_list.push(fault);
                        }
                    }

                    if(fault.command == hex_command && fault.flag == 'D') {
                        if(fault.code & fault_d) {
                            this.temp_fault_list.push(fault);
                        }
                    }
                });
            }
        }
        // else {
        //     console.log(`Not defined command ${command}`)
        // }
    }

    GetFaults() {
        // return [];
        return this.fault_list;
    }

    Get() {
        return {
            stack_serial: this.stack_serial,
            model_type: this.model_type,
            use_type: this.use_type,
            alias: this.alias,
            group_id: this.group_id,
            group_index: this.group_index,
            connect_state: this.connect_state,
            error_state: this.error_state,
            edge_serial: this.edge_serial,
            connection_type: this.connection_type,
            summary_run_state: this.summary_run_state,
            last_uptime: this.last_uptime,
            note: this.note,

            sw_version: this.sw_version,
            run_sec: this.run_sec,
            total_run_energy: this.total_run_energy,
            output_power: this.output_power,
            output_voltage: this.output_voltage,
            output_current: this.output_current,
            anode_pressure: this.anode_pressure,
            outlet_temperature: this.outlet_temperature,
            inlet_temperature: this.inlet_temperature,
            dcdc_voltage_set_point: this.dcdc_voltage_set_point,
            dcdc_current_limit: this.dcdc_current_limit,
            louver_position: this.louver_position,
            fan_sp_duty: this.fan_sp_duty,
            state_inactive: this.state_inactive,
            state_run: this.state_run,
            state_standby: this.state_standby,
            state_fault: this.state_fault,
            dcdc_disabled: this.dcdc_disabled,
            on_load: this.on_load,
            fan_pulse: this.fan_pulse,
            serated: this.serated,
            sv01: this.sv01,
            sv02: this.sv02,
            sv04: this.sv04,
            louver_open: this.louver_open,
            dcdc_enable: this.dcdc_enable,
            power_from_stack: this.power_from_stack,
            power_from_external: this.power_from_external,
            enable: this.enable,
            run_enable: this.run_enable,
            status: this.status,
            regulator_pressure: this.regulator_pressure,

            soar_fcpm_state: this.soar_fcpm_state,
            soar_cyclic_counter: this.soar_cyclic_counter,
            soar_fcpm_state_run: this.soar_fcpm_state_run,
            soar_fcpm_state_sleep: this.soar_fcpm_state_sleep,
            soar_fcpm_state_dst: this.soar_fcpm_state_dst,
            soar_fcpm_state_dc: this.soar_fcpm_state_dc,
            soar_battery_voltage: this.soar_battery_voltage,
            soar_spm_input_power: this.soar_spm_input_power,
            soar_battery_power: this.soar_battery_power,
            soar_tank_level: this.soar_tank_level,
            soar_output_power: this.soar_output_power,
        }
    }
}