import { MISSION_MODE_STATE } from "@/services/cesium/cesium_screen_space_ev_service";
import CESIUM_DRONE from "@/services/hg/cesium_drone";
import DEVICE from "@/services/hg/device/Device";
import { ChannelInfo } from "@/services/hg/drone_tv/webrtc";
import { HG_Ai_Data } from "@/services/hg/extension_ai/ExtensionAiManager";
import Mission from "@/services/hg/mission/mission";
import { MissionObject } from "@/services/hg/mission/mission_manager";
import { CONTROLLER_MODE } from "@/services/hg/single/single_manager";
import STACK from "@/services/hg/stack/Stack";
import { LINE_CHART_SERIES_FROM, RANGE_CHART_SERIES_FORM, ST_LOG_FILE_INFO } from "@/services/report/LogFileManager";
import MEDUSA_PACKET from "@/services/third_party/neton/medusa_packet_info";
import { HG_LogFileInfo, HG_LogFileItem } from "@/services/ws/ws_hg_server_packet_interface";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
const missions: Map<string, Mission> = new Map<string, Mission>();
export default new Vuex.Store({
  state: {
    auth_state: false,
    account_id: "unknown",
    app_type: 0,
    drones: [] as CESIUM_DRONE[],
    controller_mode: 0 as CONTROLLER_MODE,

    select_mission_name: undefined,
    missions: missions,

    show_top_tab: true,
    side_menu_tab: false,
    side_drone_tab: false,
    show_drone_detail_tab: false,
    side_mission_tab: false,
    side_mission_tab_state: "mission",
    assigned_mission_list: [] as any,
    now_selected_drone: "",
    side_mission_Detail_tab: false,
    mission_data: [],
    show_noFlightZone: false,
    show_extension_function: false,
    show_drone_tv: false,
    show_fc_force_update: false,
    show_user_setting: false,
    show_single: true,
    show_waypoint_box: false,
    fc_log_data: false,
    h2_log_data: false,
    extension_function_state: false,

    h2_log_file_list: [] as ST_LOG_FILE_INFO[],

    drone_state_info: [],
    drone_state: false,
    show_facial_recognition: false,
    single_drone: undefined as CESIUM_DRONE|undefined,
    waypoint_box_type_none: false,
    modal_window: false,
    modal_type: "",
    log_message_state: false,

    drone_tv_show: false,

    mission_generate_tab: false,
    delete_mission: [] as MissionObject[],
    mission_packet: {
      _id: "",
      alias: "",
      items: {},
    },
    edit_mission_name: "",
    mission_mode_state: MISSION_MODE_STATE.ON,
    mission_unvisible_list: [] as MissionObject[],

    medusa_items: [] as MEDUSA_PACKET[],
    alert_data: [],
    log_list: [] as HG_LogFileItem[],
    log_file: [] as string[],
    log_file_info: {} as HG_LogFileInfo,
    log_progress: 0,
    log_save_done_list: [] as HG_LogFileItem[],

    stacks: [] as STACK[],
    devices: [] as DEVICE[],
    login_data: [] as string[],
    stack_error_levels: [] as number[],
    stack_list_raw: [] as object[],
    stack_list: [],
    selected_stack_data: new Map(),
    report_soar_state_data: [] as RANGE_CHART_SERIES_FORM[],
    report_soar_data: [] as LINE_CHART_SERIES_FROM[],

    ai_image_arr_people_counting: [] as HG_Ai_Data[],
    ai_image_arr_vehicle: [] as HG_Ai_Data[],
    ai_image_arr_fence_detect: [] as HG_Ai_Data[],

    fc_log_data_list: [] as any,

    license_state: false,

    stack_auth: false,

    dronetv_channel_list: [] as ChannelInfo[],
    ai_channel_list: [] as ChannelInfo[],

    simulate_target_mission: undefined as undefined|MissionObject,

    gcs_guide_state: false,

    roi_state: false,
  },
  getters: {
    GetAuthState: (state) => {
      return state.auth_state;
    },
    GetAccountID: (state) => {
      return state.account_id;
    },
    GetAppType: (state) => {
      return state.app_type;
    },
    GetDrones: (state) => {
      return state.drones;
    },
    GetSelectMissionName: (state) => {
      return state.select_mission_name;
    },
    GetMissions: (state) => {
      return state.missions;
    },
    GetTopTab: (state) => {
      return state.show_top_tab;
    },
    GetSideMenuTab: (state) => {
      return state.side_menu_tab;
    },
    GetSideDroneTab: (state) => {
      return state.side_drone_tab;
    },
    GetSideMissionTab: (state) => {
      return state.side_mission_tab;
    },
    GetSideMissionDetailTab: (state) => {
      return state.side_mission_Detail_tab;
    },
    GetMissionData: (state) => {
      return state.mission_data;
    },
    GetShowDroneTV: (state) => {
      return state.show_drone_tv;
    },
    GetShowFCForceUpdate: (state) => {
      return state.show_fc_force_update;
    },
    GetShowUserSetting: (state) => {
      return state.show_user_setting;
    },
    GetShowSingle: (state) => {
      return state.show_single;
    },
    GetFcLogData: (state) => {
      return state.fc_log_data;
    },
    GetH2LogData: (state) => {
      return state.h2_log_data;
    },
    GetExtensionFunction: (state) => {
      return state.show_extension_function;
    },
    GetDroneStateInfo: (state) => {
      return state.drone_state_info;
    },
    GetDroneState: (state) => {
      return state.drone_state;
    },
    GetMedusaItems: (state) => {
      return state.medusa_items;
    },
    GetFacialRecognition: (state) => {
      return state.show_facial_recognition;
    },
    GetNoFlightZone: (state) => {
      return state.show_noFlightZone;
    },
    GetSingleDrone: (state) => {
      return state.single_drone;
    },
    GetAlertData: (state) => {
      return state.alert_data;
    },
    GetLogList: (state) => {
      return state.log_list;
    },
    GetLogFile: (state) => {
      return state.log_file;
    },
    GetLogFileInfo: (state) => {
      return state.log_file_info;
    },
    GetLogProgress: (state) => {
      return state.log_progress;
    },
    GetWayPointBox: (state) => {
      return state.show_waypoint_box;
    },
    GetWaypointBoxTypeNone: (state) => {
      return state.waypoint_box_type_none;
    },
    GetLoginData: (state) => {
      return state.login_data;
    },
    GetStackErrorLevels: (state) => {
      return state.stack_error_levels;
    },
    GetStackListRaw: (state) => {
      return state.stack_list_raw;
    },
    GetStackList: (state) => {
      return state.stack_list;
    },
    GetStackAuth: (state) => {
      return state.stack_auth;
    },
    GetSelectedStackData: (state) => {
      return state.selected_stack_data;
    },
    GetModalWindow: (state) => {
      return state.modal_window;
    },
    GetMissionPacket: (state) => {
      return state.mission_packet;
    },
    GetSideMissionTabState: (state) => {
      return state.side_mission_tab_state;
    },
    GetAssignedMissionList: (state) => {
      return state.assigned_mission_list;
    },
    GetNowSelectedDrone: (state) => {
      return state.now_selected_drone;
    },
    GetModalType: (state) => {
      return state.modal_type;
    },
    GetDeleteMission: (state) => {
      return state.delete_mission;
    },
    GetEditMissionName: (state) => {
      return state.edit_mission_name;
    },
    GetMissionGenerateTab: (state) => {
      return state.mission_generate_tab;
    },
    GetMissionModeState: (state) => {
      return state.mission_mode_state;
    },
    GetDroneTVShow: (state) => {
      return state.drone_tv_show;
    },
    GetDroneTvChannel: (state) => {
      return state.dronetv_channel_list;
    },
    GetAiChannel: (state) => {
      return state.ai_channel_list;
    },
    GetMissionUnvisibleList: (state) => {
      return state.mission_unvisible_list;
    },
    GetLogMessageState: (state) => {
      return state.log_message_state;
    },
    GetLicenseState: (state) => {
      return state.license_state;
    },
    GetSimulateTargetMission: (state) => {
      return state.simulate_target_mission;
    },
    GetLogSaveDoneList: (state) => {
      return state.log_save_done_list;
    },
    GetStacks: (state) => {
      return state.stacks;
    },
    GetDevices: (state) => {
      return state.devices;
    },
    GetH2LogFileList: (state) => {
      return state.h2_log_file_list;
    },
    GetReportSoarState: (state) => {
      return state.report_soar_state_data;
    },
    GetReportSoarData: (state) => {
      return state.report_soar_data;
    },
    GetExtensionFunctionState: (state) => {
      return state.extension_function_state;
    },
    GetAiImagePeopleCounting: (state) => {
      return state.ai_image_arr_people_counting;
    },
    GetAiImageVehicle: (state) => {
      return state.ai_image_arr_vehicle;
    },
    GetAiImageFenceDetect: (state) => {
      return state.ai_image_arr_fence_detect;
    },
    GetGcsGuideState: (state) => {
      return state.gcs_guide_state;
    },
    GetDroneDetailTab: (state) => {
      return state.show_drone_detail_tab;
    },
    GetFcLogDataList: (state) => {
      return state.fc_log_data_list
    },
    GetControllerMode: (state) => {
      return state.controller_mode
    },
    GetRoiState: (state) => {
      return state.roi_state
    }
  },
  mutations: {
    SetAuthState(state, value) {
      state.auth_state = value;
    },
    SetAccountID(state, value) {
      state.account_id = value;
    },
    SetAppType(state, value) {
      state.app_type = value;
    },
    SetDrones(state, value) {
      state.drones = value;
    },
    SetSelectMissionName(state, value) {
      state.select_mission_name = value;
    },
    SetMissions(state, value) {
      state.missions = value;
    },
    SetTopTab(state, value) {
      state.show_top_tab = value;
    },
    SetSideMenuTab(state, value) {
      state.side_menu_tab = value;
    },
    SetSideDroneTab(state, value) {
      state.side_drone_tab = value;
    },
    SetSideMissionTab(state, value) {
      state.side_mission_tab = value;
    },
    SetSideMissionDetailTab(state, value) {
      state.side_mission_Detail_tab = value;
    },
    SetMissionData(state, value) {
      state.mission_data = value;
    },
    SetShowDroneTV(state, value) {
      state.show_drone_tv = value;
    },
    SetShowFCForceUpdate(state, value) {
      state.show_fc_force_update = value;
    },
    SetShowUserSetting(state, value) {
      state.show_user_setting = value;
    },
    SetShowSingle(state, value) {
      state.show_single = value;
    },
    SetFcLogData(state, value) {
      state.fc_log_data = value;
    },
    SetH2LogData(state, value) {
      state.h2_log_data = value;
    },
    SetExtensionFunction(state, value) {
      state.show_extension_function = value;
    },
    SetDroneStateInfo(state, value) {
      state.drone_state_info = value;
    },
    SetDroneState(state, value) {
      state.drone_state = value;
    },
    SetMedusaItems(state, value) {
      state.medusa_items.push(value);
    },
    TouchMedusaItems(state) {
      state.medusa_items = [...state.medusa_items];
    },
    SetFacialRecognition: (state, value) => {
      state.show_facial_recognition = value;
    },
    SetNoFlightZone: (state, value) => {
      state.show_noFlightZone = value;
    },
    SetSingleDrone: (state, value) => {
      state.single_drone = value;
    },
    SetAlertData: (state, value) => {
      state.alert_data = value;
    },
    AddLogFileList: (state, value) => {
      state.log_list = [...state.log_list, ...value];
    },
    CleanLogFileList: (state) => {
      state.log_list = [];
    },
    // SetLogList: (state, value) => {
    //   state.log_list = value;
    // },
    SetLogFile(state, value) {
      state.log_file.push(value);
    },
    SetLogFileInfo(state, value) {
      state.log_file_info = value;
    },
    SetLogProgress(state, value) {
      state.log_progress = value;
    },
    SetWayPointBox(state, value) {
      state.show_waypoint_box = value;
    },
    SetWaypointBoxTypeNone(state, value) {
      state.waypoint_box_type_none = value;
    },
    SetLoginData(state, value) {
      state.login_data = value;
    },
    SetStackErrorLevels(state, value) {
      state.stack_error_levels.push(value);
    },
    SetNewStackErrorLevels(state, value) {
      state.stack_error_levels = value;
    },
    AppendStackListRaw(state, value) {
      state.stack_list_raw = [...state.stack_list_raw, ...value];
    },
    ClearStackListRaw(state) {
      state.stack_list_raw = [];
    },
    SetStackList(state, value) {
      state.stack_list = value;
    },
    SetModalWindow(state, value) {
      state.modal_window = value;
    },
    SetMissionPacket(state, value) {
      state.mission_packet = value;
    },
    SetSideMissionTabState(state, value) {
      state.side_mission_tab_state = value;
    },
    AddSelectedStackData(state, value) {
      // 해당 Map에 드론 인덱스를 키로 가진 객체가 없으면 드론 인덱스가 key, 빈 배열을 value로 가지도록 Map Set
      if (!state.selected_stack_data.has(value.drone_index)) {
        state.selected_stack_data.set(value.drone_index, []);
      }

      // 찾으면 state.selected_stack_data.get(value.drone_index)[?] -> Map(value.serial, new Map()) 반환
      const is_exist_map_data = state.selected_stack_data
        .get(value.drone_index)
        // 해당 드론 인덱스에 대한 value 배열을 순회하여 receive 받은 serial이 있는지 체크
        .find((stack_data: any) => stack_data.has(value.serial));

      // 존재하면 값 업데이트
      if (is_exist_map_data) {
        // value.serial로 value에 해당하는 Map 객체를 가져와서 해당 Map에 command 및 data Set
        is_exist_map_data.get(value.serial).set(value.command, value.data);
      }
      // 없으면 해당 시리얼을 key 값으로 새로운 Map 생성 후 push (Map 생성자에는 순회가능한 객체만 들어갈 수 있음)
      else {
        state.selected_stack_data
          .get(value.drone_index)
          .push(new Map([[value.serial, new Map([["Fault", new Map()]])]]));
      }
    },
    AddSelectedStackFaultData(state, value) {
      // value.drone_index가 String 이면 안 들어감
      if (state.selected_stack_data.has(value.drone_index)) {
        // 찾으면 state.selected_stack_data.get(value.drone_index)[?] -> Map(value.serial, new Map()) 반환
        const is_exist_map_data = state.selected_stack_data
          .get(value.drone_index)
          // 해당 드론 인덱스에 대한 value 배열을 순회하여 receive 받은 serial이 있는지 체크
          .find((stack_data: any) => stack_data.has(value.serial));

        if (is_exist_map_data) {
          is_exist_map_data
            .get(value.serial)
            .get("Fault")
            .set(value.fault_key, value.fault);
        } else console.log("No Exist Fault Map");
      }

      // console.log('fault : ', state.selected_stack_data);
    },
    //사용 안함
    RemoveSelectedStackData(state, value) {
      state.selected_stack_data.delete(value);
    },
    //사용 안함
    ClearSelectedStackData(state) {
      state.selected_stack_data.clear();
    },
    SetStackAuth(state, value) {
      state.stack_auth = value;
    },
    SetAssignedMissionList(state, value) { //드론에 미션 부여할 때
      if (state.assigned_mission_list.length == 0) {
        state.assigned_mission_list.push(value);
      } else {
        let list_num = 0;
        state.assigned_mission_list.forEach((info: any) => {
          if (info.drone_name == value.drone_name) {
            const set_arr = state.assigned_mission_list;
            set_arr.splice(list_num, 1);
            state.assigned_mission_list = set_arr;
          }
          if (value.mission != "None") {
            state.assigned_mission_list.push(value);
          }
          ++list_num;
        });
      }
    },
    SetNowSelectedDrone(state, value) {
      state.now_selected_drone = value;
    },
    SetModalType(state, value) {
      state.modal_type = value;
    },
    SetDeleteMission(state, value) {
      state.delete_mission = value;
    },
    SetEditMissionName(state, value) {
      state.edit_mission_name = value;
    },
    SetMissionGenerateTab(state, value) {
      state.mission_generate_tab = value;
    },
    SetMissionModeState(state, value) {
      state.mission_mode_state = value;
    },
    SetDroneTVShow(state, value) {
      state.drone_tv_show = value;
    },
    SetDroneTvChannel(state, value) {
      state.dronetv_channel_list = value;
    },
    SetAiChannel(state, value) {
      state.ai_channel_list = value;
    },
    SetMissionUnvisibleListPush(state, value) {
      state.mission_unvisible_list.push(value);
    },
    SetMissionUnvisibleListChange(state, value) {
      state.mission_unvisible_list = value;
    },
    SetLogMessageState(state, value) {
      state.log_message_state = value;
    },
    SetLicenseState(state, value) {
      state.license_state = value;
    },
    SetSimulateTargetMission(state, value) {
      state.simulate_target_mission = value;
    },
    SetLogSaveDoneList(state, value) {
      state.log_save_done_list.push(value);
    },
    SetStacks(state, value) {
      state.stacks = value;
    },
    SetDevices(state, value) {
      state.devices = value;
    },
    SetH2LogFileList(state, value) {
      state.h2_log_file_list = value;
    },
    SetReportSoarState(state, value) {
      state.report_soar_state_data = value;
    },
    SetReportSoarData(state, value) {
      state.report_soar_data = value;
    },
    SetExtensionFunctionState(state, value) {
      state.extension_function_state = value;
    },
    SetAiImagePeopleCounting(state, value) {
      state.ai_image_arr_people_counting = value;
    },
    SetAiImageVehicle(state, value) {
      state.ai_image_arr_vehicle = value;
    },
    SetAiImageFenceDetect(state, value) {
      state.ai_image_arr_fence_detect = value;
    },
    SetGcsGuideState(state, value) {
      state.gcs_guide_state = value;
    },
    SetDroneDetailTab(state, value) {
      state.show_drone_detail_tab = value;
    },
    SetFcLogDataList(state, value) {
      state.fc_log_data_list = value;
    },
    SetControllerMode(state, value) {
      state.controller_mode = value;
    },
    SetRoiState(state, value) {
      state.roi_state = value;
    },
  },
  actions: {},
  modules: {},
});
